require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0ddfcdf9d4752a0e1b847779f41fae17/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0ddfcdf9d4752a0e1b847779f41fae17'] = function fn_0ddfcdf9d4752a0e1b847779f41fae17(component) {
  component['display'] = true;

}

})
define('./functions/104fd91d13345e26662507e5fbebf5f4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_104fd91d13345e26662507e5fbebf5f4'] = async function fn_104fd91d13345e26662507e5fbebf5f4(order, user) {
var order, currentOrder, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  order['fulfillments'] = [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })];

return (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': order })), 'order'))
}

})
define('./functions/251a91f37cf39b8aa532ba567eb18a1b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_251a91f37cf39b8aa532ba567eb18a1b'] = async function fn_251a91f37cf39b8aa532ba567eb18a1b(order, cart) {
var k, cartItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  order['cart'] = cart;
  var k_list = (getObjectProperty(order, 'line_items'));
  for (var k_index in k_list) {
    k = k_list[k_index];
    if (getObjectProperty(k, 'modifiers')) {
      cartItem = (await (async function(list, item) {
      	return list.find(cartItem => {
      	  const cartModifiers = Object.values(cartItem.selectedModifiers).map(item => item.map(modifier => modifier.catalog_object_id)).flat()
      	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

      	  if (cartModifiers.length === modifiers.length) {
      	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
      	    return cartItem.selectedVariationId === item.catalog_object_id && matches.length === 0
      	  }

      	})
      })((getObjectProperty(order, 'cart')), k));
    } else {
      cartItem = (await (async function(list, id) {
      	return list.find(item => {
      	  if (item.selectedModifiers) {
      	    return item.selectedVariationId === id && Object.keys(item.selectedModifiers).length === 0
      	  } else {
      	    return item.selectedVariationId === id
      	  }

      	})
      })((getObjectProperty(order, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
    }
    k['name'] = (getObjectProperty(cartItem, 'name'));
    k['id'] = (getObjectProperty(cartItem, 'Id'));
    k['image'] = (getObjectProperty(cartItem, 'image'));
  }

}

})
define('./functions/28faa43ab591eb1821898cd453e50ae9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_28faa43ab591eb1821898cd453e50ae9'] = async function fn_28faa43ab591eb1821898cd453e50ae9(cart, tax, tips) {
var price;


  price = (await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d'](cart)) / 100;

return ({ 'totalSum': (Number(price)),'totalSumStr': String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))) })
}

})
define('./functions/290d3b3804db72ede114ff547e7b148e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e'] = async function fn_290d3b3804db72ede114ff547e7b148e(className) {
  await (async function(className) {
  	const input = document.querySelector(`.${className}`).firstElementChild.firstElementChild;
  	input.select();
  })(className);

}

})
define('./functions/2d266328cce7f50d2595cc0eddd78a0d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2d266328cce7f50d2595cc0eddd78a0d'] = async function fn_2d266328cce7f50d2595cc0eddd78a0d(user, cart, locationId, tips) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'fulfillments': [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })] })))), 'order'))
}

})
define('./functions/34b2abcdce663fbf50e670a170079af7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_34b2abcdce663fbf50e670a170079af7'] = async function fn_34b2abcdce663fbf50e670a170079af7(item, modifiersList) {
var item, modifierIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty((getObjectProperty(item, 'item_data')), 'modifier_list_info')) && !(getObjectProperty(item, 'modifiers'))) {
    modifierIds = ((getObjectProperty((getObjectProperty(item, 'item_data')), 'modifier_list_info')).map(item => item['modifier_list_id']));
    item['modifiers'] = (await asyncListFilter(modifiersList, async (item) => {


     return (modifierIds.includes((getObjectProperty(item, 'id'))));
    }));
  }

}

})
define('./functions/37bb50d9d5b36cb77803c83887ccf12c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c'] = async function fn_37bb50d9d5b36cb77803c83887ccf12c() {
return (await (async function() {
	const name = document.querySelector('.name-input').firstElementChild.firstElementChild;
	const email = document.querySelector('.email-input').firstElementChild.firstElementChild;
	const month = document.querySelector('.month-input').firstElementChild.firstElementChild;
	const date = document.querySelector('.date-input').firstElementChild.firstElementChild;
	const year = document.querySelector('.year-input').firstElementChild.firstElementChild;
	
	return name === document.activeElement || 
	       email === document.activeElement || 
	       month === document.activeElement || 
	       date === document.activeElement || 
	       year === document.activeElement
})())
}

})
define('./functions/3a6650b241d4ae4403e4313b35362cd5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3a6650b241d4ae4403e4313b35362cd5'] = function fn_3a6650b241d4ae4403e4313b35362cd5(input, length) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(input, 'el'))['maxLength'] = length;

}

})
define('./functions/42dfd10e468212be0071a84d163af59f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_42dfd10e468212be0071a84d163af59f'] = function fn_42dfd10e468212be0071a84d163af59f(start, end, step) {
var list, number, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  list = [];
  var i_inc = Math.abs(step);
  if (start > end) {
    i_inc = -i_inc;
  }
  for (i = start; i_inc >= 0 ? i <= end : i >= end; i += i_inc) {
    number = i < 10 ? String('0') + String(i) : i;
    addItemToList(list, ({ 'value': i,'label': number }));
  }

return list
}

})
define('./functions/44380bd19b232d1ebc86185656af919a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a'] = async function fn_44380bd19b232d1ebc86185656af919a(location) {
var end, start, endTime, startTime, locationBusinessHours, time, schedule;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setScheduleTime(time, schedule) {
  time.setHours((Number((schedule.slice(0, 2)))));
  time.setMinutes((Number((schedule.slice(3, 5)))));
  time.setSeconds((Number((schedule.slice(6, schedule.length)))));
  time.setMilliseconds(0);
}


  locationBusinessHours = (getObjectProperty(location, 'business_hours.periods'))[(((new Date((new Date())).getDay()) + 1) - 1)];
  startTime = (getObjectProperty(locationBusinessHours, 'start_local_time'));
  endTime = (getObjectProperty(locationBusinessHours, 'end_local_time'));
  start = (new Date());
  end = (new Date());
  await setScheduleTime(start, startTime);
  await setScheduleTime(end, endTime);

return (start < (new Date()) && end > (new Date()))
}

})
define('./functions/458134ca0fffb49743ef9f5c3adc95af/code.js', () => { 

BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af'] = async function fn_458134ca0fffb49743ef9f5c3adc95af(cart, tips) {
var tax, cart;


  tax = (await BackendlessUI.Functions.Custom['fn_6eaf9b25814cec57778d16ac3dc12086'](cart));

return (Object.assign(({ 'tax': tax,'taxStr': String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](tax))) }), (await BackendlessUI.Functions.Custom['fn_28faa43ab591eb1821898cd453e50ae9'](cart, tax, tips))))
}

})
define('./functions/4b2f6a4582f49b7b93bc12c5ee18b8fe/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'] = function fn_4b2f6a4582f49b7b93bc12c5ee18b8fe(list, itemProperty, comparementField) {
return (list[(((list.map(item => item[comparementField])).indexOf(itemProperty) + 1) - 1)])
}

})
define('./functions/51c9e59364164f22ddfea5944f05aab3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'] = async function fn_51c9e59364164f22ddfea5944f05aab3(number) {
return (await (async function(number) {
	return number.toFixed(2)
})(number))
}

})
define('./functions/53e1ed49aa1ad3d8f818a184f7359967/code.js', () => { 

BackendlessUI.Functions.Custom['fn_53e1ed49aa1ad3d8f818a184f7359967'] = async function fn_53e1ed49aa1ad3d8f818a184f7359967(locationId, pagedata) {
  await (async function(location, pageData) {
  	const appId = 'sandbox-sq0idb-kLb8XvN1b39fqhcwkFGFKw';
  	const locationId = location;

  	async function main() {
  	  const payments = Square.payments(appId, locationId);
  	  const card = await payments.card();

  	  await card.attach('#card-container');

  	  pageData.loading = false;

  	  async function eventHandler(event) {
  	    event.preventDefault();

  	    try {
  	      const result = await card.tokenize();

  	      if (result.status === 'OK') {
  	        pageData.cardToken = result.token;
  	      }
  	    } catch (e) {
  	      console.error(e);
  	    }
  	  };

  	  const cardButton = document.querySelector('.card__submit');
  	  cardButton.addEventListener('click', eventHandler);
  	}

  	main();
  })(locationId, pagedata);

}

})
define('./functions/68014e5426756ca6afb9b717b4664334/code.js', () => { 

BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'] = async function fn_68014e5426756ca6afb9b717b4664334(component, imageList) {
var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  item = (await (async function(list, id) {
  	return list.find(item => item.squareId === id)
  })(imageList, (getObjectProperty(component, 'id'))));
  if (item) {
    component['image'] = (getObjectProperty(item, 'image'));
  } else {
    component['image'] = '/img/no-img.png';
  }

}

})
define('./functions/6eaf9b25814cec57778d16ac3dc12086/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6eaf9b25814cec57778d16ac3dc12086'] = function fn_6eaf9b25814cec57778d16ac3dc12086(cart) {
var taxes, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  taxes = [];
  for (var j_index in cart) {
    j = cart[j_index];
    if (getObjectProperty(j, 'taxAmount')) {
      addItemToList(taxes, (Number((getObjectProperty(j, 'taxAmount')))));
    }
  }

return (!taxes.length ? 0 : taxes.reduce(function(x, y) {return x + y;}) / 100)
}

})
define('./functions/6f23a1f3b70c5566bd81aed8bb2297a4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6f23a1f3b70c5566bd81aed8bb2297a4'] = async function fn_6f23a1f3b70c5566bd81aed8bb2297a4(order, cart, payment, usedBalance, locationObjId, method, type) {
var order, payment, usedBalance, item, cart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveOrder`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'squareId': (getObjectProperty(order, 'id')),'item': (await Promise.all(cart.map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'item': ({ 'objectId': (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')) }),'name': (getObjectProperty(item, 'name')),'image': (getObjectProperty(item, 'image')),'selectedModifier': (getObjectProperty(item, 'selectedModifiers')),'selectedVariation': (getObjectProperty(item, 'selectedVariationId')),'variations': (getObjectProperty(item, 'variations')),'modifiers': (getObjectProperty(item, 'modifiers')) });}))),'usedBonus': usedBalance,'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'method': method,'squareId': (getObjectProperty(payment, 'id')),'type': type }),'location': ({ 'objectId': locationObjId }),'title': (getObjectProperty(order, 'ticket_name')) }))))
}

})
define('./functions/89844db7d6677ddcee222b521258184d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d'] = async function fn_89844db7d6677ddcee222b521258184d(newItem, pageData, appData) {
var cart, cartItem, currentOrder, item, j, k, locationId, modifier, modifiers, newItem, orderItem, rewardItem, rewardName, updatedOrder, user, userData, variationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  newItem = (JSON.parse((JSON.stringify(newItem))));
  pageData['loading'] = true;
  userData = (getObjectProperty(appData, 'userData'));
  locationId = (getObjectProperty((getObjectProperty(userData, 'activeLocation')), 'id'));
  user = (getObjectProperty(appData, 'user'));
  cart = (getObjectProperty(userData, 'cart'));
  if ((getObjectProperty(newItem, 'modifier')) && !(getObjectProperty(newItem, 'modifiers'))) {
    newItem['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(appData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(newItem, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(newItem, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(newItem, 'variations'))) {
    newItem['variations'] = (getObjectProperty(newItem, 'item_data.variations'));
  }
  if (getObjectProperty(newItem, 'selectedVariationId')) {
    variationId = (getObjectProperty(newItem, 'selectedVariationId'));
  } else {
    variationId = (getObjectProperty(((getObjectProperty(newItem, 'variations'))[0]), 'id'));
    newItem['selectedVariationId'] = variationId;
  }
  modifiers = [];
  if (getObjectProperty(newItem, 'selectedModifiers')) {
    var j_list = (getObjectProperty(newItem, 'selectedModifiers'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(modifiers, j);
    }
  } else {
    newItem['selectedModifiers'] = ({  });
  }
  newItem['orderData'] = ({ 'quantity': '1','catalog_object_id': variationId,'modifiers': modifiers });
  newItem['quantity'] = '1';
  if (Array.isArray(cart)) {
    cartItem = (await BackendlessUI.Functions.Custom['fn_8e28277e1bdfb38d4d1f2b8a6b6ad168'](cart, newItem));
    if (cartItem) {
      (getObjectProperty(cartItem, 'orderData'))['quantity'] = (String((Number((getObjectProperty(cartItem, 'orderData.quantity')))) + (Number((getObjectProperty(newItem, 'orderData.quantity'))))));
      cartItem['quantity'] = (String((Number((getObjectProperty(cartItem, 'quantity')))) + (Number((getObjectProperty(newItem, 'quantity'))))));
      userData['cart'] = cart;
    } else {
      addItemToList(cart, newItem);
    }
  } else {
    userData['cart'] = [newItem];
  }
  currentOrder = (getObjectProperty(userData, 'currentOrder'));
  if (currentOrder) {
    orderItem = (await (async function(list, id) {
    	return list.find(item => item.catalog_object_id === id && !item.applied_discounts && !item.modifiers)
    })((getObjectProperty(currentOrder, 'line_items')), (getObjectProperty(newItem, 'selectedVariationId'))));
    if (orderItem) {
      orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) + (Number((getObjectProperty(newItem, 'quantity'))))));
    }
    updatedOrder = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': locationId,'line_items': orderItem ? (getObjectProperty(currentOrder, 'line_items')) : (addItemToList((getObjectProperty(currentOrder, 'line_items')), (getObjectProperty(newItem, 'orderData')))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'id': (getObjectProperty(currentOrder, 'id')),'version': (getObjectProperty(currentOrder, 'version')) }) })), 'order'));
    userData['currentOrder'] = updatedOrder;
    for (var j_index2 in cart) {
      j = cart[j_index2];
      delete j['reward'];
    }
    var j_list2 = (getObjectProperty(updatedOrder, 'line_items'));
    for (var j_index3 in j_list2) {
      j = j_list2[j_index3];
      if (getObjectProperty(j, 'applied_discounts')) {
        rewardName = (getObjectProperty(j, 'name'));
        for (var k_index in cart) {
          k = cart[k_index];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            rewardItem = k;
            break;
          }
        }
        break;
      }
    }
    localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
  }
  localStorage.setItem('cart', JSON.stringify(cart));

}

})
define('./functions/8e28277e1bdfb38d4d1f2b8a6b6ad168/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8e28277e1bdfb38d4d1f2b8a6b6ad168'] = async function fn_8e28277e1bdfb38d4d1f2b8a6b6ad168(cart, newItem) {
var cartItem, j, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  for (var j_index in cart) {
    j = cart[j_index];
    if ((getObjectProperty(newItem, 'id')) == (getObjectProperty(j, 'id')) && (getObjectProperty(newItem, 'selectedVariationId')) == (getObjectProperty(j, 'selectedVariationId')) && (Object.keys((getObjectProperty(j, 'selectedModifiers')))).length == (Object.keys((getObjectProperty(newItem, 'selectedModifiers')))).length && (!(getObjectProperty(j, 'orderData.modifiers')).length || !(await asyncListFilter((getObjectProperty(j, 'orderData.modifiers')), async (item) => {


     return (!(((getObjectProperty(newItem, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
    })).length)) {
      cartItem = j;
      break;
    }
  }

return cartItem
}

})
define('./functions/917b2ff20353d818fb5f4651020c5408/code.js', () => { 

BackendlessUI.Functions.Custom['fn_917b2ff20353d818fb5f4651020c5408'] = function fn_917b2ff20353d818fb5f4651020c5408(phone) {
return (phone.slice(0, 3) == '380' ? '+' : (phone.slice(0, 2) == '+1' ? '' : '+1'))
}

})
define('./functions/91bb94042c7f02453a544691e012b78f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f'] = function fn_91bb94042c7f02453a544691e012b78f(location) {
var openTimeStr, dayPart, openHours, openMinutes, openingTime, dayOfWeek;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dayOfWeek = (new Date((new Date())).getDay());
  if (dayOfWeek == 6) {
    openingTime = (getObjectProperty(((getObjectProperty(location, 'business_hours.periods'))[0]), 'start_local_time'));
  } else if (dayOfWeek == 7) {
    openingTime = (getObjectProperty(((getObjectProperty(location, 'business_hours.periods'))[1]), 'start_local_time'));
  } else {
    openingTime = (getObjectProperty(((getObjectProperty(location, 'business_hours.periods'))[((dayOfWeek + 2) - 1)]), 'start_local_time'));
  }
  openHours = (Number((openingTime.slice(0, 2))));
  openMinutes = (Number((openingTime.slice(3, 5))));
  if (openHours >= 12) {
    dayPart = 'p.m';
    if (openHours > 12) {
      openHours = openHours - 12;
    }
  } else {
    dayPart = 'a.m';
  }
  if (openMinutes) {
    openTimeStr = [openHours,':',openMinutes < 10 ? String('0') + String(openMinutes) : openMinutes,' ',dayPart].join('');
  } else {
    openTimeStr = [openHours,' ',dayPart].join('');
  }

return (['Thank you for choosing ',(getObjectProperty(location, 'name')),'! We\'re closed for the day, but we\'ll be back tomorrow at ',openTimeStr,'. Please come back then to place your order and we\'ll be ready to serve you. We appreciate your business!'].join(''))
}

})
define('./functions/93aab44d974426490d74d4cd5b242d7e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_93aab44d974426490d74d4cd5b242d7e'] = async function fn_93aab44d974426490d74d4cd5b242d7e(error) {
  await (async function(error) {
  	alert(error)
  })(error);

}

})
define('./functions/9cfc4eb554c4e994d6419643d4f44dd8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9cfc4eb554c4e994d6419643d4f44dd8'] = async function fn_9cfc4eb554c4e994d6419643d4f44dd8(item, tax) {
var taxAmount, tax, sum, j, quantity, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(item, 'reward')) {
    quantity = (Number((getObjectProperty(item, 'quantity')))) - 1;
  } else {
    quantity = (Number((getObjectProperty(item, 'quantity'))));
  }
  price = (getObjectProperty(item, 'itemPrice'));
  sum = price * quantity;
  item['sum'] = sum;
  item['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
  taxAmount = 0;
  var j_list = (getObjectProperty(item, 'item_data.tax_ids'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((tax.map(item => item['id'])).includes(j)) {
      tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'](tax, j, 'id'));
      taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
    }
  }
  item['taxAmount'] = taxAmount;

}

})
define('./functions/ad211bac0f872b8ab4fffec74df842d8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'] = async function fn_ad211bac0f872b8ab4fffec74df842d8(pageData, location, appData) {
var menu, error, object, location2, categoryItem, k, squareObjects, j, ids, menuTimestamp, menuCache, timestamps, categories, category, item, variation, currentItem, modifier;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function listenMenuAdd() {
  getRTSubscriptions().remove('menuAdd');
  try {
    location2 = [(getObjectProperty(pageData, 'activeLocation'))];

    ;(function(parentObjects) {
      const callback = async object => {
          menu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'id': JSON.stringify(((getObjectProperty(object, 'children'))[0])) }).send());
      await setCategories();
      getRTSubscriptions().remove('menuAdd');

      };

      const rtHandlers = Backendless.Data.of('Location').rt();

      const options = ['menu'];

      if (parentObjects) {
         options.push(parentObjects);
      }

      options.push(callback)

      rtHandlers.addSetRelationListener.apply(rtHandlers, options);

      getRTSubscriptions().add('menuAdd', () => rtHandlers.removeSetRelationListener(callback));

    })(location2);

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      pageData['networkError'] = true;
    }

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function listenCategoryAdd() {
  getRTSubscriptions().remove('AddNewCategory');

  ;(function(parentObjects) {
    const callback = async object => {
        try {
      addItemToList((getObjectProperty(pageData, 'categoryList')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/getNewCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(object, 'children'))))));
      await listenCategoryUpdate();

    } catch (error) {
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        pageData['networkError'] = true;
      }
      console.log(error);

    }

    };

    const rtHandlers = Backendless.Data.of('Menu').rt();

    const options = ['category'];

    if (parentObjects) {
       options.push(parentObjects);
    }

    options.push(callback)

    rtHandlers.addAddRelationListener.apply(rtHandlers, options);

    getRTSubscriptions().add('AddNewCategory', () => rtHandlers.removeAddRelationListener(callback));

  })([menu]);
    }

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function setCategories() {
  categories = (await asyncListSort((getObjectProperty(menu, 'category')), 1, async (category) => {
     if (!!(await asyncListFilter((getObjectProperty(category, 'item')), async (item) => {


     return (getObjectProperty(item, 'id'));
    })).length) {
      category['show'] = true;
    }


   return (getObjectProperty(category, 'order'));
  }));
  pageData['categoryList'] = categories;
  appData['categoryList'] = categories;
  await listenCategoryUpdate();
  await listenCategoryAdd();
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Describe this function...
 */
async function listenCategoryUpdate() {
  getRTSubscriptions().remove('AddItemToCategory');
  getRTSubscriptions().remove('updateCategory');
  getRTSubscriptions().remove('deleteCategory');

  ;(function(whereClause) {
    const callback = async object => {
        removeItemInList((getObjectProperty(pageData, 'categoryList')), object, 'objectId');
    if ((getObjectProperty(pageData, 'chosenCategory')) && (getObjectProperty((getObjectProperty(pageData, 'chosenCategory')), 'objectId')) == (getObjectProperty(object, 'objectId'))) {
      Object.assign(pageData, ({ 'chosenCategory': ({  }),'showCategory': true,'showCatalog': false,'showItem': false }));
    }

    };

    const rtHandlers = Backendless.Data.of('Category').rt();

    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addDeleteListener.apply(rtHandlers, options);

    getRTSubscriptions().add('deleteCategory', () => rtHandlers.removeDeleteListener(callback));

  })((['objectId in (\'',((getObjectProperty(pageData, 'categoryList')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));

  ;(function(whereClause) {
    const callback = async object => {
        category = (await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(pageData, 'categoryList')), (getObjectProperty(object, 'objectId'))));
    if ((getObjectProperty(category, 'order')) != (getObjectProperty(object, 'order'))) {
      Object.assign(category, object);
      pageData['categoryList'] = (await asyncListSort((getObjectProperty(pageData, 'categoryList')), 1, async (item) => {


       return (getObjectProperty(item, 'order'));
      }));
    } else {
      Object.assign(category, object);
    }

    };

    const rtHandlers = Backendless.Data.of('Category').rt();

    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addUpdateListener.apply(rtHandlers, options);

    getRTSubscriptions().add('updateCategory', () => rtHandlers.removeUpdateListener(callback));

  })((['objectId in (\'',((getObjectProperty(pageData, 'categoryList')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));
    }

/**
 * Describe this function...
 */
async function listenItems() {
  getRTSubscriptions().remove('listenItemChange');

  ;(function(whereClause) {
    const callback = async object => {
        Object.assign((await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty((getObjectProperty(pageData, 'chosenCategory')), 'item')), (getObjectProperty(object, 'objectId')))), object);
    currentItem = (await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(pageData, 'catalogList')), (getObjectProperty(object, 'objectId'))));
    if (getObjectProperty(object, 'modifier')) {
      currentItem['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(pageData, 'modifiersList')), async (modifier) => {


       return ((Object.keys((getObjectProperty(object, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
      })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


         return ((getObjectProperty(object, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
        }));
      ; return item;})));
    }
    currentItem['variations'] = (await asyncListFilter((getObjectProperty(currentItem, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(object, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));

    };

    const rtHandlers = Backendless.Data.of('Item').rt();

    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addUpdateListener.apply(rtHandlers, options);

    getRTSubscriptions().add('listenItemChange', () => rtHandlers.removeUpdateListener(callback));

  })((['objectId IN (\'',((getObjectProperty((getObjectProperty(pageData, 'chosenCategory')), 'item')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));
    }


  try {
    menuCache = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})((getObjectProperty(location, 'menuId'))));
    timestamps = (getObjectProperty(appData, 'menuTimestamps'));
    menuTimestamp = (await (async function(list, id) {
    	return list.find(item => item.menuId === id)
    })(timestamps, (getObjectProperty(location, 'menuId'))));
    if (menuCache && (getObjectProperty(menuCache, 'changeDate')) == menuTimestamp) {
      menu = (getObjectProperty(menuCache, 'menu'));
    } else {
      menu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getMenuById`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'id': JSON.stringify((getObjectProperty(location, 'menuId'))) }).send());
      localStorage.setItem((getObjectProperty(location, 'menuId')), JSON.stringify(({ 'menu': menu,'changeDate': (getObjectProperty(menuTimestamp, 'changeDate')) })));
    }
    appData['activeMenuId'] = (getObjectProperty(location, 'menuId'));
    ids = (await (async function(arr) {
    	return [...new Set(arr)]
    })(((await (async function(arr) {
    	return arr.flat()
    })(((getObjectProperty(menu, 'category')).map(item => item['item'])))).map(item => item['squareId']))));
    squareObjects = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(ids))), 'objects'));
    pageData['squareMenuItems'] = squareObjects;
    var j_list = (getObjectProperty(menu, 'category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      var k_list = (getObjectProperty(j, 'item'));
      for (var k_index in k_list) {
        k = k_list[k_index];
        categoryItem = (await (async function(list, id) {
        	return list.find(item => item.id === id)
        })(squareObjects, (getObjectProperty(k, 'squareId'))));
        if (categoryItem) {
          k['id'] = (getObjectProperty(categoryItem, 'id'));
          k['item_data'] = (getObjectProperty(categoryItem, 'item_data'));
        } else {
        }
      }
    }
    await setCategories();

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      pageData['networkError'] = true;
    }

  }

}

})
define('./functions/ae27dc58f88a9714e85d5f17d4b83f38/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38'] = function fn_ae27dc58f88a9714e85d5f17d4b83f38() {
var pickupDate;


  pickupDate = (new Date());
  pickupDate.setMinutes(((new Date(pickupDate).getMinutes()) + 10));

return pickupDate
}

})
define('./functions/b3a18051eaadb1e812933d5f812b0b0e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e'] = async function fn_b3a18051eaadb1e812933d5f812b0b0e() {
return (await (async function() {
	const code1 = document.querySelector('.code-1').firstElementChild.firstElementChild;
	const code2 = document.querySelector('.code-2').firstElementChild.firstElementChild;
	const code3 = document.querySelector('.code-3').firstElementChild.firstElementChild;
	const code4 = document.querySelector('.code-4').firstElementChild.firstElementChild;
	const code5 = document.querySelector('.code-5').firstElementChild.firstElementChild;
	
	return code1 === document.activeElement || 
	       code2 === document.activeElement || 
	       code3 === document.activeElement || 
	       code4 === document.activeElement || 
	       code5 === document.activeElement
})())
}

})
define('./functions/bae2b08d5cd1ee36e77782049bb4b3ad/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bae2b08d5cd1ee36e77782049bb4b3ad'] = async function fn_bae2b08d5cd1ee36e77782049bb4b3ad(images, item) {
var modifierNames, modifiers, itemImages;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  itemImages = images;
  if (!(getObjectProperty(item, 'image'))) {
    item['image'] = (itemImages ? ((await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'](itemImages, (getObjectProperty(item, 'name')), 'name')) ? (getObjectProperty((await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'](itemImages, (getObjectProperty(item, 'name')), 'name')), 'url')) : null) : null);
  }
  modifiers = (getObjectProperty(item, 'modifiers'));
  if (modifiers) {
    modifierNames = (modifiers.map(item => item['name'])).join(', ');
  }
  item['modifiersString'] = (modifierNames ? [(getObjectProperty(item, 'variation_name')),', ',modifierNames].join('') : (getObjectProperty(item, 'variation_name')));
  item['priceString'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty((getObjectProperty(item, 'gross_sales_money')), 'amount')) / 100)))));

}

})
define('./functions/bb44ca5c2f64b9195b6f2012de579bc1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'] = function fn_bb44ca5c2f64b9195b6f2012de579bc1(card) {
var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(card, 'card_brand')) == 'VISA') {
    imageUrl = '/img/card_logo/visa.png';
  } else {
    imageUrl = '/img/card_logo/mastercard.png';
  }

return imageUrl
}

})
define('./functions/bfc548691c9afea98ab7f3ec02f5415c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'] = async function fn_bfc548691c9afea98ab7f3ec02f5415c(appData, pageData) {
var item, locationsList, i, distanceList, location2, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  try {
    location2 = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    if (!(getObjectProperty(appData, 'coordinates'))) {
      appData['coordinates'] = (await Promise.all(((getObjectProperty(appData, 'locationsList')).map(item => item['coordinates'])).map(async item => {; return ({ 'lat': (getObjectProperty(item, 'latitude')),'lng': (getObjectProperty(item, 'longitude')) });})));
    }

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      pageData['loading'] = false;
      pageData['networkError'] = true;
    }

  }
  if (location2) {
    appData['mapCenter'] = ({ 'lat': (getObjectProperty(location2, 'lat')),'lng': (getObjectProperty(location2, 'lng')) });
    while (!(getObjectProperty(appData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(appData, 'coordinates')), (getObjectProperty(appData, 'mapCenter')), appData);
    while (!(getObjectProperty(appData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(appData, 'distanceList'));
    locationsList = (getObjectProperty(appData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
      (locationsList[(i - 1)])['distanceVal'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.value'));
    }
    appData['locationsList'] = (await asyncListSort(locationsList, 1, async (item) => {


     return (getObjectProperty(item, 'distanceVal'));
    }));
    appData['filteredLocationList'] = (await asyncListSort(locationsList, 1, async (item) => {


     return (getObjectProperty(item, 'distanceVal'));
    }));
  }

}

})
define('./functions/c2bee686ed885b1408b94e5c34acef12/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12'] = async function fn_c2bee686ed885b1408b94e5c34acef12() {
  await (async function() {
  	window.history.back()
  })();

}

})
define('./functions/d82335856f765b64e571be37c56d0e1d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d'] = function fn_d82335856f765b64e571be37c56d0e1d(cart) {
return (!cart.length ? 0 : (cart.map(item => item['sum'])).reduce(function(x, y) {return x + y;}))
}

})
define('./functions/d8cc31b5bafa725dab15cd36453e4669/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'] = async function fn_d8cc31b5bafa725dab15cd36453e4669(order, images) {
var dayPart, minute, hour, date, months, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createAmountString(amount) {
  return String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((amount / 100))))
}


  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  date = (getObjectProperty(order, 'created_at'));
  hour = (new Date(date).getHours());
  minute = (new Date(date).getMinutes());
  if (hour < 12) {
    if (hour == 0) {
      hour = 12;
    }
    dayPart = 'AM';
  } else {
    if (hour > 12) {
      hour = hour - 12;
    }
    dayPart = 'PM';
  }
  if (minute < 10) {
    minute = String(0) + String(minute);
  }

return ({ 'dateStr': ['Order placed ',months[((new Date(date).getMonth() + 1) - 1)],' ',(new Date(date).getDate()),', ',[hour,':',minute,' ',dayPart].join('')].join(''),'totalMoneyStr': await createAmountString((getObjectProperty(order, 'total_money.amount'))),'totalTipStr': await createAmountString((getObjectProperty((getObjectProperty(order, 'total_tip_money')), 'amount'))),'totalTaxStr': await createAmountString((getObjectProperty((getObjectProperty(order, 'total_tax_money')), 'amount'))),'paymentMethodStr': (getObjectProperty(order, 'tenders')) && (getObjectProperty(order, 'tenders.type')) == 'CARD' ? 'Card' : 'Digital Wallet' })
}

})
define('./functions/deae239fbd5105dc37d8b961045461fd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'] = async function fn_deae239fbd5105dc37d8b961045461fd(stateObj) {
  await (async function(stateObj) {
  	window.history.pushState(stateObj, '')
  })(stateObj);

}

})
define('./functions/e6a123cfb14311c56e1eaf98c8a8fb01/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'] = async function fn_e6a123cfb14311c56e1eaf98c8a8fb01(appData, order, pageData) {
var error, locationId, loyalty, loyaltyAcc, loyaltyProgram, remainder, response, rewardPointsNeeded, rewardsList, user, userData, userLoyalty;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  try {
    userData = (getObjectProperty(appData, 'userData'));
    user = (getObjectProperty(appData, 'user'));
    locationId = (getObjectProperty(userData, 'activeLocation.id'));
    loyalty = (getObjectProperty(appData, 'loyalty'));
    userLoyalty = (getObjectProperty(userData, 'loyalty'));
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/accumulate-loyalty-points`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order_id': (getObjectProperty(order, 'id')),'location_id': locationId,'account_id': (getObjectProperty(user, 'loyaltyId')) }));
    loyaltyProgram = (getObjectProperty(loyalty, 'program'));
    loyaltyAcc = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/getLoyaltyAccount`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'account_id': JSON.stringify((getObjectProperty(user, 'loyaltyId'))) }).send()), 'loyalty_account'));
    rewardPointsNeeded = (getObjectProperty(((getObjectProperty(loyaltyProgram, 'reward_tiers'))[0]), 'points'));
    rewardsList = [];
    remainder = (getObjectProperty(loyaltyAcc, 'balance')) % rewardPointsNeeded;
    userLoyalty['account'] = loyaltyAcc;
    userLoyalty['loyaltyStr'] = (String(rewardPointsNeeded - remainder) + String(' drinks'));
    userLoyalty['loyaltyImgUrl'] = (['./styles/assets/img/reward/gem',remainder,'.svg'].join(''));
    var repeat_end = Math.floor((getObjectProperty(loyaltyAcc, 'balance')) / rewardPointsNeeded);
    for (var count = 0; count < repeat_end; count++) {
      addItemToList(rewardsList, '');
    }
    userLoyalty['rewardsList'] = rewardsList;
    userLoyalty['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      pageData['loading'] = false;
      pageData['networkError'] = true;
    }

  }

}

})
define('./functions/eed592775522bc4a315154cb89c478f8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_eed592775522bc4a315154cb89c478f8'] = function fn_eed592775522bc4a315154cb89c478f8(categoryName) {
var image;


  if (categoryName == 'Coffee and Espresso') {
    image = '/img/category/coffee-and-espresso.png';
  } else if (categoryName == 'Drinks (No Espresso)') {
    image = '/img/category/no-espresso.png';
  } else if (categoryName == 'Food') {
    image = '/img/category/food.png';
  } else if (categoryName == 'Kids') {
    image = '/img/category/kids.png';
  } else if (categoryName == 'Retail') {
    image = '/img/category/retail.png';
  } else if (categoryName == 'Supreme Energy') {
    image = '';
  }

return image
}

})
define('./functions/f59976c923cc6f4e446e367af9c67aae/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f59976c923cc6f4e446e367af9c67aae'] = async function fn_f59976c923cc6f4e446e367af9c67aae(item, taxes, modifiers, variation) {
var price, modifiersSum, modifierPrices, item;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (modifiers) {
    modifierPrices = (await asyncListFilter(((modifiers.map(item => item['modifier_data'])).map(item => item['price_money'])), async (item) => {


     return item;
    }));
  } else {
    modifierPrices = [];
  }
  modifiersSum = !modifierPrices.length ? 0 : (modifierPrices.map(item => item['amount'])).reduce(function(x, y) {return x + y;});
  price = modifiersSum + (getObjectProperty((getObjectProperty((getObjectProperty(variation, 'item_variation_data')), 'price_money')), 'amount'));
  item['itemPrice'] = price;
  await BackendlessUI.Functions.Custom['fn_9cfc4eb554c4e994d6419643d4f44dd8'](item, taxes);

}

})
define('./functions/f91fce3b100aa72ab427a96851194a4c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f91fce3b100aa72ab427a96851194a4c'] = async function fn_f91fce3b100aa72ab427a96851194a4c(order, user, tips, locationId, source) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': (getObjectProperty(order, 'total_money.amount')),'source_id': ((getObjectProperty(order, 'total_money.amount')) ? (getObjectProperty(user, 'walletId')) : 'CASH'),'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'tip': (tips * 100),'location_id': locationId })), 'payment'))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/authorization/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete ___arguments.context.appData['appLoaded'];
  if (await Backendless.UserService.isValidLogin()) {
    user = (await Backendless.UserService.getCurrentUser(false));
    ___arguments.context.appData['user'] = user;
    if ((getObjectProperty(user, 'email')) && (getObjectProperty(user, 'name'))) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization_signup', undefined);
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/authorization/components/1c9cfd4506b64bfe654c2bf2f6448b05/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, phone;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function loginWithTwilio() {
  Object.assign(___arguments.context.appData, ({ 'phone': phone,'transaction': (await Backendless.Request.post(`${Backendless.appPath}/services/Twilio/loginWithPhoneNumber`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(phone))) }));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization-code', undefined);
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'phone')) {
      phone = String('+') + String((String((await BackendlessUI.Functions.Custom['fn_917b2ff20353d818fb5f4651020c5408']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'phone'))))) + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'phone'))).replace((new RegExp('\\D', 'g')), ''));
    }
    if (phone) {
      try {
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('18dbeb3961183e122ed1300823d6cf2d', false);
        await loginWithTwilio();

      } catch (error) {
        if ((getObjectProperty(error, 'message')) == 'There is no user with provided phone number') {
          user = (await Backendless.UserService.register( new Backendless.User({ 'phoneNumber': phone,'password': (generateUUID().short()) }) ));
          try {
            await loginWithTwilio();

          } catch (error) {
            if ((getObjectProperty(error, 'code')) == 21211) {
              (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('18dbeb3961183e122ed1300823d6cf2d', true);
              ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('18dbeb3961183e122ed1300823d6cf2d'))['content'] = 'Invalid phone number';
              await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(user, 'objectId')) });
            }
            console.log(error);

          }
        } else {
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('18dbeb3961183e122ed1300823d6cf2d', true);
          ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('18dbeb3961183e122ed1300823d6cf2d'))['content'] = (getObjectProperty(error, 'message'));
        }

      }
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('18dbeb3961183e122ed1300823d6cf2d', true);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('18dbeb3961183e122ed1300823d6cf2d'))['content'] = 'Please enter a phone number';
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization/components/c29a9467ff4b7451d5dfa85688ccf54f/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  await (async function(phone) {
  	const btn = document.querySelector('.btn-login');
  	if(phone) btn.click();
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'phone')));

  },
  /* handler:onBlur */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('18dbeb3961183e122ed1300823d6cf2d', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/authorization/components/c9d817dc47e4433bf55580e386f8bac4/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c29a9467ff4b7451d5dfa85688ccf54f')), 'el')), ({ 'type': 'tel','maxLength': 25,'inputMode': 'tel','autocomplete': 'tel-national' }));

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c29a9467ff4b7451d5dfa85688ccf54f')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization/components/0be63a093b2b1df5af59123f2a84d5fe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization/components/f05dcd297da5f5a400c2dfcbb0a12976/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f05dcd297da5f5a400c2dfcbb0a12976')), 'el'))['noValidate'] = true;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-code/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'phone')) {
    user = (await Backendless.UserService.getCurrentUser(false));
    if (user) {
      if ((getObjectProperty(user, 'email')) && (getObjectProperty(user, 'name'))) {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
      } else {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization-signup', undefined);
      }
    }
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/authorization-code/components/9516d98349d93b7be9815e1937a55ccc/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('76a125d338669c24af45ba8d68a5ca35')), 'el')), ({ 'inputMode': 'numeric' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0cd114b7efc8b994e9df671d748279a')), 'el')), ({ 'inputMode': 'numeric' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3fd2c764b308d685ebf743e92e5f9ddf')), 'el')), ({ 'inputMode': 'numeric' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d57c4261c071caef5a86c59cc3182e15')), 'el')), ({ 'inputMode': 'numeric' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6c36c98900c516f72b58a174b1df43f6')), 'el')), ({ 'inputMode': 'numeric' }));
  await (async function(form) {
  	const inputs = document.querySelectorAll('.form__code_input')

  	inputs.forEach(input => {
  	  input.addEventListener('paste', (event) => {
  	    event.preventDefault

  	    const code = event.clipboardData.getData('text')
  	    form.code = [...code]

  	    const btn = document.querySelector('.code-submit');
  	    btn.click();
  	  })

  	  input.addEventListener('input', (event) => {
  	    // event.preventDefault

  	    const code = event.target.value
  	    // alert(code)
  	    if (code.length === 5) {
  	      form.code = [...code]

  	      const btn = document.querySelector('.code-submit');
  	      btn.click();

  	      input.blur()
  	    }
  	  })
  	})
  })(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'));
  (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('76a125d338669c24af45ba8d68a5ca35')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-code/components/76a125d338669c24af45ba8d68a5ca35/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('76a125d338669c24af45ba8d68a5ca35')), 'el'))['maxLength'] = 1;
  if (___arguments.value) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0cd114b7efc8b994e9df671d748279a')));
  }

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e']('code-1');
  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await new Promise(r => setTimeout(r, 1 || 0));
  if (!(await BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-code/components/e0cd114b7efc8b994e9df671d748279a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0cd114b7efc8b994e9df671d748279a')), 'el'))['maxLength'] = 1;
  if (___arguments.value) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3fd2c764b308d685ebf743e92e5f9ddf')));
  }

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e']('code-2');
  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await new Promise(r => setTimeout(r, 1 || 0));
  if (!(await BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-code/components/3fd2c764b308d685ebf743e92e5f9ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3fd2c764b308d685ebf743e92e5f9ddf')), 'el'))['maxLength'] = 1;
  if (___arguments.value) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d57c4261c071caef5a86c59cc3182e15')));
  }

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e']('code-3');
  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await new Promise(r => setTimeout(r, 1 || 0));
  if (!(await BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-code/components/d57c4261c071caef5a86c59cc3182e15/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d57c4261c071caef5a86c59cc3182e15')), 'el'))['maxLength'] = 1;
  if (___arguments.value) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6c36c98900c516f72b58a174b1df43f6')));
  }

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e']('code-4');
  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await new Promise(r => setTimeout(r, 1 || 0));
  if (!(await BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-code/components/0258ed919dc53544ef465564f94b6658/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['code'] = [];

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-code/components/6c36c98900c516f72b58a174b1df43f6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6c36c98900c516f72b58a174b1df43f6')), 'el'))['maxLength'] = 1;
  if (___arguments.value) {
    await (async function() {
    	const input = document.querySelector('.code-5').firstElementChild.firstElementChild;

    	input.addEventListener('blur', function(e) {
    	  const btn = document.querySelector('.code-submit');
    	  btn.click();
    	})

    	input.blur();
    })();
  }

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_290d3b3804db72ede114ff547e7b148e']('code-5');
  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await (async function() {
  	const btn = document.querySelector('.code-submit');
  	btn.click();
  })();
  await new Promise(r => setTimeout(r, 1 || 0));
  if (!(await BackendlessUI.Functions.Custom['fn_b3a18051eaadb1e812933d5f812b0b0e']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-code/components/52dad02492aef609ec8bcbda3b1db6d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, code;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setIntervals'); if (timerId && timers[timerId]) {    clearInterval(timers[timerId]);    delete timers[timerId]; }}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    code = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'code')).join('');
    if (code.length == 5) {
      ___arguments.context.pageData['loading'] = true;
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ede8aab6db37e009e979fc3741bfc335', false);
      try {
        user = (await Backendless.Request.post(`${Backendless.appPath}/services/Twilio/loginWithCode`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'transactionId': (getObjectProperty(___arguments.context.appData, 'transaction.transactionId')),'code': code }));
        Backendless.Users.setCurrentUser(user, user);
        ___arguments.context.appData['user'] = user;
        stopSetTimeout('codeTimer');
        delete ___arguments.context.appData['transaction'];
        if (!(getObjectProperty(user, 'email')) || !(getObjectProperty(user, 'name'))) {
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization-signup', undefined);
        } else {
          delete ___arguments.context.appData['phone'];
          delete ___arguments.context.appData['userData'];
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
        }

      } catch (error) {
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ede8aab6db37e009e979fc3741bfc335', true);
        if ((getObjectProperty(error, 'code')) == 3044) {
        } else if ((getObjectProperty(error, 'message')).indexOf('Verification code is not valid') !== -1) {
          ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['codeErrorMsg'] = 'Invalid code';
        } else {
          ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['codeErrorMsg'] = 'Invalid code';
        }
        ___arguments.context.pageData['loading'] = false;

      }
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization-code/components/4131655b462c6ec1ec0983078814bd1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var phone;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setIntervals'); if (timerId && timers[timerId]) {    clearInterval(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setIntervals'); const timer = setInterval(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (!(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4131655b462c6ec1ec0983078814bd1a')).includes('disabled'))) {
    phone = String((await BackendlessUI.Functions.Custom['fn_917b2ff20353d818fb5f4651020c5408']((getObjectProperty(___arguments.context.appData, 'phone'))))) + String(getObjectProperty(___arguments.context.appData, 'phone'));
    ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['codeTime'] = 20;

    ;(function() {
      const callback = async () => {
          ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['codeTime'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'codeTime')) - 1);
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'codeTime')) == 0) {
        stopSetTimeout('codeTimer');
      }

      };

      const timerId = 'codeTimer';
      const timerDelay = 1000;

      runSetTimeout(timerId, callback, timerDelay)
    })()
    Object.assign(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), (await Backendless.Request.post(`${Backendless.appPath}/services/Twilio/loginWithPhoneNumber`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(phone))));
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'codeTime')) ? ['form__text', 'form__text--brown', 'disabled'] : ['form__text', 'form__text--brown'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/authorization-code/components/f05dcd297da5f5a400c2dfcbb0a12976/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setIntervals'); if (timerId && timers[timerId]) {    clearInterval(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setIntervals'); const timer = setInterval(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f05dcd297da5f5a400c2dfcbb0a12976')), 'el'))['noValidate'] = true;
  ___arguments.context.dataModel['codeTime'] = 20;

  ;(function() {
    const callback = async () => {
        ___arguments.context.dataModel['codeTime'] = ((getObjectProperty(___arguments.context.dataModel, 'codeTime')) - 1);
    if ((getObjectProperty(___arguments.context.dataModel, 'codeTime')) == 0) {
      stopSetTimeout('codeTimer');
    }

    };

    const timerId = 'codeTimer';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-code/components/a0f4c5b95c73850c711ed444b13a554b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  delete ___arguments.context.appData['user'];
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization-signup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (await Backendless.UserService.getCurrentUser(false));
    if ((getObjectProperty(user, 'email')) && (getObjectProperty(user, 'name'))) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
    }
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/authorization-signup/components/802915ec41fb75e40683b412127d33a8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  onValueAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthday')) {
    return ([(new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthday'))).getDate()),'/',(new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthday'))).getMonth() + 1),'/',(new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthday'))).getFullYear())].join(''));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/authorization-signup/components/6531e95f655b9ae1e5ea69690e7a6cb4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var birthday, name2, email, error, user, customer, loyaltyAcc, phone, errorText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function deleteCustomer() {
  await Backendless.Request.delete(`${Backendless.appPath}/services/Square/delete-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(customer));
}

/**
 * Describe this function...
 */
async function showPhoneError(errorText) {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ff95b0b423e056802ecbf222bf66d28', true);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9ff95b0b423e056802ecbf222bf66d28'))['content'] = errorText;
  ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['loading'] = false;
}

/**
 * Describe this function...
 */
async function showEmailError(errorText) {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4865377f6cc84a3d9e9255b7490a71bc', true);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4865377f6cc84a3d9e9255b7490a71bc'))['content'] = errorText;
  ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['loading'] = false;
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95de163b422b1189e08bd798dd937027', false);
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'email'));
  name2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'name'));
  user = (getObjectProperty(___arguments.context.appData, 'user'));
  phone = (getObjectProperty(user, 'phoneNumber'));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'loading')) && !(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5888560d5af64bf7009c49b8aa0d7471')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('83e91bd1dda83cd434f9fb18e0093c17', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4865377f6cc84a3d9e9255b7490a71bc', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5888560d5af64bf7009c49b8aa0d7471', false);
    if (email && name2) {
      if (email.match((new RegExp('^[\\w\\.]+@([\\w-]+\\.)+[\\w-]{1,25}$', '')))) {
        ___arguments.context.pageData['loading'] = true;
        user['email'] = email;
        user['name'] = name2;
        if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear'))) {
          birthday = (new Date());
          birthday.setFullYear((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')))));
          birthday.setMonth(((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')))) - 1));
          birthday.setDate((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')))));
          birthday.setHours(0);
          birthday.setMinutes(0);
          birthday.setSeconds(0);
          birthday.setMilliseconds(0);
          user['dateOfBirth'] = birthday;
        }
        try {
          customer = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'birthday': ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')) ? [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')),'-',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')),'-',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay'))].join('') : ''),'email': email,'username': name2,'phone': phone }));
          if (!(getObjectProperty(user, 'loyaltyId'))) {
            if (phone == '+380635699170' || phone == '+380687746790') {
              user['loyaltyId'] = '8a51197a-a685-4c79-bc89-7766d86a1297';
            } else {
              try {
                loyaltyAcc = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-loyalty-account`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'phone_number': (String(phone) + String('')),'customer_id': customer })), 'loyalty_account'));

              } catch (error) {
                if (((getObjectProperty(error, 'message')).indexOf('Phone number already associated to a loyalty account.') !== -1) || ((getObjectProperty(error, 'message')).indexOf('Customer id already has a loyalty account') !== -1)) {
                  loyaltyAcc = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/searchLoyaltyAccounts`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(phone))), 'loyalty_accounts'))[0];
                }

              }
              user['loyaltyId'] = (getObjectProperty(loyaltyAcc, 'id'));
            }
          }
          user['customerId'] = customer;
          ___arguments.context.appData['user'] = user;
          await Backendless.UserService.update( new Backendless.User(user) );
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
          return ;

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')).indexOf('INVALID_EMAIL_ADDRESS') !== -1) {
            await showEmailError('Invalid email address');
          } else if ((getObjectProperty(error, 'code')) == 1155) {
            await showEmailError('Email is already in use');
            await deleteCustomer();
          } else {
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95de163b422b1189e08bd798dd937027', true);
            ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['loading'] = false;
          }

        }
      } else {
        await showEmailError('Invalid email address');
      }
    } else {
      if (!email) {
        await showEmailError('Please enter an email address');
      }
      if (!name2) {
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('83e91bd1dda83cd434f9fb18e0093c17', true);
      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/authorization-signup/components/01eb98f4fafd4e75d2a49d8a1e19ad26/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_3a6650b241d4ae4403e4313b35362cd5'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('01eb98f4fafd4e75d2a49d8a1e19ad26')), 25);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('01eb98f4fafd4e75d2a49d8a1e19ad26'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-signup/components/4652c55463a20d46cd6fa874201bf7ba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_3a6650b241d4ae4403e4313b35362cd5'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4652c55463a20d46cd6fa874201bf7ba')), 250);

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      if (!(await BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-signup/components/f67a2886b6c343716047ae6d27eda2c6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_3a6650b241d4ae4403e4313b35362cd5'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f67a2886b6c343716047ae6d27eda2c6')), 250);

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      if (!(await BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/authorization-signup/components/f05dcd297da5f5a400c2dfcbb0a12976/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f05dcd297da5f5a400c2dfcbb0a12976')), 'el'))['noValidate'] = true;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-signup/components/2f6604bd7c976d8fe8b9b1ee286c5d03/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4652c55463a20d46cd6fa874201bf7ba')), 'el'))['inputmode'] = 'email';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-signup/components/332de1a94907a9ca5f9ab9ebab563699/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b50606fc5fd3fde8b2264b126acf4b1b')), 'el')), ({ 'inputMode': 'numeric','maxLength': '2' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9')), 'el')), ({ 'inputMode': 'numeric','maxLength': '2' }));
  Object.assign((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f199ad68dc021622b98e9083f593da56')), 'el')), ({ 'inputMode': 'numeric','maxLength': '4' }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/authorization-signup/components/f2cb2bd218d51f325406897137beb49f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var date, lastDay;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  date = (new Date());
  date.setMonth((___arguments.value - 1));
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')) {
    date.setFullYear((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')));
  }
  lastDay = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
  ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['daySelect'] = (await BackendlessUI.Functions.Custom['fn_42dfd10e468212be0071a84d163af59f'](1, lastDay, 1));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) > lastDay) {
    ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['birthDay'] = lastDay;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/authorization-signup/components/cc22db38da03e6950e091a42ec11a839/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var lastDay, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  date = (new Date());
  date.setFullYear(___arguments.value);
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) {
    date.setMonth(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) - 1));
  }
  lastDay = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
  ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['daySelect'] = (await BackendlessUI.Functions.Custom['fn_42dfd10e468212be0071a84d163af59f'](1, lastDay, 1));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) > lastDay) {
    ___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976')['birthDay'] = lastDay;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/authorization-signup/components/b50606fc5fd3fde8b2264b126acf4b1b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var maxDate, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5888560d5af64bf7009c49b8aa0d7471', false);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b50606fc5fd3fde8b2264b126acf4b1b'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));
  if ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')))) > 12) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b50606fc5fd3fde8b2264b126acf4b1b'))['value'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b50606fc5fd3fde8b2264b126acf4b1b')), 'value')).charAt(0));
  }
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')) {
    date = (new Date());
    date.setMonth(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) - 1));
    maxDate = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
    if ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')))) > maxDate) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9'))['value'] = maxDate;
    }
  }

  },
  /* handler:onChange */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }
  if ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')))) < 10 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')).length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b50606fc5fd3fde8b2264b126acf4b1b'))['value'] = (String('0') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')));
  }

  },
  /* handler:onBlur */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/authorization-signup/components/b108ab68307e6f8ebfdcf948c60d3ca9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var maxDate, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5888560d5af64bf7009c49b8aa0d7471', false);
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) {
    date = (new Date());
    date.setMonth(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) - 1));
    maxDate = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
  } else {
    maxDate = 31;
  }
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));
  if ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')))) > maxDate) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9'))['value'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9')), 'value')).charAt(0));
  }

  },
  /* handler:onChange */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }
  if ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')))) < 10 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')).length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b108ab68307e6f8ebfdcf948c60d3ca9'))['value'] = (String('0') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthDay')));
  }

  },
  /* handler:onBlur */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/authorization-signup/components/f199ad68dc021622b98e9083f593da56/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await BackendlessUI.Functions.Custom['fn_37bb50d9d5b36cb77803c83887ccf12c']())) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '0';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthMonth')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')) && ((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')))) > (new Date((new Date())).getFullYear()) || (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f05dcd297da5f5a400c2dfcbb0a12976'), 'birthYear')))) < 1950)) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5888560d5af64bf7009c49b8aa0d7471', true);
  }

  },
  /* handler:onBlur */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5888560d5af64bf7009c49b8aa0d7471', false);

  },
  /* handler:onChange */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89ea0952a1973e610d916914161aeb68'))['padding-bottom'] = '50%';
  }

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/checkout-order/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if ((getObjectProperty(___arguments.context.appData, 'appLoaded')) && !!(getObjectProperty(userData, 'cart')).length) {
    ___arguments.context.pageData['tipsList'] = [1, 2, 5];
    ___arguments.context.pageData['activeLocation'] = (getObjectProperty(userData, 'activeLocation'));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/checkout-order/components/39d843e6509e0d55aefb312a686b7b6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cart, currentOrder, error, location2, locationId, loyalty, order, payment, program, user, userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.appData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    location2 = (getObjectProperty(userData, 'activeLocation'));
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a'](location2))) {
      currentOrder = (getObjectProperty(userData, 'currentOrder'));
      locationId = (getObjectProperty(location2, 'id'));
      user = (getObjectProperty(___arguments.context.appData, 'user'));
      cart = (getObjectProperty(userData, 'cart'));
      loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
      try {
        if (currentOrder) {
          order = (await BackendlessUI.Functions.Custom['fn_104fd91d13345e26662507e5fbebf5f4'](currentOrder, user));
        } else {
          order = (await BackendlessUI.Functions.Custom['fn_2d266328cce7f50d2595cc0eddd78a0d'](user, cart, locationId));
        }
        payment = (await BackendlessUI.Functions.Custom['fn_f91fce3b100aa72ab427a96851194a4c'](order, user, (getObjectProperty(___arguments.context.pageData, 'tips')), locationId, ((getObjectProperty(order, 'total_money.amount')) ? (getObjectProperty(___arguments.context.dataModel, 'id')) : 'CASH')));
        Object.assign(order, (await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.appData, 'imageList')))));
        await BackendlessUI.Functions.Custom['fn_251a91f37cf39b8aa532ba567eb18a1b'](order, cart);
        await BackendlessUI.Functions.Custom['fn_6f23a1f3b70c5566bd81aed8bb2297a4'](order, cart, payment, 0, (getObjectProperty(location2, 'objectId')), 'Card', 'Order');
        (getObjectProperty(userData, 'ordersList')).unshift(order);
        program = (getObjectProperty(loyalty, 'program'));
        if (program && (getObjectProperty(program, 'location_ids')) && ((getObjectProperty(program, 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.appData, order, ___arguments.context.pageData);
        }
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        Object.assign(userData, ({ 'previousOrder': order,'cart': [] }));
        delete userData['currentOrder'];
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('cart');
        ___arguments.context.pageData['loading'] = false;

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
          await (async function() {
          	alert('Card declined')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
          await (async function() {
          	alert('Card declined due to insufficient funds')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
          await (async function() {
          	alert('Card declined due to transaction limit')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
          await (async function() {
          	alert('Card expired')
          })();
          await saveOrder();
        }
        if (((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) || ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) || (getObjectProperty(error, 'errors')) && ((getObjectProperty(((getObjectProperty(error, 'errors'))[0]), 'code')) == 'GENERIC_DECLINE' || (getObjectProperty(((getObjectProperty(error, 'errors'))[0]), 'code')) == 'TRANSACTION_LIMIT')) {
          await (async function() {
          	alert('Card declined')
          })();
        }
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }
        Backendless.Logging.getLogger('SERVER_CODE').error((String('Card Order Error: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f'](location2));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkout-order/components/4e0550055f664ebeb1d5cf6e90bc8f91/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/e5330f6b175ab5996fb2d31cc07b83ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cart, error, fieldsToClear, item, j, modifier, newCart, order, rewardsList, updatedOrder, userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['loading'] = true;
  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  cart = (getObjectProperty(userData, 'cart'));
  order = (getObjectProperty(userData, 'currentOrder'));
  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) {
      var j_list = subsequenceFromEndLast((getObjectProperty(order, 'rewards')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice')) - 1));
      for (var j_index in j_list) {
        j = j_list[j_index];
        await Backendless.Request.delete(`${Backendless.appPath}/services/Square/delete-royalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(j, 'id'))));
        rewardsList = (getObjectProperty(userData, 'loyalty.rewardsList'));
        addItemToList(rewardsList, '');
        (getObjectProperty(userData, 'loyalty'))['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
      }
      order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
      userData['currentOrder'] = order;
    }
    if (order) {
      if (cart.length == 1) {
        if (!updatedOrder) {
          updatedOrder = order;
        }
        ;(async () => {
            if (updatedOrder) {
            updatedOrder['state'] = 'CANCELED';
            await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(updatedOrder, 'location_id')),'state': (getObjectProperty(updatedOrder, 'state')),'version': (getObjectProperty(updatedOrder, 'version')),'id': (getObjectProperty(updatedOrder, 'id')) }) });
          }

        })();
      } else {
        fieldsToClear = [];
        if (!updatedOrder) {
          updatedOrder = order;
        }
        var j_list2 = (getObjectProperty(updatedOrder, 'line_items'));
        for (var j_index2 in j_list2) {
          j = j_list2[j_index2];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            addItemToList(fieldsToClear, j);
          }
        }
        updatedOrder = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(updatedOrder, 'location_id')),'line_items': (getObjectProperty(updatedOrder, 'line_items')),'version': (getObjectProperty(updatedOrder, 'version')),'id': (getObjectProperty(updatedOrder, 'id')) }),'fields_to_clear': (!fieldsToClear.length ? null : (await Promise.all(fieldsToClear.map(async item => {; return (['line_items[',(getObjectProperty(item, 'uid')),']'].join(''));})))) })), 'order'));
        userData['currentOrder'] = updatedOrder;
        localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
      }
    }
    newCart = (await asyncListFilter(cart, async (item) => {


     return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'id')) == (getObjectProperty(item, 'id')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) == (getObjectProperty(item, 'selectedVariationId')) && (Object.keys((getObjectProperty(item, 'selectedModifiers')))).length == (Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedModifiers')))).length && (!(getObjectProperty(item, 'orderData.modifiers')).length || !(await asyncListFilter((getObjectProperty(item, 'orderData.modifiers')), async (modifier) => {


     return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(modifier, 'catalog_object_id')))));
    })).length)));
    }));
    if (!newCart.length) {
      userData['cart'] = [];
      delete userData['currentOrder'];
      localStorage.removeItem('cart');
      localStorage.removeItem('currentOrder');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu', undefined);
    } else {
      userData['cart'] = newCart;
      localStorage.setItem('cart', JSON.stringify(newCart));
      Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af'](newCart, (getObjectProperty(___arguments.context.pageData, 'tips')))));
    }
    ___arguments.context.pageData['loading'] = false;

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/c7854405dad8cca5aa1baea4e2daf151/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tips')) == ___arguments.context.dataModel ? ['checkout__tips_item', 'active'] : ['checkout__tips_item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'tips')) == ___arguments.context.dataModel) {
    delete ___arguments.context.pageData['tips'];
  } else {
    ___arguments.context.pageData['tips'] = ___arguments.context.dataModel;
  }
  Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af']((getObjectProperty(___arguments.context.appData, 'userData.cart')), (getObjectProperty(___arguments.context.pageData, 'tips')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/9c8a3a89690df3fe56f95415fbddfd4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/b6a6fc81879fc432cc3eb184a9feaa15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var price;



  return (String('$') + String(___arguments.context.getComponentDataStoreByUid('c7854405dad8cca5aa1baea4e2daf151')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/checkout-order/components/c93d938a14d4d63c474d2f51631cba06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-history', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/b96d66299d4ae04f1f544286649c88b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var balance;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  balance = (getObjectProperty(___arguments.context.appData, 'userData.balance'));
  if (balance && (getObjectProperty(___arguments.context.pageData, 'orderSum')) > (getObjectProperty(balance, 'wallet.balance.amount'))) {
    return ['checkout__payment_button', 'button', 'disabled'];
  } else {
    return ['checkout__payment_button', 'button'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var location2, error, userData, order, locationId, program, loyalty, user, usedBalance, payment, cart, bonusBalance, totalAmount, currentOrder;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && !(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b96d66299d4ae04f1f544286649c88b9')).includes('disabled'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    location2 = (getObjectProperty(userData, 'activeLocation'));
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a'](location2))) {
      user = (getObjectProperty(___arguments.context.appData, 'user'));
      cart = (getObjectProperty(userData, 'cart'));
      loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
      locationId = (getObjectProperty(location2, 'id'));
      currentOrder = (getObjectProperty(userData, 'currentOrder'));
      try {
        if (currentOrder) {
          order = (await BackendlessUI.Functions.Custom['fn_104fd91d13345e26662507e5fbebf5f4'](currentOrder, user));
        } else {
          order = (await BackendlessUI.Functions.Custom['fn_2d266328cce7f50d2595cc0eddd78a0d'](user, cart, locationId));
        }
        payment = (await BackendlessUI.Functions.Custom['fn_f91fce3b100aa72ab427a96851194a4c'](order, user, (getObjectProperty(___arguments.context.pageData, 'tips')), locationId, ((getObjectProperty(order, 'total_money.amount')) ? (getObjectProperty(user, 'walletId')) : 'CASH')));
        order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
        Object.assign(order, (await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.appData, 'imageList')))));
        await BackendlessUI.Functions.Custom['fn_251a91f37cf39b8aa532ba567eb18a1b'](order, cart);
        bonusBalance = (getObjectProperty(user, 'bonusBalance'));
        usedBalance = bonusBalance > totalAmount ? totalAmount : bonusBalance;
        user['bonusBalance'] = (bonusBalance - usedBalance);
        await BackendlessUI.Functions.Custom['fn_6f23a1f3b70c5566bd81aed8bb2297a4'](order, cart, payment, usedBalance, (getObjectProperty(location2, 'objectId')), 'Gift Card', 'Order');
        ;(async () => {
            await Backendless.UserService.update( new Backendless.User(user) );

        })();
        (getObjectProperty(userData, 'ordersList')).unshift(order);
        program = (getObjectProperty(loyalty, 'program'));
        if (program && (getObjectProperty(program, 'location_ids')) && ((getObjectProperty(program, 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.appData, order, ___arguments.context.pageData);
        }
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        Object.assign(userData, ({ 'previousOrder': order,'cart': [] }));
        delete userData['currentOrder'];
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('cart');
        ___arguments.context.pageData['loading'] = false;

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }
        Backendless.Logging.getLogger('SERVER_CODE').error((String('Balance Order Error: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f'](location2));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/b6030c3d84dcf24a11c3ddf1e8df902c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cart, discount, error, item, j, k, location2, locationId, loyalty, order, price, quantity, reward, rewardItem, rewardsList, sum, tax, taxAmount, taxes, updatedOrder, user, userData, userLoyalty;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && !(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b6030c3d84dcf24a11c3ddf1e8df902c')).includes('disabled'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    location2 = (getObjectProperty(userData, 'activeLocation'));
    locationId = (getObjectProperty(location2, 'id'));
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    cart = (getObjectProperty(userData, 'cart'));
    loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
    userLoyalty = (getObjectProperty(userData, 'loyalty'));
    try {
      if (getObjectProperty(userData, 'currentOrder')) {
        order = (getObjectProperty(userData, 'currentOrder'));
        if ((getObjectProperty(order, 'rewards')) && (await asyncListFilter((getObjectProperty(order, 'line_items')), async (item) => {


         return ((getObjectProperty(item, 'quantity')) != '0');
        })).length == (getObjectProperty(order, 'rewards')).length) {
          ___arguments.context.pageData['loading'] = false;
          return ;
        }
      } else {
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }) })))), 'order'));
      }
      reward = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-loyalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'loyalty_account_id': (getObjectProperty(user, 'loyaltyId')),'reward_tier_id': (getObjectProperty(((getObjectProperty(loyalty, 'program.reward_tiers'))[0]), 'id')),'order_id': (getObjectProperty(order, 'id')) })), 'reward'));
      updatedOrder = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
      rewardsList = (getObjectProperty(userLoyalty, 'rewardsList'));
      rewardsList.pop();
      userLoyalty['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
      userData['currentOrder'] = updatedOrder;
      taxes = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


       return ((getObjectProperty(item, 'present_at_location_ids')).includes(locationId));
      }));
      for (var j_index in cart) {
        j = cart[j_index];
        j['discount'] = 0;
      }
      var j_list2 = (getObjectProperty(updatedOrder, 'line_items'));
      for (var j_index3 in j_list2) {
        j = j_list2[j_index3];
        if (getObjectProperty(j, 'applied_discounts')) {
          for (var k_index in cart) {
            k = cart[k_index];
            if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


             return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
            })).length)) {
              rewardItem = k;
              break;
            }
          }
          rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
          quantity = (Number((getObjectProperty(rewardItem, 'quantity'))));
          discount = (getObjectProperty(rewardItem, 'discount'));
          price = (getObjectProperty(rewardItem, 'itemPrice'));
          sum = price * quantity - discount;
          rewardItem['sum'] = sum;
          rewardItem['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
          taxAmount = 0;
          var j_list = (getObjectProperty(rewardItem, 'item_data.tax_ids'));
          for (var j_index2 in j_list) {
            j = j_list[j_index2];
            if ((taxes.map(item => item['id'])).includes(j)) {
              tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'](taxes, j, 'id'));
              taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
            }
          }
          rewardItem['taxAmount'] = taxAmount;
        }
      }
      localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
      localStorage.setItem('cart', JSON.stringify(cart));
      Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af'](cart, (getObjectProperty(___arguments.context.pageData, 'tips')))));

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'userData')) && !(getObjectProperty(___arguments.context.appData, 'userData.loyalty.rewardsList')).length ? ['checkout__payment_button', 'button', 'disabled'] : ['checkout__payment_button', 'button'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/checkout-order/components/6ffa30fe1e68894d1fa13fd74891a978/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/201cc83dfd3e13e21e4d18c4b06edca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['stopPropagation'] = true;
    ___arguments.context.pageData['screen'] = 'location';
    ___arguments.context.pageData['locationView'] = 'list';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
    ___arguments.context.pageData['userPoint'] = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    while (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(___arguments.context.pageData, 'coordinates')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), ___arguments.context.pageData);
    while (!(getObjectProperty(___arguments.context.pageData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(___arguments.context.pageData, 'distanceList'));
    locationsList = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
    }
    ___arguments.context.pageData['stopPropagation'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/cbaf450f12f802318f7ae9da250df17b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/4c019201b3584aff819c4ac855c8ff3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cart, error, taxAmount, tax, sum, j, item, discount, quantity, price, rewardItem, userData, k, orderData, items, orderItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if (quantity < 99) {
    cart = (getObjectProperty(userData, 'cart'));
    ___arguments.context.pageData['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['quantity'] = (String(quantity + 1));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))['quantity'] = (String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.quantity')))) + 1));
    try {
      if (getObjectProperty(userData, 'currentOrder')) {
        items = (getObjectProperty(userData, 'currentOrder.line_items'));
        for (var j_index in items) {
          j = items[j_index];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            orderItem = j;
            break;
          }
        }
        if (orderItem) {
          orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) + 1));
        } else {
          orderData = (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))))));
          orderData['quantity'] = '1';
          addItemToList(items, orderData);
        }
        userData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(userData, 'currentOrder.location_id')),'line_items': (getObjectProperty(userData, 'currentOrder.line_items')),'version': (getObjectProperty(userData, 'currentOrder.version')),'id': (getObjectProperty(userData, 'currentOrder.id')) }) })), 'order'));
        localStorage.setItem('currentOrder', JSON.stringify((getObjectProperty(userData, 'currentOrder'))));
        for (var j_index2 in cart) {
          j = cart[j_index2];
          j['discount'] = 0;
        }
        var j_list2 = (getObjectProperty((getObjectProperty(userData, 'currentOrder')), 'line_items'));
        for (var j_index4 in j_list2) {
          j = j_list2[j_index4];
          for (var k_index in cart) {
            k = cart[k_index];
            if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


             return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
            })).length)) {
              rewardItem = k;
              break;
            }
          }
          if (getObjectProperty(j, 'applied_discounts')) {
            rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
          }
          quantity = (Number((getObjectProperty(rewardItem, 'quantity'))));
          discount = (getObjectProperty(rewardItem, 'discount'));
          price = (getObjectProperty(rewardItem, 'itemPrice'));
          sum = price * quantity - discount;
          rewardItem['sum'] = sum;
          rewardItem['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
          taxAmount = 0;
          var j_list = (getObjectProperty(rewardItem, 'item_data.tax_ids'));
          for (var j_index3 in j_list) {
            j = j_list[j_index3];
            if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


             return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
            })).map(item => item['id'])).includes(j)) {
              tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


               return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
              })), j, 'id'));
              taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
            }
          }
          rewardItem['taxAmount'] = taxAmount;
        }
      } else {
        quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
        discount = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount'));
        price = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'));
        sum = discount ? price * quantity - discount : price * quantity;
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['sum'] = sum;
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
        taxAmount = 0;
        var j_list3 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'item_data.tax_ids'));
        for (var j_index5 in j_list3) {
          j = j_list3[j_index5];
          if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


           return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
          })).map(item => item['id'])).includes(j)) {
            tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


             return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
            })), j, 'id'));
            taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
          }
        }
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['taxAmount'] = taxAmount;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af'](cart, (getObjectProperty(___arguments.context.pageData, 'tips')))));
    localStorage.setItem('cart', JSON.stringify(cart));
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/53fbcf73ab97355cd227388dcebce7aa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userData, error, taxAmount, tax, sum, j, item, discount, quantity, price, orderItem, items, rewardsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if (quantity > 1) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))['quantity'] = (String(quantity - 1));
    ___arguments.context.pageData['loading'] = true;
    try {
      if (getObjectProperty(userData, 'currentOrder')) {
        if (quantity == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'))) {
          await Backendless.Request.delete(`${Backendless.appPath}/services/Square/delete-royalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(((getObjectProperty((getObjectProperty(userData, 'currentOrder')), 'rewards')).slice(-1)[0]), 'id'))));
          rewardsList = (getObjectProperty(userData, 'loyalty.rewardsList'));
          addItemToList(rewardsList, '');
          (getObjectProperty(userData, 'loyalty'))['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
          ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['discount'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice')));
          userData['currentOrder'] = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(userData, 'currentOrder.id'))) }).send()), 'order'));
        }
        items = (getObjectProperty(userData, 'currentOrder.line_items'));
        for (var j_index in items) {
          j = items[j_index];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            orderItem = j;
            break;
          }
        }
        if (orderItem) {
          if ((getObjectProperty(orderItem, 'quantity')) == '1') {
            userData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(userData, 'currentOrder.location_id')),'version': (getObjectProperty(userData, 'currentOrder.version')),'id': (getObjectProperty(userData, 'currentOrder.id')) }),'fields_to_clear': [['line_items[',(getObjectProperty(orderItem, 'uid')),']'].join('')] })), 'order'));
            localStorage.setItem('currentOrder', JSON.stringify((getObjectProperty(userData, 'currentOrder'))));
          } else {
            orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) - 1));
            userData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(userData, 'currentOrder.location_id')),'line_items': (getObjectProperty(userData, 'currentOrder.line_items')),'version': (getObjectProperty(userData, 'currentOrder.version')),'id': (getObjectProperty(userData, 'currentOrder.id')) }) })), 'order'));
            localStorage.setItem('currentOrder', JSON.stringify((getObjectProperty(userData, 'currentOrder'))));
          }
        }
      }
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['quantity'] = (String(quantity - 1));
      quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
      discount = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount'));
      price = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'));
      sum = discount ? price * quantity - discount : price * quantity;
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['sum'] = sum;
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
      taxAmount = 0;
      var j_list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'item_data.tax_ids'));
      for (var j_index2 in j_list) {
        j = j_list[j_index2];
        if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


         return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
        })).map(item => item['id'])).includes(j)) {
          tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


           return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
          })), j, 'id'));
          taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
        }
      }
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['taxAmount'] = taxAmount;

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af']((getObjectProperty(userData, 'cart')), (getObjectProperty(___arguments.context.pageData, 'tips')))));
    localStorage.setItem('cart', JSON.stringify((getObjectProperty(___arguments.context.appData, 'cart'))));
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/60ca6af780f15cdd5d5441b5fb737c87/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var tips, tax, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'cart')) && !!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
    tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
    tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
    price = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d']((getObjectProperty(___arguments.context.pageData, 'cart')))) / 100)));
    ___arguments.context.pageData['totalSumStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))));
  } else {
    ___arguments.context.pageData['totalSumStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkout-order/components/0e93102dfa124a06aedc14d9fa929cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var tips, tax, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.newValue) {
    if ((getObjectProperty(___arguments.context.appData, 'cart')) && !!(getObjectProperty(___arguments.context.appData, 'cart')).length) {
      tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
      tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
      price = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d']((getObjectProperty(___arguments.context.appData, 'cart')))) / 100)));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))));
      return ;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
      return ;
    }
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = '$0.00';
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/checkout-order/components/0af68e451c13fe3450285bb9fd0a7281/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.cart'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/checkout-order/components/9e07541842d81ed3c9491be2284d3a63/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    if ((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('CAN_USE_APPLE_PAY', undefined))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('57f6e3377f6421e1900d4a85050e9f3e', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkout-order/components/57f6e3377f6421e1900d4a85050e9f3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var location2, error, userData, order, locationId, program, loyalty, payment, cart, nonce, tips, user, currentOrder;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.appData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    location2 = (getObjectProperty(userData, 'activeLocation'));
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a'](location2))) {
      try {
        user = (getObjectProperty(___arguments.context.appData, 'user'));
        cart = (getObjectProperty(userData, 'cart'));
        locationId = (getObjectProperty(location2, 'id'));
        loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
        currentOrder = (getObjectProperty(userData, 'currentOrder'));
        if (currentOrder) {
          order = (await BackendlessUI.Functions.Custom['fn_104fd91d13345e26662507e5fbebf5f4'](currentOrder, user));
        } else {
          order = (await BackendlessUI.Functions.Custom['fn_2d266328cce7f50d2595cc0eddd78a0d'](user, cart, locationId));
        }
        tips = (getObjectProperty(___arguments.context.pageData, 'tips')) * 100;
        nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_APPLE_PAY', ({ 'totalAmount': tips ? (getObjectProperty(order, 'total_money.amount')) + tips : (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
        if (nonce) {
          payment = (await BackendlessUI.Functions.Custom['fn_f91fce3b100aa72ab427a96851194a4c'](order, user, tips, locationId, nonce));
          Object.assign(order, (await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.appData, 'imageList')))));
          await BackendlessUI.Functions.Custom['fn_251a91f37cf39b8aa532ba567eb18a1b'](order, cart);
          await BackendlessUI.Functions.Custom['fn_6f23a1f3b70c5566bd81aed8bb2297a4'](order, cart, payment, 0, (getObjectProperty(location2, 'objectId')), 'Apple Pay', 'Order');
          (getObjectProperty(userData, 'ordersList')).unshift(order);
          program = (getObjectProperty(loyalty, 'program'));
          if (program && (getObjectProperty(program, 'location_ids')) && ((getObjectProperty(program, 'location_ids')).includes(locationId))) {
            await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.appData, order, ___arguments.context.pageData);
          }
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
          Object.assign(userData, ({ 'previousOrder': order,'cart': [] }));
          delete userData['currentOrder'];
          localStorage.removeItem('currentOrder');
          localStorage.removeItem('cart');
        }
        ___arguments.context.pageData['loading'] = false;

      } catch (error) {
        if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
          await (async function() {
          	alert('Card declined')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
          await (async function() {
          	alert('Card declined due to insufficient funds')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
          await (async function() {
          	alert('Card declined due to transaction limit')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
          await (async function() {
          	alert('Card expired')
          })();
          await saveOrder();
        }
        if (error.indexOf('_CANCELED BY USER') !== -1) {
          ___arguments.context.pageData['loading'] = false;
        }
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }
        Backendless.Logging.getLogger('SERVER_CODE').error((String('Apple Pay Order Error: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f'](location2));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
      ___arguments.context.pageData['loading'] = false;
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/1c1b0d160fc163e2e004562d4d64d999/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var location2, error, userData, order, locationId, program, loyalty, payment, cart, nonce, tips, user, currentOrder;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.appData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    location2 = (getObjectProperty(userData, 'activeLocation'));
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a'](location2))) {
      try {
        user = (getObjectProperty(___arguments.context.appData, 'user'));
        cart = (getObjectProperty(userData, 'cart'));
        locationId = (getObjectProperty(location2, 'id'));
        loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
        currentOrder = (getObjectProperty(userData, 'currentOrder'));
        if (currentOrder) {
          order = (await BackendlessUI.Functions.Custom['fn_104fd91d13345e26662507e5fbebf5f4'](currentOrder, user));
        } else {
          order = (await BackendlessUI.Functions.Custom['fn_2d266328cce7f50d2595cc0eddd78a0d'](user, cart, locationId));
        }
        tips = (getObjectProperty(___arguments.context.pageData, 'tips')) * 100;
        nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_GOOGLE_PAY', ({ 'totalAmount': tips ? (getObjectProperty(order, 'total_money.amount')) + tips : (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
        if (nonce) {
          payment = (await BackendlessUI.Functions.Custom['fn_f91fce3b100aa72ab427a96851194a4c'](order, user, tips, locationId, nonce));
          Object.assign(order, (await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.appData, 'imageList')))));
          await BackendlessUI.Functions.Custom['fn_251a91f37cf39b8aa532ba567eb18a1b'](order, cart);
          await BackendlessUI.Functions.Custom['fn_6f23a1f3b70c5566bd81aed8bb2297a4'](order, cart, payment, 0, (getObjectProperty(location2, 'objectId')), 'Google Pay', 'Order');
          (getObjectProperty(userData, 'ordersList')).unshift(order);
          program = (getObjectProperty(loyalty, 'program'));
          if (program && (getObjectProperty(program, 'location_ids')) && ((getObjectProperty(program, 'location_ids')).includes(locationId))) {
            await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.appData, order, ___arguments.context.pageData);
          }
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
          Object.assign(userData, ({ 'previousOrder': order,'cart': [] }));
          delete userData['currentOrder'];
          localStorage.removeItem('currentOrder');
          localStorage.removeItem('cart');
        }

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
          await (async function() {
          	alert('Card declined')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
          await (async function() {
          	alert('Card declined due to insufficient funds')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
          await (async function() {
          	alert('Card declined due to transaction limit')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
          await (async function() {
          	alert('Card expired')
          })();
          await saveOrder();
        }
        if (error.indexOf('_CANCELED BY USER') !== -1) {
          ___arguments.context.pageData['loading'] = false;
        }
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }
        Backendless.Logging.getLogger('SERVER_CODE').error((String('Google Pay Order Error:') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f'](location2));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/d6f5763c21c2f8fabddcb54bb43f65c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/6516b3a80a8be43568fd1dabd054b24f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'totalSum')) ? 'pay with balance' : 'checkout')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/checkout-order/components/7404660a242b1b1d72d1ebf103c98109/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'userData.balance')));

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-wallet', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/ce1545383274a3419f692ca59f3a5de5/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var address, location2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  location2 = (getObjectProperty(___arguments.context.appData, 'userData.activeLocation'));
  if (location2) {
    address = (getObjectProperty(location2, 'address'));
    Object.assign(___arguments.context.dataModel, ({ 'name': (getObjectProperty(location2, 'name')),'addressStr': [(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),' ',(getObjectProperty(address, 'administrative_district_level_1')),' ',(getObjectProperty(address, 'postal_code'))].join('') }));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkout-order/components/9125044b2f7e0b91c38e6217df2775a2/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var cart, taxAmount, k, tax, sum, j, userData, item, discount, quantity, price, selectedVariation, modifiersSum, modifierPrices, selectedModifiers, modifiersNamesStr, modifiersNames, modifier, modifierList, variations, rewardItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if (userData && (getObjectProperty(userData, 'cart'))) {
    cart = (getObjectProperty(userData, 'cart'));
    if (getObjectProperty(userData, 'currentOrder')) {
      for (var j_index in cart) {
        j = cart[j_index];
        j['discount'] = 0;
      }
      var j_list = (getObjectProperty((getObjectProperty(userData, 'currentOrder')), 'line_items'));
      for (var j_index2 in j_list) {
        j = j_list[j_index2];
        if (getObjectProperty(j, 'applied_discounts')) {
          for (var k_index in cart) {
            k = cart[k_index];
            if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


             return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
            })).length)) {
              rewardItem = k;
              break;
            }
          }
          rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
        }
      }
    }
    for (var k_index2 in cart) {
      k = cart[k_index2];
      modifierList = (getObjectProperty(k, 'modifiers'));
      variations = (getObjectProperty(k, 'variations'));
      selectedVariation = variations[(((variations.map(item => item['id'])).indexOf(getObjectProperty(k, 'selectedVariationId')) + 1) - 1)];
      if (modifierList) {
        modifier = [];
        var j_list2 = (Object.keys((getObjectProperty(k, 'selectedModifiers'))));
        for (var j_index3 in j_list2) {
          j = j_list2[j_index3];
          modifier = (modifier.concat((getObjectProperty((getObjectProperty((modifierList[(((modifierList.map(item => item['id'])).indexOf(j) + 1) - 1)]), 'modifier_list_data')), 'modifiers'))));
        }
        selectedModifiers = (await asyncListFilter(modifier, async (item) => {


         return (((getObjectProperty((getObjectProperty(k, 'orderData')), 'modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'id'))));
        }));
        modifiersNames = ((selectedModifiers.map(item => item['modifier_data'])).map(item => item['name']));
      } else {
        modifiersNames = [];
      }
      modifiersNamesStr = !modifiersNames.length ? (getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'name')) : [(getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'name')),', ',modifiersNames.join(', ')].join('');
      k['modifiersStr'] = modifiersNamesStr;
      k['itemPrice'] = 0;
      if (selectedModifiers) {
        modifierPrices = (await asyncListFilter(((selectedModifiers.map(item => item['modifier_data'])).map(item => item['price_money'])), async (item) => {


         return item;
        }));
      } else {
        modifierPrices = [];
      }
      modifiersSum = !modifierPrices.length ? 0 : (modifierPrices.map(item => item['amount'])).reduce(function(x, y) {return x + y;});
      price = modifiersSum + (getObjectProperty((getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'price_money')), 'amount'));
      k['itemPrice'] = price;
      quantity = (Number((getObjectProperty(k, 'quantity'))));
      price = (getObjectProperty(k, 'itemPrice'));
      discount = (getObjectProperty(k, 'discount'));
      sum = discount ? price * quantity - discount : price * quantity;
      k['sum'] = sum;
      k['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
      taxAmount = 0;
      var j_list3 = (getObjectProperty(k, 'item_data.tax_ids'));
      for (var j_index4 in j_list3) {
        j = j_list3[j_index4];
        if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


         return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
        })).map(item => item['id'])).includes(j)) {
          tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'taxList')), async (item) => {


           return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(userData, 'activeLocation.id'))));
          })), j, 'id'));
          taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
        }
      }
      k['taxAmount'] = taxAmount;
    }
    Object.assign(___arguments.context.pageData, (await BackendlessUI.Functions.Custom['fn_458134ca0fffb49743ef9f5c3adc95af'](cart, (getObjectProperty(___arguments.context.pageData, 'tips')))));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkout-order/components/1fd7f75a9acccc587a7e83469b4f0b0d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-order/components/a28551465cf5c5b3e4585b5363185b4c/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.cardsList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/checkout-qr/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['user'] = (getObjectProperty(___arguments.context.appData, 'user'));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/checkout-qr/components/c93d938a14d4d63c474d2f51631cba06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['screen'] = 'settings';
  ___arguments.context.pageData['tab'] = 'history';
  ___arguments.context.pageData['historyObjectId'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-qr/components/6ffa30fe1e68894d1fa13fd74891a978/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-qr/components/590d10da4903722d396964f77fc92dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-wallet', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-qr/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkout-qr/components/7404660a242b1b1d72d1ebf103c98109/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.balance'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, locationsList, j, location2, newLocations, list, item, locationTypes, address, locationChangeTimestamp, locationChangeCache, timestamps, imagesList, appConfig, category, categoryItem, squareObjects, data, cardsList, userData, user, userLocation, birthday, monthList, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getDeviceSubscriptions() { return getSubscriptionsManager('deviceSubscriptions');}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(imagesList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (!(getObjectProperty(___arguments.context.appData, 'user'))) {
    ___arguments.context.appData['user'] = (await Backendless.UserService.getCurrentUser(false));
  }
  ___arguments.context.pageData['user'] = (getObjectProperty(___arguments.context.appData, 'user'));
  if (!(getObjectProperty(___arguments.context.appData, 'appLoaded'))) {
    try {
      userData = (getObjectProperty(___arguments.context.appData, 'userData'));
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      if (user) {
        if (!(getObjectProperty(user, 'email')) || !(getObjectProperty(user, 'name'))) {
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization-signup', undefined);
        } else {
          try {
            await (async function(pageData) {
            	window.onpopstate = function(event) {
            	  Object.assign(pageData, event.state)
            	}
            })(___arguments.context.pageData);
            await (async function() {
            	const map = BackendlessUI.requireModule( "https://maps.googleapis.com/maps/api/js?key=AIzaSyAMbIvlFqSOAtaGsFm1ogYluZj5KoX7frE&region=US&language=en&callback=initMap" );

            	window.initMap = function() {}
            })();
            if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
              getDeviceSubscriptions().remove('resumedAppRequest');

              await (async function() {
                const callback = async (data) => {

                };

                const eventName = 'onAppResumed'

                await BackendlessUI.DeviceAPI.addEventListener(eventName, callback)

                getDeviceSubscriptions().add('resumedAppRequest', () => BackendlessUI.DeviceAPI.removeEventListener(eventName, callback));
              })();
            } else {
              await (async function() {
              	const square = BackendlessUI.requireModule( "https://web.squarecdn.com/v1/square.js" );
              })();
            }
            timestamps = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
            ___arguments.context.appData['menuTimestamps'] = (await asyncListFilter(timestamps, async (item) => {


             return ((getObjectProperty(item, 'type')) == 'menu');
            }));
            ___arguments.context.appData['appContent'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppContent`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
            appConfig = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppConfig`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
            ___arguments.context.appData['appConfig'] = appConfig;
            ;(async () => {
                imagesList = [];
              await getItemImages(0);
              ___arguments.context.appData['imageList'] = imagesList;

            })();
            if (!(getObjectProperty(user, 'customerId'))) {
              user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
            }
            monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            birthday = (getObjectProperty(user, 'dateOfBirth'));
            user['birthdayStr'] = ([monthList[((new Date(birthday).getMonth() + 1) - 1)],' ',(new Date(birthday).getDate()),', ',(new Date(birthday).getFullYear())].join(''));
            locationChangeTimestamp = (getObjectProperty((await (async function(list) {
            	return list.find(item => item.type === 'location')
            })(timestamps)), 'changeDate'));
            locationChangeCache = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('locationChange'));
            if (locationChangeCache == locationChangeTimestamp) {
              locationsList = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('locationsList'));
            } else {
              localStorage.setItem('locationChange', JSON.stringify(locationChangeTimestamp));
              locationsList = (await asyncListFilter((getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations')), async (item) => {


               return ((getObjectProperty(item, 'business_hours')) && (getObjectProperty(item, 'status')) == 'ACTIVE');
              }));
              for (var j_index in locationsList) {
                j = locationsList[j_index];
                address = (getObjectProperty(j, 'address'));
                j['addressStr'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
              }
              locationTypes = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getLocations`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
              for (var j_index2 in locationTypes) {
                j = locationTypes[j_index2];
                location2 = locationsList[(((locationsList.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
                if (location2) {
                  location2['visitType'] = (getObjectProperty(j, 'visitType'));
                  location2['isMain'] = (getObjectProperty(j, 'isMain'));
                  location2['hidden'] = (getObjectProperty(j, 'hidden'));
                  location2['objectId'] = (getObjectProperty(j, 'objectId'));
                  location2['menuId'] = (getObjectProperty(j, 'menuId'));
                }
              }
              localStorage.setItem('locationsList', JSON.stringify(locationsList));
            }
            ___arguments.context.appData['filteredLocationList'] = (await asyncListFilter(locationsList, async (item) => {


             return (!(getObjectProperty(item, 'hidden')));
            }));
            ___arguments.context.appData['locationsList'] = (await asyncListFilter(locationsList, async (item) => {


             return (!(getObjectProperty(item, 'hidden')));
            }));
            ___arguments.context.appData['mainLocation'] = (await (async function(list) {
            	return list.find(item => item.isMain)
            })(locationsList));
            ___arguments.context.appData['mainLocationId'] = (getObjectProperty(___arguments.context.appData, 'mainLocation.id'));
            ;(async () => {
                getRTSubscriptions().remove('ListenMainLocationChange');

              ;(function() {
                const callback = async list => {
                    newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/getUpsertedLocs`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(list)));
                var j_list = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
                for (var j_index3 in j_list) {
                  j = j_list[j_index3];
                  location2 = (getObjectProperty(___arguments.context.pageData, 'locationsList'))[(((newLocations.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
                  if (location2) {
                    location2['isMain'] = (getObjectProperty(j, 'isMain'));
                  }
                }
                ___arguments.context.appData['mainLocation'] = (await (async function(list) {
                	return list.find(item => item.isMain)
                })((getObjectProperty(___arguments.context.pageData, 'locationsList'))));
                ___arguments.context.appData['mainLocationId'] = (getObjectProperty(___arguments.context.pageData, 'mainLocation.id'));

                };

                const rtHandlers = Backendless.Data.of('Location').rt();
                const options = [callback];

                rtHandlers.addBulkUpsertListener.apply(rtHandlers, options);

                getRTSubscriptions().add('ListenMainLocationChange', () => rtHandlers.removeUpsertListener(callback));

              })();

            })();
            userLocation = (getObjectProperty(user, 'location'));
            if (userLocation) {
              userData['activeLocation'] = (locationsList[(((locationsList.map(item => item['id'])).indexOf(userLocation) + 1) - 1)]);
              await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, (getObjectProperty(userData, 'activeLocation')), ___arguments.context.appData);
            }
            ;(async () => {
                cardsList = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-cards-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'customer_id': JSON.stringify((getObjectProperty(user, 'customerId'))) }).send());
              userData['cardsList'] = ((getObjectProperty(cardsList, 'cards')) ? (getObjectProperty(cardsList, 'cards')) : []);

            })();
            ;(async () => {
                if (!(getObjectProperty(___arguments.context.appData, 'modifiersList'))) {
                ___arguments.context.appData['modifiersList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('modifier_list') }).send());
              }

            })();
            await new Promise(r => setTimeout(r, 1000 || 0));
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
            await (async function(locationList) {
            	let geocoder = new google.maps.Geocoder();
            	
            	locationList.forEach(location => {
            	  if(!location.coordinates) {
            	    geocoder.geocode( { 'address': location.addressStr}, function(results, status) {
            	      if (status == 'OK') {
            	        location.coordinates = {}
            	        location.coordinates.latitude = results[0].geometry.location.lat()
            	        location.coordinates.longitude = results[0].geometry.location.lng()
            	      } else {
            	        console.log('Geocode was not successful for the following reason: ' + status);
            	      }
            	    })
            	  }
            	})
            })(locationsList);
            if (!(getObjectProperty(___arguments.context.appData, 'taxList'))) {
              ___arguments.context.appData['taxList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('tax') }).send());
            }

          } catch (error) {
            console.log(error);
            if ((getObjectProperty(error, 'message')) == 'Network Error') {
              ___arguments.context.pageData['loading'] = false;
              ___arguments.context.pageData['networkError'] = true;
            }
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);

          }
        }
        ___arguments.context.appData['appLoaded'] = true;
      } else {
        await (async function(pageData) {
        	window.onpopstate = function(event) {
        	  Object.assign(pageData, event.state)
        	}
        })(___arguments.context.pageData);
        await (async function() {
        	const map = BackendlessUI.requireModule( "https://maps.googleapis.com/maps/api/js?key=AIzaSyAMbIvlFqSOAtaGsFm1ogYluZj5KoX7frE&region=US&language=en&callback=initMap" );

        	window.initMap = function() {}
        })();
        if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
          getDeviceSubscriptions().remove('resumedAppRequest');

          await (async function() {
            const callback = async (data) => {

            };

            const eventName = 'onAppResumed'

            await BackendlessUI.DeviceAPI.addEventListener(eventName, callback)

            getDeviceSubscriptions().add('resumedAppRequest', () => BackendlessUI.DeviceAPI.removeEventListener(eventName, callback));
          })();
        } else {
          await (async function() {
          	const square = BackendlessUI.requireModule( "https://web.squarecdn.com/v1/square.js" );
          })();
        }
        timestamps = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
        ___arguments.context.appData['menuTimestamps'] = (await asyncListFilter(timestamps, async (item) => {


         return ((getObjectProperty(item, 'type')) == 'menu');
        }));
        ___arguments.context.appData['appContent'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppContent`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
        appConfig = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppConfig`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
        ___arguments.context.appData['appConfig'] = appConfig;
        ;(async () => {
            imagesList = [];
          await getItemImages(0);
          ___arguments.context.appData['imageList'] = imagesList;

        })();
        locationChangeTimestamp = (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })(timestamps)), 'changeDate'));
        locationChangeCache = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('locationChange'));
        if (locationChangeCache == locationChangeTimestamp) {
          locationsList = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('locationsList'));
        } else {
          localStorage.setItem('locationChange', JSON.stringify(locationChangeTimestamp));
          locationsList = (await asyncListFilter((getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations')), async (item) => {


           return ((getObjectProperty(item, 'business_hours')) && (getObjectProperty(item, 'status')) == 'ACTIVE');
          }));
          for (var j_index4 in locationsList) {
            j = locationsList[j_index4];
            address = (getObjectProperty(j, 'address'));
            j['addressStr'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
          }
          locationTypes = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getLocations`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
          for (var j_index5 in locationTypes) {
            j = locationTypes[j_index5];
            location2 = locationsList[(((locationsList.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
            if (location2) {
              location2['visitType'] = (getObjectProperty(j, 'visitType'));
              location2['isMain'] = (getObjectProperty(j, 'isMain'));
              location2['hidden'] = (getObjectProperty(j, 'hidden'));
              location2['objectId'] = (getObjectProperty(j, 'objectId'));
              location2['menuId'] = (getObjectProperty(j, 'menuId'));
            }
          }
          localStorage.setItem('locationsList', JSON.stringify(locationsList));
        }
        ___arguments.context.appData['filteredLocationList'] = (await asyncListFilter(locationsList, async (item) => {


         return (!(getObjectProperty(item, 'hidden')));
        }));
        ___arguments.context.appData['locationsList'] = (await asyncListFilter(locationsList, async (item) => {


         return (!(getObjectProperty(item, 'hidden')));
        }));
        ___arguments.context.appData['mainLocation'] = (await (async function(list) {
        	return list.find(item => item.isMain)
        })(locationsList));
        ___arguments.context.appData['mainLocationId'] = (getObjectProperty(___arguments.context.appData, 'mainLocation.id'));
        ;(async () => {
            getRTSubscriptions().remove('ListenMainLocationChange');

          ;(function() {
            const callback = async list => {
                newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/getUpsertedLocs`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(list)));
            var j_list2 = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
            for (var j_index6 in j_list2) {
              j = j_list2[j_index6];
              location2 = (getObjectProperty(___arguments.context.pageData, 'locationsList'))[(((newLocations.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
              if (location2) {
                location2['isMain'] = (getObjectProperty(j, 'isMain'));
              }
            }
            ___arguments.context.appData['mainLocation'] = (await (async function(list) {
            	return list.find(item => item.isMain)
            })((getObjectProperty(___arguments.context.pageData, 'locationsList'))));
            ___arguments.context.appData['mainLocationId'] = (getObjectProperty(___arguments.context.pageData, 'mainLocation.id'));

            };

            const rtHandlers = Backendless.Data.of('Location').rt();
            const options = [callback];

            rtHandlers.addBulkUpsertListener.apply(rtHandlers, options);

            getRTSubscriptions().add('ListenMainLocationChange', () => rtHandlers.removeUpsertListener(callback));

          })();

        })();
        ;(async () => {
            if (!(getObjectProperty(___arguments.context.appData, 'modifiersList'))) {
            ___arguments.context.appData['modifiersList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('modifier_list') }).send());
          }

        })();
        await new Promise(r => setTimeout(r, 1000 || 0));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
        await (async function(locationList) {
        	let geocoder = new google.maps.Geocoder();
        	
        	locationList.forEach(location => {
        	  if(!location.coordinates) {
        	    geocoder.geocode( { 'address': location.addressStr}, function(results, status) {
        	      if (status == 'OK') {
        	        location.coordinates = {}
        	        location.coordinates.latitude = results[0].geometry.location.lat()
        	        location.coordinates.longitude = results[0].geometry.location.lng()
        	      } else {
        	        console.log('Geocode was not successful for the following reason: ' + status);
        	      }
        	    })
        	  }
        	})
        })(locationsList);
      }
      ___arguments.context.appData['appLoaded'] = true;

    } catch (error) {
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
      }

    }
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var data, order, cart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.appData, 'userData'))) {
    data = ({  });
    cart = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('cart'));
    data['cart'] = (cart ? cart : []);
    order = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentOrder'));
    if (order) {
      data['currentOrder'] = order;
    }
    ___arguments.context.appData['userData'] = data;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/home/components/17b42e8753514bf1b64d29db869723fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var favoriteList, favItemId, userFavorite, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  favoriteList = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'favoriteList'));
  userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite'));
  favItemId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), 'objectId'));
  ;(async () => {
      try {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(favItemId));

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }

  })();
  removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
  removeItemInList(favoriteList, ___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), 'objectId');
  if (getObjectProperty(___arguments.context.appData, 'userData.previousOrder')) {
    console.log(getObjectProperty(___arguments.context.appData, 'userData.previousOrder.cart'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c5d8545ab0cce6a30ffe07b661cced6e'))['refresh'] = true;
  delete ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c5d8545ab0cce6a30ffe07b661cced6e'))['refresh'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/ead4338b07f96f7bd9aa8ea508213107/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
    ___arguments.context.dataModel['showVariation'] = true;
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favVariation')) {
    ___arguments.context.dataModel['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'favVariation'));
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
    ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
  }
  Object.assign(___arguments.context.appData, ({ 'activeItem': (JSON.parse((JSON.stringify(___arguments.context.dataModel)))),'catalog': ({ 'category': 'favorites' }) }));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-item', undefined);

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
    ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
  }
  while (!(getObjectProperty(___arguments.context.appData, 'imageList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'imageList')));
  }
  ___arguments.context.dataModel['userFav'] = true;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/c779f1820bc7edb9afe712575d9b791f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newFav, userFavorite, favoriteList, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'savingFav'))) {
    ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['savingFav'] = true;
    favoriteList = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId')))) {
      ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['userFav'] = false;
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId'))));
      ;(async () => {
          try {
          await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(favItemId));

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['userFav'] = true;
        newFav = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/createFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'item': ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId')) }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'selectedVariationId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'selectedModifiers')) }))));
        newFav['favId'] = (getObjectProperty(newFav, 'item.objectId'));
        newFav['item_data'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'item_data'));
        newFav['id'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'id'));
        newFav['favVariation'] = (getObjectProperty(newFav, 'variation'));
        newFav['favModifier'] = (getObjectProperty(newFav, 'modifier'));
        newFav['variation'] = (getObjectProperty(newFav, 'item.variation'));
        newFav['modifier'] = (getObjectProperty(newFav, 'item.modifier'));
        newFav['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'name'));
        newFav['image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'image'));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      }
    }
    await new Promise(r => setTimeout(r, 300 || 0));
    ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  user = (getObjectProperty(___arguments.context.appData, 'user'));

  return ((getObjectProperty(user, 'favorite')) && (((getObjectProperty(user, 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId')))) ? 'favorite' : 'favorite border')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/home/components/505df5fafbaff2d24456d8c146b5c645/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
    ___arguments.context.dataModel['showVariation'] = true;
  }
  Object.assign(___arguments.context.appData, ({ 'activeItem': (JSON.parse((JSON.stringify(___arguments.context.dataModel)))),'catalog': ({ 'category': 'previous' }) }));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-item', undefined);

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  while (!(getObjectProperty(___arguments.context.appData, 'imageList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'imageList')));
  }
  delete ___arguments.context.dataModel['discount'];
  delete ___arguments.context.dataModel['itemPrice'];
  delete ___arguments.context.dataModel['sum'];
  delete ___arguments.context.dataModel['taxAmount'];
  delete ___arguments.context.dataModel['totalPrice'];
  delete ___arguments.context.dataModel['quantity'];
  while (!(getObjectProperty(___arguments.context.appData, 'user'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  user = (getObjectProperty(___arguments.context.appData, 'user'));
  if ((getObjectProperty(user, 'favorite')) && (((getObjectProperty(user, 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))) {
    ___arguments.context.dataModel['userFav'] = true;
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/c13605b3de5da77275d391f6fddecbbd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.appData, 'user'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-rewards', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/8fd95508501ca7f7e396a8fc90191a76/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')).length > 1) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['showVariation'] = true;
  }
  Object.assign(___arguments.context.appData, ({ 'activeItem': (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'))))),'catalog': ({ 'category': 'today\'s offers' }) }));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-item', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'useImage')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.name'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/82a80af8c13c4bf8a4b20435829af919/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'))))), ___arguments.context.pageData, ___arguments.context.appData);
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeLocation')) && (getObjectProperty(___arguments.context.pageData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/6e26cbf82c87bac88455c939e4c644d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d'](___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), ___arguments.context.pageData, ___arguments.context.appData);
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-order', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.activeLocation'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/670e2565ecdf44a604b71bd6858371f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d'](___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), ___arguments.context.pageData, ___arguments.context.appData);
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-order', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/d75b8edd820966e4803678fd352c9723/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.appData, 'user'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-rewards', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/2f8c054766b86519c17320f8ff7843d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.appData, 'user'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-rewards', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/220a9b5c910bc52bfe5b3187a051ac9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  console.log(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'));
  ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.name'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')).length > 1) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['showVariation'] = true;
  }
  Object.assign(___arguments.context.appData, ({ 'activeItem': (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'))))),'catalog': ({ 'category': 'today\'s offers' }) }));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-item', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/9807faa636cfa5f3d49f1b7b4e62ca33/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-wallet', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/3c0642efcde4780ae662e5e596d6bf0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/731dc6fd7985926731beb47a132e8189/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/7a1182e8868bcad50d1189a80a499993/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'todaysOffers')) && !!(getObjectProperty(___arguments.context.pageData, 'todaysOffers')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/4416128cf3cb1e114fe7637fb5f75851/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/3abe0817cf7a200c8d75729e9d0735f5/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var account, balance, error, loyalty, program, remainder, rewardPointsNeeded, rewardsList, user, userData, userLoyalty;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await Backendless.UserService.isValidLogin()) {
    try {
      while (!(getObjectProperty(___arguments.context.appData, 'user'))) {
        await new Promise(r => setTimeout(r, 100 || 0));
      }
      user = (getObjectProperty(___arguments.context.appData, 'user'));
      userData = (getObjectProperty(___arguments.context.appData, 'userData'));
      if ((getObjectProperty(user, 'email')) && (getObjectProperty(user, 'name'))) {
        if (!(getObjectProperty(___arguments.context.appData, 'loyalty'))) {
          ___arguments.context.appData['loyalty'] = ({  });
          userData['loyalty'] = ({  });
        }
        loyalty = (getObjectProperty(___arguments.context.appData, 'loyalty'));
        userLoyalty = (getObjectProperty(userData, 'loyalty'));
        if (!(getObjectProperty(userLoyalty, 'account'))) {
          account = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/getLoyaltyAccount`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'account_id': JSON.stringify((getObjectProperty(user, 'loyaltyId'))) }).send()), 'loyalty_account'));
          userLoyalty['account'] = account;
        } else {
          account = (getObjectProperty(userLoyalty, 'account'));
        }
        if (!(getObjectProperty(loyalty, 'program'))) {
          program = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-loyalty-program`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'program'));
          loyalty['program'] = program;
        } else {
          program = (getObjectProperty(loyalty, 'program'));
        }
        rewardPointsNeeded = (getObjectProperty(((getObjectProperty(program, 'reward_tiers'))[0]), 'points'));
        loyalty['rewardPointsNeeded'] = rewardPointsNeeded;
        loyalty['rewardCategories'] = (getObjectProperty(((getObjectProperty(program, 'reward_tiers'))[0]), 'definition.catalog_object_ids'));
        balance = (getObjectProperty(account, 'balance'));
        remainder = balance % rewardPointsNeeded;
        rewardsList = [];
        userLoyalty['loyaltyStr'] = (String(rewardPointsNeeded - remainder) + String(' drinks'));
        userLoyalty['loyaltyImgUrl'] = (['./styles/assets/img/reward/gem',remainder,'.svg'].join(''));
        var repeat_end = Math.floor(balance / rewardPointsNeeded);
        for (var count = 0; count < repeat_end; count++) {
          addItemToList(rewardsList, '');
        }
        userLoyalty['rewardsList'] = rewardsList;
        userLoyalty['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
        Object.assign(___arguments.context.dataModel, userLoyalty);
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
  } else {
    ___arguments.context.dataModel['loyaltyImgUrl'] = (['./styles/assets/img/reward/gem',0,'.svg'].join(''));
  }

  },
  /* handler:onMounted */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'user.loyalty'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/home/components/e0266148b74b39619cc89e14653e3ffb/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var error, item, offer, todaysOffer, offerCache, dateEnd, date, dateStart, list, object, newOffersList, newOffers, newIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

/**
 * Describe this function...
 */
async function listenTodaysOffer() {
  ;(async () => {
      getRTSubscriptions().remove('addTodaysOffer');

    ;(function() {
      const callback = async list => {
          await addOffers(list);
      await RTdeleteTodaysOffer();

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();
      const options = [callback];

      rtHandlers.addBulkUpsertListener.apply(rtHandlers, options);

      getRTSubscriptions().add('addTodaysOffer', () => rtHandlers.removeUpsertListener(callback));

    })();
      getRTSubscriptions().remove('addPeriodTodaysOffer');

    ;(function() {
      const callback = async list => {
          await addOffers(list);
      await RTdeleteTodaysOffer();

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();
      const options = [callback];

      rtHandlers.addBulkCreateListener.apply(rtHandlers, options);

      getRTSubscriptions().add('addPeriodTodaysOffer', () => rtHandlers.removeBulkCreateListener(callback));

    })();

  })();
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function RTdeleteTodaysOffer() {
  ;(async () => {
      getRTSubscriptions().remove('deleteTodaysOffer');

    ;(function(whereClause) {
      const callback = async object => {
          ___arguments.context.dataModel['todaysOffers'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'todaysOffers')), async (item) => {


       return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(object, 'objectId')));
      }));
      localStorage.setItem('offers', JSON.stringify(({ 'changeDate': ((new Date()).valueOf()),'offers': (getObjectProperty(___arguments.context.dataModel, 'todaysOffers')) })));

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();

      const options = [callback];

      if (whereClause) {
         options.unshift(whereClause);
      }

      rtHandlers.addDeleteListener.apply(rtHandlers, options);

      getRTSubscriptions().add('deleteTodaysOffer', () => rtHandlers.removeDeleteListener(callback));

    })((['objectId IN (\'',((getObjectProperty(___arguments.context.dataModel, 'todaysOffers')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));

  })();
}

/**
 * Describe this function...
 */
async function addOffers(newIds) {
  try {
    newOffers = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['offerDate >= \'',(date.valueOf()),'\' and offerDate < \'',(dateEnd.valueOf()),'\' and objectId in (\'',newIds.join('\',\''),'\')'].join(''))).setPageSize(100)));
    if (!!newOffers.length) {
      newOffersList = (await Promise.all((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((newOffers.map(item => item['squareId']))))), 'objects')).map(async item => {  offer = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(newOffers, (getObjectProperty(item, 'id'))));
        item['objectId'] = (getObjectProperty(offer, 'objectId'));
        item['altImage'] = (getObjectProperty(offer, 'image'));
        item['useImage'] = (getObjectProperty(offer, 'useImage'));
        await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](item, (getObjectProperty(___arguments.context.pageData, 'imageList')));
      ; return item;})));
      ___arguments.context.dataModel['todaysOffers'] = newOffersList;
    }
    localStorage.setItem('offers', JSON.stringify(({ 'changeDate': ((new Date()).valueOf()),'offers': (getObjectProperty(___arguments.context.dataModel, 'todaysOffers')) })));

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }
}


  while (!(getObjectProperty(___arguments.context.appData, 'imageList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  dateStart = (date.valueOf());
  dateEnd = (date.valueOf()) + 24 * 60 * 60 * 1000;
  offerCache = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('offers'));
  if (offerCache && (getObjectProperty(offerCache, 'changeDate')) < dateEnd && (getObjectProperty(offerCache, 'changeDate')) > dateEnd) {
    ___arguments.context.dataModel['todaysOffers'] = (getObjectProperty(offerCache, 'offers'));
  } else {
    todaysOffer = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTodaysOffer`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if (!todaysOffer.length) {
      ___arguments.context.dataModel['todaysOffers'] = [];
    } else {
      ___arguments.context.dataModel['todaysOffers'] = (await asyncListSort((await Promise.all((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((todaysOffer.map(item => item['squareId']))))), 'objects')).map(async item => {  offer = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(todaysOffer, (getObjectProperty(item, 'id'))));
        item['objectId'] = (getObjectProperty(offer, 'objectId'));
        item['altImage'] = (getObjectProperty(offer, 'image'));
        item['useImage'] = (getObjectProperty(offer, 'useImage'));
        item['modifier'] = (getObjectProperty(offer, 'modifier'));
        item['variation'] = (getObjectProperty(offer, 'variation'));
        item['order'] = (getObjectProperty(offer, 'order'));
        await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](item, (getObjectProperty(___arguments.context.appData, 'imageList')));
      ; return item;}))), 1, async (item) => {


       return (getObjectProperty(item, 'order'));
      }));
    }
    localStorage.setItem('offers', JSON.stringify(({ 'changeDate': ((new Date()).valueOf()),'offers': (getObjectProperty(___arguments.context.dataModel, 'todaysOffers')) })));
    try {
      await RTdeleteTodaysOffer();

    } catch (error) {
      console.log(error);

    }
  }
  try {
    await listenTodaysOffer();

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/c5d8545ab0cce6a30ffe07b661cced6e/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var lastOrder, userData, cartItem, squareItemData, j, lastOrderItems, modifierNames, orders, squareOrders, k, curOrder, item, itemImages;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  itemImages = (getObjectProperty(___arguments.context.appData, 'imageList'));
  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if (getObjectProperty(userData, 'previousOrder')) {
    ___arguments.context.dataModel['previousOrder'] = (getObjectProperty(userData, 'previousOrder'));
  } else {
    orders = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getOrders`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'offset': 0 }).send());
    if (!orders.length) {
      ___arguments.context.dataModel['ordersList'] = [];
      userData['ordersList'] = [];
    } else {
      squareOrders = (await asyncListSort((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders')), -1, async (item) => {


       return (getObjectProperty(item, 'updated_at'));
      }));
      if (squareOrders.length < 20) {
        userData['ordersLoaded'] = true;
      }
      ___arguments.context.dataModel['ordersList'] = squareOrders;
      userData['ordersList'] = squareOrders;
    }
    if (squareOrders && !!squareOrders.length) {
      for (var j_index in squareOrders) {
        j = squareOrders[j_index];
        curOrder = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(orders, (getObjectProperty(j, 'id'))));
        j['cart'] = (getObjectProperty(curOrder, 'item'));
        var k_list = (getObjectProperty(j, 'line_items'));
        for (var k_index in k_list) {
          k = k_list[k_index];
          if (getObjectProperty(k, 'modifiers')) {
            cartItem = (await (async function(list, item) {
            	return list.find(cartItem => {
            	  const cartModifiers = Object.values(cartItem.selectedModifier).flat().map(item => item.catalog_object_id)
            	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

            	  if (cartModifiers.length === modifiers.length) {
            	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
            	    return cartItem.selectedVariation === item.catalog_object_id && matches.length === 0
            	  }
            	})
            })((getObjectProperty(j, 'cart')), k));
          } else {
            cartItem = (await (async function(list, id) {
            	return list.find(item => {
            	  if (item.selectedModifier) {
            	    return item.selectedVariation === id && Object.keys(item.selectedModifier).length === 0
            	  } else {
            	    return item.selectedVariation === id
            	  }

            	})
            })((getObjectProperty(j, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
          }
          k['id'] = (getObjectProperty(cartItem, 'squareId'));
          if (getObjectProperty(cartItem, 'item')) {
            Object.assign(k, ({ 'name': (getObjectProperty(cartItem, 'item.name')),'id': (getObjectProperty(cartItem, 'item.squareId')),'image': (getObjectProperty(cartItem, 'item.image')) }));
          }
        }
      }
      lastOrder = (await (async function(list, id) {
      	return list.find(item => item.id === id)
      })(squareOrders, (getObjectProperty((orders[0]), 'squareId'))));
      lastOrderItems = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((getObjectProperty(lastOrder, 'line_items')).map(item => item['id']))))), 'objects'));
      var j_list = (getObjectProperty(lastOrder, 'line_items'));
      for (var j_index2 in j_list) {
        j = j_list[j_index2];
        if (getObjectProperty(j, 'modifiers')) {
          cartItem = (await (async function(list, item) {
          	return list.find(cartItem => {
          	  const cartModifiers = Object.values(cartItem.selectedModifier).flat().map(item => item.catalog_object_id)
          	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

          	  if(cartModifiers.length === modifiers.length) {
          	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
          	    return cartItem.selectedVariation === item.catalog_object_id && matches.length === 0
          	  }
          	})
          })((getObjectProperty(lastOrder, 'cart')), j));
          modifierNames = ((getObjectProperty(j, 'modifiers')).map(item => item['name'])).join(', ');
          cartItem['modifiersStr'] = ([(getObjectProperty(j, 'variation_name')),', ',modifierNames].join(''));
        } else {
          cartItem = (await (async function(list, id) {
          	return list.find(item => {
          	  if (item.selectedModifier) {
          	    return item.selectedVariation === id && Object.keys(item.selectedModifier).length === 0
          	  } else {
          	    return item.selectedVariation === id
          	  }

          	})
          })((getObjectProperty(lastOrder, 'cart')), (getObjectProperty(j, 'catalog_object_id'))));
          cartItem['modifiersStr'] = (getObjectProperty(j, 'variation_name'));
        }
        squareItemData = (await (async function(list, id) {
        	return list.find(item => item.id === id).item_data
        })(lastOrderItems, (getObjectProperty(j, 'id'))));
        cartItem['id'] = (getObjectProperty(cartItem, 'squareId'));
        if (getObjectProperty(cartItem, 'item')) {
          cartItem['orderItemId'] = (getObjectProperty(cartItem, 'objectId'));
          cartItem['objectId'] = (getObjectProperty(cartItem, 'item.objectId'));
          cartItem['name'] = (getObjectProperty(cartItem, 'item.name'));
          if (!(getObjectProperty(cartItem, 'image'))) {
            cartItem['image'] = (getObjectProperty(cartItem, 'item.image'));
          }
          cartItem['id'] = (getObjectProperty(cartItem, 'item.squareId'));
          cartItem['modifier'] = (getObjectProperty(cartItem, 'item.modifier'));
          cartItem['variation'] = (getObjectProperty(cartItem, 'item.variation'));
        } else {
          cartItem['modifier'] = (getObjectProperty(cartItem, 'modifiers'));
          cartItem['variation'] = (getObjectProperty(cartItem, 'variations'));
        }
        cartItem['item_data'] = squareItemData;
        cartItem['selectedVariationId'] = (getObjectProperty(cartItem, 'selectedVariation'));
        cartItem['selectedModifiers'] = (getObjectProperty(cartItem, 'selectedModifier'));
      }
      ___arguments.context.dataModel['previousOrder'] = lastOrder;
      userData['previousOrder'] = lastOrder;
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/18673e71e5ad52e7a3869cb8b0afd5b9/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var favoriteList, userData, item, favItem, squareFavs, favorite, user, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  while (!(getObjectProperty(___arguments.context.appData, 'user'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  if (getObjectProperty(___arguments.context.appData, 'user')) {
    while (!(getObjectProperty(___arguments.context.appData, 'userData'))) {
      await new Promise(r => setTimeout(r, 100 || 0));
    }
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    if (getObjectProperty(userData, 'favoriteList')) {
      ___arguments.context.dataModel['favoriteList'] = (getObjectProperty(userData, 'favoriteList'));
    } else {
      favorite = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/userFavs`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(0)));
      user = (getObjectProperty(___arguments.context.appData, 'user'));
      if (!favorite.length) {
        user['favorite'] = [];
        ___arguments.context.dataModel['favoriteList'] = [];
        userData['favoriteList'] = [];
      } else {
        for (var j_index in favorite) {
          j = favorite[j_index];
          if (!(getObjectProperty(j, 'item')) || !(getObjectProperty(j, 'item.squareId'))) {
            await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(j, 'objectId'))));
            removeItemInList(favorite, j, 'objectId');
          }
        }
        user['favorite'] = favorite;
        if (!!favorite.length) {
          squareFavs = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((favorite.map(item => item['item'])).map(item => item['squareId']))))), 'objects'));
          favoriteList = (await Promise.all(favorite.map(async item => {  favItem = (await (async function(list, id) {
            	return list.find(fav => fav.id === id)
            })(squareFavs, (getObjectProperty((getObjectProperty(item, 'item')), 'squareId'))));
            item['item_data'] = (getObjectProperty(favItem, 'item_data'));
            item['id'] = (getObjectProperty(favItem, 'id'));
            item['favVariation'] = (getObjectProperty(item, 'variation'));
            item['favModifier'] = (getObjectProperty(item, 'modifier'));
            item['favId'] = (getObjectProperty((getObjectProperty(item, 'item')), 'objectId'));
            item['variation'] = (getObjectProperty((getObjectProperty(item, 'item')), 'variation'));
            item['modifier'] = (getObjectProperty((getObjectProperty(item, 'item')), 'modifier'));
            item['name'] = (getObjectProperty((getObjectProperty(item, 'item')), 'name'));
            item['image'] = (getObjectProperty((getObjectProperty(item, 'item')), 'image'));
          ; return item;})));
          ___arguments.context.dataModel['favoriteList'] = favoriteList;
          userData['favoriteList'] = favoriteList;
        }
      }
    }
    console.log('abc');
    console.log(getObjectProperty(___arguments.context.dataModel, 'favoriteList'));
  }

  },
  /* handler:onMounted */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userData = (getObjectProperty(___arguments.context.appData, 'userData'));

  return (userData && (getObjectProperty(userData, 'favoriteList')) && !!(getObjectProperty(userData, 'favoriteList')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/43a6cd194b161716070965a3120a44f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.appData, 'appLoaded')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/47e9dd9f3c7a923e10e89f7a455b87ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.appData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/fae5009accad70d100434e5e0d3cee16/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var wallet, balance, error, user, deviceId, token, order, amount, appConfig, userData, hookData, command;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createGiftCard() {
  while (!(getObjectProperty(___arguments.context.appData, 'mainLocationId'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  balance['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0));
  wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.appData, 'mainLocationId'))))), 'gift_card'));
  await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
  appConfig = (getObjectProperty(___arguments.context.appData, 'appConfig'));
  if ((getObjectProperty(appConfig, 'enableWelcomeGift')) && !(getObjectProperty(user, 'wasDeleted'))) {
    try {
      amount = (getObjectProperty(appConfig, 'welcomeGiftAmount'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.appData, 'mainLocationId')),'line_items': [({ 'name': 'Gift Card','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })],'discounts': [({ 'amount_money': ({ 'amount': amount,'currency': 'USD' }),'name': 'Welcome Gift Discount' })],'state': 'COMPLETED' })))), 'order'));
      wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.appData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
        try {
          token = (getObjectProperty((await ( async function (channels) { return BackendlessUI.DeviceAPI.registerDevice(channels) })('push')), 'deviceToken'));
          if (token) {
            deviceId = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getDevice`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(token) }).send());
            await Backendless.Messaging.pushWithTemplate('new_user', ({ 'deviceId': deviceId }));
          }

        } catch (error) {
          console.log(error);

        }
      }
      wallet['state'] = 'ACTIVE';
      await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(wallet, 'customerId')) })) );

    } catch (error) {
      console.log(error);

    }
  }
  Object.assign((getObjectProperty(___arguments.context.appData, 'user')), ({ 'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')) }));
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function Balance_Update_Hook() {
  getRTSubscriptions().remove('Balance Update Hook');

  ;(function() {
    const callback = async command => {
        hookData = (getObjectProperty(command, 'data'));
    if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'walletId')) == (getObjectProperty(hookData, 'walletId'))) {
      balance = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'balance'));
      balance['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(hookData, 'balance')) / 100)));
      ;(async () => {
          balance['wallet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());

      })();
    }

    };

    const channel = getChannelInstance('balance');

    channel.addCommandListener(callback);

    getRTSubscriptions().add('Balance Update Hook', () => channel.removeCommandListener(callback));
  })();
}


  if (await Backendless.UserService.isValidLogin()) {
    while (!(getObjectProperty(___arguments.context.appData, 'user'))) {
      await new Promise(r => setTimeout(r, 100 || 0));
    }
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    if (!(getObjectProperty(userData, 'balance'))) {
      balance = ({  });
      if (getObjectProperty(user, 'walletId')) {
        try {
          wallet = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());

        } catch (error) {
          await createGiftCard();

        }
      } else {
        await createGiftCard();
      }
      balance['wallet'] = wallet;
      balance['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((wallet ? (getObjectProperty(wallet, 'balance_money.amount')) / 100 : 0)));
      userData['balance'] = balance;
      await Balance_Update_Hook();
    }
  }

  },
  /* handler:onMounted */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.balance'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/home/components/d85bd7861d61fda3ad1a0d938d61f864/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.appData, 'userData.previousOrder.cart'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, locationsList, item, j, location2, locationTypes, address, imagesList, category, categoryItem, squareObjects, data, userLocation, user, order, payment, wallet, deviceId, token, amount, newLocations, list, cardsList, favItem, squareFavs, favorite, lastOrder, cartItem, squareItemData, lastOrderItems, modifierNames, orders, squareOrders, k, curOrder, birthday, monthList, favSquareIds, offer, todaysOffer, dateEnd, date, offset, res, newOffersList, newOffers, newIds, object, hookData, command;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getDeviceSubscriptions() { return getSubscriptionsManager('deviceSubscriptions');}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

/**
 * Describe this function...
 */
async function getTodaysOffer() {
  ;(async () => {
      date = (new Date());
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    dateEnd = (date.valueOf()) + 24 * 60 * 60 * 1000;
    todaysOffer = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['offerDate >= \'',(date.valueOf()),'\' and offerDate < \'',(dateEnd.valueOf()),'\''].join(''))).setPageSize(100)));
    if (!todaysOffer.length) {
      ___arguments.context.pageData['todaysOffers'] = [];
    } else {
      ___arguments.context.pageData['todaysOffers'] = (await asyncListSort((await Promise.all((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((todaysOffer.map(item => item['squareId']))))), 'objects')).map(async item => {  offer = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(todaysOffer, (getObjectProperty(item, 'id'))));
        item['objectId'] = (getObjectProperty(offer, 'objectId'));
        item['altImage'] = (getObjectProperty(offer, 'image'));
        item['useImage'] = (getObjectProperty(offer, 'useImage'));
        item['modifier'] = (getObjectProperty(offer, 'modifier'));
        item['variation'] = (getObjectProperty(offer, 'variation'));
        item['order'] = (getObjectProperty(offer, 'order'));
        await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](item, (getObjectProperty(___arguments.context.pageData, 'imageList')));
      ; return item;}))), 1, async (item) => {


       return (getObjectProperty(item, 'order'));
      }));
      try {
        await RTdeleteTodaysOffer();

      } catch (error) {
        console.log(error);

      }
    }
    try {
      await listenTodaysOffer();

    } catch (error) {
      console.log(error);

    }

  })();
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Data.of('ItemImage').find(Backendless.DataQueryBuilder.create().setProperties(['image', 'name', 'squareId', 'objectId']).setPageSize(100).setOffset(offset)));
  addItemToList(imagesList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}

/**
 * Describe this function...
 */
async function addOffers(newIds) {
  try {
    newOffers = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['offerDate >= \'',(date.valueOf()),'\' and offerDate < \'',(dateEnd.valueOf()),'\' and objectId in (\'',newIds.join('\',\''),'\')'].join(''))).setPageSize(100)));
    if (!!newOffers.length) {
      newOffersList = (await Promise.all((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((newOffers.map(item => item['squareId']))))), 'objects')).map(async item => {  offer = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(newOffers, (getObjectProperty(item, 'id'))));
        item['objectId'] = (getObjectProperty(offer, 'objectId'));
        item['altImage'] = (getObjectProperty(offer, 'image'));
        item['useImage'] = (getObjectProperty(offer, 'useImage'));
        await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](item, (getObjectProperty(___arguments.context.pageData, 'imageList')));
      ; return item;})));
      ___arguments.context.pageData['todaysOffers'] = newOffersList;
    }

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }
}

/**
 * Describe this function...
 */
async function listenTodaysOffer() {
  ;(async () => {
      getRTSubscriptions().remove('addTodaysOffer');

    ;(function() {
      const callback = async list => {
          await addOffers(list);
      await RTdeleteTodaysOffer();

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();
      const options = [callback];

      rtHandlers.addBulkUpsertListener.apply(rtHandlers, options);

      getRTSubscriptions().add('addTodaysOffer', () => rtHandlers.removeUpsertListener(callback));

    })();
      getRTSubscriptions().remove('addPeriodTodaysOffer');

    ;(function() {
      const callback = async list => {
          await addOffers(list);
      await RTdeleteTodaysOffer();

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();
      const options = [callback];

      rtHandlers.addBulkCreateListener.apply(rtHandlers, options);

      getRTSubscriptions().add('addPeriodTodaysOffer', () => rtHandlers.removeBulkCreateListener(callback));

    })();

  })();
}

/**
 * Describe this function...
 */
async function RTdeleteTodaysOffer() {
  ;(async () => {
      getRTSubscriptions().remove('deleteTodaysOffer');

    ;(function(whereClause) {
      const callback = async object => {
          ___arguments.context.pageData['todaysOffers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'todaysOffers')), async (item) => {


       return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(object, 'objectId')));
      }));

      };

      const rtHandlers = Backendless.Data.of('TodaysOffer').rt();

      const options = [callback];

      if (whereClause) {
         options.unshift(whereClause);
      }

      rtHandlers.addDeleteListener.apply(rtHandlers, options);

      getRTSubscriptions().add('deleteTodaysOffer', () => rtHandlers.removeDeleteListener(callback));

    })((['objectId IN (\'',((getObjectProperty(___arguments.context.pageData, 'todaysOffers')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));

  })();
}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function Balance_Update_Hook() {

  ;(function() {
    const callback = async command => {
        hookData = (getObjectProperty(command, 'data'));
    if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'walletId')) == (getObjectProperty(hookData, 'walletId'))) {
      ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(hookData, 'balance')) / 100)));
      ;(async () => {
          ___arguments.context.pageData['wallet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());

      })();
    }

    };

    const channel = getChannelInstance('balance');

    channel.addCommandListener(callback);

    getRTSubscriptions().add('Balance Update Hook', () => channel.removeCommandListener(callback));
  })();
}


  console.log(await Backendless.UserService.getUserRoles());
  try {
    ___arguments.context.pageData['user'] = ((getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false)));
    user = (getObjectProperty(___arguments.context.pageData, 'user'));
    if (user) {
      if (!(getObjectProperty(user, 'email')) || !(getObjectProperty(user, 'name'))) {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', ({ 'tab': 'signup' }));
      } else {
        try {
          await (async function(pageData) {
          	window.onpopstate = function(event) {
          	  Object.assign(pageData, event.state)
          	}
          })(___arguments.context.pageData);
          await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'home' }));
          if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {

            await (async function() {
              const callback = async (data) => {
                  if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'home') {
                await getTodaysOffer();
              } else if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'menu') {
                await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'user.location')));
                if ((getObjectProperty(___arguments.context.pageData, 'showCatalog')) || (getObjectProperty(___arguments.context.pageData, 'showItem'))) {
                  category = (getObjectProperty(___arguments.context.pageData, 'chosenCategory'));
                  category['item'] = (await Backendless.Data.of('Category').loadRelations((getObjectProperty(category, 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('item').setPageSize(100).setOffset(0)));
                  squareObjects = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((getObjectProperty(category, 'item')).map(item => item['squareId']))))), 'objects'));
                  category['catalog'] = (await asyncListSort((await Promise.all((getObjectProperty(category, 'item')).map(async item => {  categoryItem = (await (async function(list, id) {
                    	return list.find(item => item.id === id)
                    })(squareObjects, (getObjectProperty(item, 'squareId'))));
                    item['id'] = (getObjectProperty(categoryItem, 'id'));
                    item['item_data'] = (getObjectProperty(categoryItem, 'item_data'));
                  ; return item;}))), 1, async (item) => {


                   return (getObjectProperty(item, 'order'));
                  }));
                  ___arguments.context.pageData['catalogList'] = (getObjectProperty(category, 'catalog'));
                  var j_list = (getObjectProperty(___arguments.context.pageData, 'catalogList'));
                  for (var j_index in j_list) {
                    j = j_list[j_index];
                    if (!(getObjectProperty(j, 'image'))) {
                      await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](j, (getObjectProperty(___arguments.context.pageData, 'imageList')));
                    }
                  }
                }
              }

              };

              const eventName = 'onAppResumed'

              await BackendlessUI.DeviceAPI.addEventListener(eventName, callback)

              getDeviceSubscriptions().add('resumedAppRequest', () => BackendlessUI.DeviceAPI.removeEventListener(eventName, callback));
            })();
          }
          await (async function() {
          	const square = BackendlessUI.requireModule( "https://sandbox.web.squarecdn.com/v1/square.js" );

          	const map = BackendlessUI.requireModule( "https://maps.googleapis.com/maps/api/js?key=AIzaSyAMbIvlFqSOAtaGsFm1ogYluZj5KoX7frE&region=US&language=en&callback=initMap" );

          	window.initMap = function() {}
          })();
          ;(async () => {
              imagesList = [];
            await getItemImages(0);
            ___arguments.context.pageData['imageList'] = imagesList;
            await getTodaysOffer();

          })();
          if (!(getObjectProperty(user, 'customerId'))) {
            user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
          }
          monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          birthday = (getObjectProperty(user, 'dateOfBirth'));
          user['birthdayStr'] = ([monthList[((new Date(birthday).getMonth() + 1) - 1)],' ',(new Date(birthday).getDate()),', ',(new Date(birthday).getFullYear())].join(''));
          ;(async () => {
              orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['Users[order].objectId = \'',(getObjectProperty(user, 'objectId')),'\''].join(''))).setRelated('item').setRelationsDepth(2).setSortBy('created DESC').setPageSize(20)));
            if (!orders.length) {
              ___arguments.context.pageData['ordersList'] = [];
            } else {
              squareOrders = (await asyncListSort((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders')), -1, async (item) => {


               return (getObjectProperty(item, 'updated_at'));
              }));
              ___arguments.context.pageData['ordersList'] = squareOrders;
              if (!!squareOrders.length) {
                for (var j_index2 in squareOrders) {
                  j = squareOrders[j_index2];
                  curOrder = (await (async function(list, id) {
                  	return list.find(item => item.squareId === id)
                  })(orders, (getObjectProperty(j, 'id'))));
                  j['cart'] = (getObjectProperty(curOrder, 'item'));
                  var k_list = (getObjectProperty(j, 'line_items'));
                  for (var k_index in k_list) {
                    k = k_list[k_index];
                    if (getObjectProperty(k, 'modifiers')) {
                      cartItem = (await (async function(list, item) {
                      	return list.find(cartItem => {
                      	  const cartModifiers = Object.values(cartItem.selectedModifier).map(item => item[0].catalog_object_id)
                      	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

                      	  if (cartModifiers.length === modifiers.length) {
                      	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
                      	    return cartItem.selectedVariation === item.catalog_object_id && matches.length === 0
                      	  }
                      	})
                      })((getObjectProperty(j, 'cart')), k));
                    } else {
                      cartItem = (await (async function(list, id) {
                      	return list.find(item => {
                      	  if (item.selectedModifier) {
                      	    return item.selectedVariation === id && Object.keys(item.selectedModifier).length === 0
                      	  } else {
                      	    return item.selectedVariation === id
                      	  }

                      	})
                      })((getObjectProperty(j, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
                    }
                    k['name'] = (getObjectProperty(cartItem, 'item.name'));
                    k['id'] = (getObjectProperty(cartItem, 'item.squareId'));
                    k['image'] = (getObjectProperty(cartItem, 'item.image'));
                  }
                }
                lastOrder = (await (async function(list, id) {
                	return list.find(item => item.id === id)
                })(squareOrders, (getObjectProperty((orders[0]), 'squareId'))));
                lastOrderItems = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((getObjectProperty(lastOrder, 'line_items')).map(item => item['id']))))), 'objects'));
                var j_list2 = (getObjectProperty(lastOrder, 'line_items'));
                for (var j_index3 in j_list2) {
                  j = j_list2[j_index3];
                  if (getObjectProperty(j, 'modifiers')) {
                    cartItem = (await (async function(list, item) {
                    	return list.find(cartItem => {
                    	  const cartModifiers = Object.values(cartItem.selectedModifier).map(item => item[0].catalog_object_id)
                    	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

                    	  if(cartModifiers.length === modifiers.length) {
                    	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
                    	    return cartItem.selectedVariation === item.catalog_object_id && matches.length === 0
                    	  }
                    	})
                    })((getObjectProperty(lastOrder, 'cart')), j));
                    modifierNames = ((getObjectProperty(j, 'modifiers')).map(item => item['name'])).join(', ');
                    cartItem['modifiersStr'] = ([(getObjectProperty(j, 'variation_name')),', ',modifierNames].join(''));
                  } else {
                    cartItem = (await (async function(list, id) {
                    	return list.find(item => {
                    	  if (item.selectedModifier) {
                    	    return item.selectedVariation === id && Object.keys(item.selectedModifier).length === 0
                    	  } else {
                    	    return item.selectedVariation === id
                    	  }

                    	})
                    })((getObjectProperty(lastOrder, 'cart')), (getObjectProperty(j, 'catalog_object_id'))));
                    cartItem['modifiersStr'] = (getObjectProperty(j, 'variation_name'));
                  }
                  squareItemData = (await (async function(list, id) {
                  	return list.find(item => item.id === id).item_data
                  })(lastOrderItems, (getObjectProperty(j, 'id'))));
                  cartItem['id'] = (getObjectProperty(cartItem, 'item.squareId'));
                  cartItem['orderItemId'] = (getObjectProperty(cartItem, 'objectId'));
                  cartItem['objectId'] = (getObjectProperty(cartItem, 'item.objectId'));
                  cartItem['name'] = (getObjectProperty(cartItem, 'item.name'));
                  cartItem['image'] = (getObjectProperty(cartItem, 'item.image'));
                  cartItem['item_data'] = squareItemData;
                  cartItem['selectedVariationId'] = (getObjectProperty(cartItem, 'selectedVariation'));
                  cartItem['selectedModifiers'] = (getObjectProperty(cartItem, 'selectedModifier'));
                  cartItem['modifier'] = (getObjectProperty(cartItem, 'item.modifier'));
                  cartItem['variation'] = (getObjectProperty(cartItem, 'item.variation'));
                }
                ___arguments.context.pageData['previousOrder'] = lastOrder;
              }
            }

          })();
          favorite = (await Backendless.Data.of('UserFavorite').find(Backendless.DataQueryBuilder.create().setWhereClause((['Users[userFavorite].objectId = \'',(getObjectProperty(user, 'objectId')),'\''].join(''))).setRelated('item').setPageSize(100)));
          if (!favorite.length) {
            user['favorite'] = [];
            ___arguments.context.pageData['favoriteList'] = [];
          } else {
            for (var j_index4 in favorite) {
              j = favorite[j_index4];
              if (!(getObjectProperty(j, 'item')) || !(getObjectProperty(j, 'item.squareId'))) {
                await Backendless.Data.of('UserFavorite').remove( j );
                removeItemInList(favorite, j, 'objectId');
              }
            }
            user['favorite'] = favorite;
            if (!!favorite.length) {
              squareFavs = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((favorite.map(item => item['item'])).map(item => item['squareId']))))), 'objects'));
              ___arguments.context.pageData['favoriteList'] = (await Promise.all(favorite.map(async item => {  favItem = (await (async function(list, id) {
                	return list.find(fav => fav.id === id)
                })(squareFavs, (getObjectProperty((getObjectProperty(item, 'item')), 'squareId'))));
                item['item_data'] = (getObjectProperty(favItem, 'item_data'));
                item['id'] = (getObjectProperty(favItem, 'id'));
                item['favVariation'] = (getObjectProperty(item, 'variation'));
                item['favModifier'] = (getObjectProperty(item, 'modifier'));
                item['favId'] = (getObjectProperty((getObjectProperty(item, 'item')), 'objectId'));
                item['variation'] = (getObjectProperty((getObjectProperty(item, 'item')), 'variation'));
                item['modifier'] = (getObjectProperty((getObjectProperty(item, 'item')), 'modifier'));
                item['name'] = (getObjectProperty((getObjectProperty(item, 'item')), 'name'));
                item['image'] = (getObjectProperty((getObjectProperty(item, 'item')), 'image'));
              ; return item;})));
            }
          }
          ;(async () => {
              cardsList = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-cards-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'customer_id': JSON.stringify((getObjectProperty(user, 'customerId'))) }).send());
            ___arguments.context.pageData['cardsList'] = ((getObjectProperty(cardsList, 'cards')) ? (getObjectProperty(cardsList, 'cards')) : []);

          })();
          locationsList = (await asyncListFilter((getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations')), async (item) => {


           return ((getObjectProperty(item, 'business_hours')) && (getObjectProperty(item, 'status')) == 'ACTIVE');
          }));
          for (var j_index5 in locationsList) {
            j = locationsList[j_index5];
            address = (getObjectProperty(j, 'address'));
            j['addressStr'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
          }
          locationTypes = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setProperties(['squareId AS id', 'visitType', 'isMain', 'hidden', 'objectId']).setPageSize(100)));
          for (var j_index6 in locationTypes) {
            j = locationTypes[j_index6];
            location2 = locationsList[(((locationsList.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
            if (location2) {
              location2['visitType'] = (getObjectProperty(j, 'visitType'));
              location2['isMain'] = (getObjectProperty(j, 'isMain'));
              location2['hidden'] = (getObjectProperty(j, 'hidden'));
              location2['objectId'] = (getObjectProperty(j, 'objectId'));
            }
          }
          ___arguments.context.pageData['filteredLocationList'] = (await asyncListFilter(locationsList, async (item) => {


           return (!(getObjectProperty(item, 'hidden')));
          }));
          ___arguments.context.pageData['locationsList'] = (await asyncListFilter(locationsList, async (item) => {


           return (!(getObjectProperty(item, 'hidden')));
          }));
          ___arguments.context.pageData['mainLocation'] = (await (async function(list) {
          	return list.find(item => item.isMain)
          })(locationsList));
          ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(___arguments.context.pageData, 'mainLocation.id'));
          ;(async () => {

            ;(function() {
              const callback = async list => {
                  newLocations = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId IN (\'',list.join('\',\''),'\')'].join(''))).setProperties(['squareId AS id', 'isMain']).setPageSize(100)));
              var j_list3 = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
              for (var j_index7 in j_list3) {
                j = j_list3[j_index7];
                location2 = (getObjectProperty(___arguments.context.pageData, 'locationsList'))[(((newLocations.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
                if (location2) {
                  location2['isMain'] = (getObjectProperty(j, 'isMain'));
                }
              }
              ___arguments.context.pageData['mainLocation'] = (await (async function(list) {
              	return list.find(item => item.isMain)
              })((getObjectProperty(___arguments.context.pageData, 'locationsList'))));
              ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(___arguments.context.pageData, 'mainLocation.id'));

              };

              const rtHandlers = Backendless.Data.of('Location').rt();
              const options = [callback];

              rtHandlers.addBulkUpsertListener.apply(rtHandlers, options);

              getRTSubscriptions().add('ListenMainLocationChange', () => rtHandlers.removeUpsertListener(callback));

            })();

          })();
          if (getObjectProperty(user, 'walletId')) {
            wallet = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());
            ___arguments.context.pageData['wallet'] = wallet;
            ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((wallet ? (getObjectProperty(wallet, 'balance_money.amount')) / 100 : 0)));
          } else {
            ;(async () => {
                ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0));
              wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
              await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
              try {
                amount = 500;
                order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
                payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
                wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
                ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
                wallet['state'] = 'ACTIVE';
                if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
                  try {
                    token = (getObjectProperty((await ( async function (channels) { return BackendlessUI.DeviceAPI.registerDevice(channels) })('push')), 'deviceToken'));
                    if (token) {
                      deviceId = (getObjectProperty(((await Backendless.Data.of('DeviceRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['deviceToken = \'',token,'\''].join(''))).setPageSize(1)))[0]), 'deviceId'));
                      await Backendless.Messaging.pushWithTemplate('new_user', ({ 'deviceId': deviceId }));
                    }

                  } catch (error) {
                    console.log(error);

                  }
                }
                ___arguments.context.pageData['wallet'] = wallet;
                (getObjectProperty(___arguments.context.pageData, 'user'))['walletId'] = (getObjectProperty(wallet, 'id'));
                (getObjectProperty(___arguments.context.pageData, 'user'))['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
                await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(wallet, 'customerId')) })) );

              } catch (error) {
                console.log(error);

              }

            })();
          }
          await Balance_Update_Hook();
          userLocation = (getObjectProperty(user, 'location'));
          if (userLocation) {
            ___arguments.context.pageData['activeLocation'] = (locationsList[(((locationsList.map(item => item['id'])).indexOf(userLocation) + 1) - 1)]);
            await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, userLocation);
          }
          await (async function(locationList) {
          	let geocoder = new google.maps.Geocoder();
          	
          	locationList.forEach(location => {
          	  if(!location.coordinates) {
          	    geocoder.geocode( { 'address': location.addressStr}, function(results, status) {
          	      if (status == 'OK') {
          	        location.coordinates = {}
          	        location.coordinates.latitude = results[0].geometry.location.lat()
          	        location.coordinates.longitude = results[0].geometry.location.lng()
          	      } else {
          	        console.log('Geocode was not successful for the following reason: ' + status);
          	      }
          	    })
          	  }
          	})
          })(locationsList);
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
          ___arguments.context.pageData['taxList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('tax') }).send());

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        } finally {
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);

        }
      }
    } else {
      await (async function(pageData) {
      	window.onpopstate = function(event) {
      	  Object.assign(pageData, event.state)
      	}
      })(___arguments.context.pageData);
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'home' }));
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {

        await (async function() {
          const callback = async (data) => {
              if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'home') {
            await getTodaysOffer();
          } else if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'menu') {
            await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'user.location')));
            if ((getObjectProperty(___arguments.context.pageData, 'showCatalog')) || (getObjectProperty(___arguments.context.pageData, 'showItem'))) {
              category = (getObjectProperty(___arguments.context.pageData, 'chosenCategory'));
              category['item'] = (await Backendless.Data.of('Category').loadRelations((getObjectProperty(category, 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('item').setPageSize(100).setOffset(0)));
              squareObjects = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((getObjectProperty(category, 'item')).map(item => item['squareId']))))), 'objects'));
              category['catalog'] = (await asyncListSort((await Promise.all((getObjectProperty(category, 'item')).map(async item => {  categoryItem = (await (async function(list, id) {
                	return list.find(item => item.id === id)
                })(squareObjects, (getObjectProperty(item, 'squareId'))));
                item['id'] = (getObjectProperty(categoryItem, 'id'));
                item['item_data'] = (getObjectProperty(categoryItem, 'item_data'));
              ; return item;}))), 1, async (item) => {


               return (getObjectProperty(item, 'order'));
              }));
              ___arguments.context.pageData['catalogList'] = (getObjectProperty(category, 'catalog'));
              var j_list4 = (getObjectProperty(___arguments.context.pageData, 'catalogList'));
              for (var j_index8 in j_list4) {
                j = j_list4[j_index8];
                if (!(getObjectProperty(j, 'image'))) {
                  await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](j, (getObjectProperty(___arguments.context.pageData, 'imageList')));
                }
              }
            }
          }

          };

          const eventName = 'onAppResumed'

          await BackendlessUI.DeviceAPI.addEventListener(eventName, callback)

          getDeviceSubscriptions().add('resumedAppRequest', () => BackendlessUI.DeviceAPI.removeEventListener(eventName, callback));
        })();
      }
      await (async function() {
      	const square = BackendlessUI.requireModule( "https://sandbox.web.squarecdn.com/v1/square.js" );

      	const map = BackendlessUI.requireModule( "https://maps.googleapis.com/maps/api/js?key=AIzaSyAMbIvlFqSOAtaGsFm1ogYluZj5KoX7frE&region=US&language=en&callback=initMap" );

      	window.initMap = function() {}
      })();
      imagesList = [];
      await getItemImages(0);
      ___arguments.context.pageData['imageList'] = imagesList;
      await getTodaysOffer();
      locationsList = (await asyncListFilter((getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations')), async (item) => {


       return ((getObjectProperty(item, 'business_hours')) && (getObjectProperty(item, 'status')) == 'ACTIVE');
      }));
      for (var j_index9 in locationsList) {
        j = locationsList[j_index9];
        address = (getObjectProperty(j, 'address'));
        j['addressStr'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
      }
      locationTypes = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setProperties(['squareId AS id', 'visitType', 'isMain', 'hidden']).setPageSize(100)));
      for (var j_index10 in locationTypes) {
        j = locationTypes[j_index10];
        location2 = locationsList[(((locationsList.map(item => item['id'])).indexOf(getObjectProperty(j, 'id')) + 1) - 1)];
        if (location2) {
          location2['visitType'] = (getObjectProperty(j, 'visitType'));
          location2['isMain'] = (getObjectProperty(j, 'isMain'));
          location2['hidden'] = (getObjectProperty(j, 'hidden'));
        }
      }
      ___arguments.context.pageData['filteredLocationList'] = (await asyncListFilter(locationsList, async (item) => {


       return (!(getObjectProperty(item, 'hidden')));
      }));
      ___arguments.context.pageData['locationsList'] = (await asyncListFilter(locationsList, async (item) => {


       return (!(getObjectProperty(item, 'hidden')));
      }));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
      await (async function(locationList) {
      	let geocoder = new google.maps.Geocoder();
      	
      	locationList.forEach(location => {
      	  if(!location.coordinates) {
      	    geocoder.geocode( { 'address': location.addressStr}, function(results, status) {
      	      if (status == 'OK') {
      	        location.coordinates = {}
      	        location.coordinates.latitude = results[0].geometry.location.lat()
      	        location.coordinates.longitude = results[0].geometry.location.lng()
      	      } else {
      	        console.log('Geocode was not successful for the following reason: ' + status);
      	      }
      	    })
      	  }
      	})
      })(locationsList);
    }

  } catch (error) {
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43a6cd194b161716070965a3120a44f3', false);
    }

  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var reward, order, cart;


  ___arguments.context.pageData['screen'] = 'home';
  ___arguments.context.pageData['menu'] = ({  });
  ___arguments.context.pageData['tipsList'] = [1, 2, 5];
  cart = ((function(){ try { return JSON.parse(localStorage.getItem('cart')) } catch(e){ return null }})());
  ___arguments.context.pageData['cart'] = (cart ? cart : []);
  order = ((function(){ try { return JSON.parse(localStorage.getItem('currentOrder')) } catch(e){ return null }})());
  if (order) {
    ___arguments.context.pageData['currentOrder'] = order;
  }
  reward = ((function(){ try { return JSON.parse(localStorage.getItem('reward')) } catch(e){ return null }})());
  if (reward) {
    ___arguments.context.pageData['reward'] = reward;
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onLeave */
  ['onLeave'](___arguments) {
    function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getDeviceSubscriptions() { return getSubscriptionsManager('deviceSubscriptions');}


  getRTSubscriptions().remove('addTodaysOffer');
  getRTSubscriptions().remove('deleteTodaysOffer');
  getRTSubscriptions().remove('AddNewCategory');
  getRTSubscriptions().remove('addPeriodTodaysOffer');
  getRTSubscriptions().remove('listenItemChange');
  getRTSubscriptions().remove('ListenMainLocationChange');
  getRTSubscriptions().remove('Balance Update Hook');
  getDeviceSubscriptions().remove('resumedAppRequest');

  },
  /* handler:onLeave */
  /* content */
}))

define('./pages/landing_backup/components/c7c8a902ac34c68ba78775ba06913472/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'home')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b952013a8d781f22ba8bea8e2f62eee7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'settings')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/935ba5e5894c78467b04798b60e76cee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'menu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(async () => {
      try {
      ___arguments.context.pageData['modifiersList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('modifier_list') }).send());

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }

  })();

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/009ccd4ecccef8d817e6ac32c7b97795/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'location')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['locationView'] = 'list';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/11ab3313596c7e67f8c477aa5438744f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var data;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'home' }));
    ___arguments.context.pageData['screen'] = 'home';
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'home' ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/ba4dca12ca12c5ab578277ae41c27301/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var location2, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': true,'showCatalog': false,'showItem': false }));
    ___arguments.context.pageData['screen'] = 'menu';
    ___arguments.context.pageData['showCategory'] = true;
    ___arguments.context.pageData['showCatalog'] = false;
    ___arguments.context.pageData['showItem'] = false;
    location2 = (getObjectProperty(___arguments.context.pageData, 'activeLocation.id'));
    if (location2) {
      await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, location2);
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'menu' ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2487e69734eb1ed933420f2510cd57af/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'pay' ? ['navbar__item', 'navbar__item--w85', 'active'] : ['navbar__item', 'navbar__item--w85'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/7cbc502c817266c8c9377ba76f5e7051/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.pageData);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'location' ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/bd1b573124e75f1bb4a63faa9815af86/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    if (getObjectProperty(___arguments.context.pageData, 'user')) {
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'main' }));
      ___arguments.context.pageData['screen'] = 'settings';
      ___arguments.context.pageData['tab'] = 'main';
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'settings' ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d28982ab45440591d69a4d6c1ceabafc/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      ___arguments.context.pageData['cardNumber'] = (await (async function(value) {
  	
  	let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  	let matches = v.match(/\d{4,16}/g);
  	let match = matches && matches[0] || ''
  	let parts = []
  	
  	for (i=0, len=match.length; i<len; i+=4) {
  	  parts.push(match.substring(i, i+4))
  	}
  	
  	if (parts.length) {
  	  return parts.join(' ')
  	} else {
  	  return value
  	}
  })(___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/e6d5d2484e4b75a94638dbc49c73938d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['locationView'] = 'list';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('821a937b393a2bcb49c526c759446c83', false);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'list' ? ['location__menu_item', 'active'] : ['location__menu_item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d3e1cf038a9bef9d529531bc09f6dfee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['locationView'] = 'map';
  await new Promise(r => setTimeout(r, 1 || 0));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('821a937b393a2bcb49c526c759446c83', true);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? ['location__menu_item', 'active'] : ['location__menu_item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2bf89bb1a4f9f09e3025ef54ae66cbd4/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'list' ? '/img/icons/list_view_active.svg' : '/img/icons/list_view.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/0f8de7e359ac5435dfe7f573c98eac44/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? '/img/icons/map_view_active.svg' : '/img/icons/map_view.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/0d7517c489d90c2f5c180363671bb2ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? ['location__list', 'location__list--row', 'hide-scroll'] : ['location__list', 'location__list--column', 'hide-scroll'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/9125044b2f7e0b91c38e6217df2775a2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'checkout')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b584707c94d14e68ae9a8d5cd1cf058c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'user'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'checkout','checkoutTab': 'qr' }));
    ___arguments.context.pageData['screen'] = 'checkout';
    ___arguments.context.pageData['checkoutTab'] = 'qr';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/fbde59d360654c7f336e7609c888a9b4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.UserService.logout();
    delete ___arguments.context.appData['user'];
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
    localStorage.removeItem('cart');
    localStorage.removeItem('currentOrder');

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1b64de07e43e094c5c81b00235fca22b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'main')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/9611e8c8daa540d3a5c57b57f79edc46/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'rewards')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/494be508940b28723a7f59a3e7ede8d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'cards')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/13191393465e98b7edb84e8e912b1773/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'fav')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/93c316b1d39e89cf77ebae4365dd6219/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'history')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/fd675087da54cfe252ecde9b1058e441/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'wallet')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2d2fdbf56685771bcc07fb7556e92f06/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.newValue) {
    await (async function(location, pageData) {
    	const appId = 'sq0idp-F5SAAmEU_KgZHsEeQnUTrQ';
    	const locationId = location;

    	async function main() {
    	  const payments = Square.payments(appId, locationId);
    	  const card = await payments.card();

    	  await card.attach('#card-container');

    	  pageData.loading = false;

    	  async function eventHandler(event) {
    	    event.preventDefault();

    	    try {
    	      const result = await card.tokenize();

    	      if (result.status === 'OK') {
    	        pageData.cardToken = result.token;
    	      }
    	      pageData.loading = false;
    	    } catch (e) {
    	      pageData.loading = false;
    	    }
    	  };

    	  const cardButton = document.querySelector('.card__submit');
    	  cardButton.addEventListener('click', eventHandler);
    	}

    	main();
    })((getObjectProperty(___arguments.context.pageData, 'user.location')), ___arguments.context.pageData);
  }
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/landing_backup/components/31daf115497471687215695c184efba3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) != 'chat')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/723bcfc31a7e1131f492a9dc96a8d63c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'chat')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'addCard') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2d2fdbf56685771bcc07fb7556e92f06', false);
  }
  await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) != 'main')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/95fd5ae8fd633aefb714d3831e515c7e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'rewards' }));
  ___arguments.context.pageData['tab'] = 'rewards';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/04f56ead3c0bd5fd547c75c1b499331d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'cards' }));
  ___arguments.context.pageData['tab'] = 'cards';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1b04b2cd9a01bd09fb87459ecd4f6c27/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'fav' }));
  ___arguments.context.pageData['tab'] = 'fav';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/298b4f13b0cacd479453ebabae8ad524/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'history' }));
  ___arguments.context.pageData['tab'] = 'history';
  ___arguments.context.pageData['historyObjectId'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c87fef686e49cb41abcebea341274ec3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['tab'] = 'chat';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/152809fd00722ae68a55caf292a30b5c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'main') {
    return 'profile';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'rewards') {
    return 'my rewards';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'cards') {
    return 'my cards';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'fav') {
    return 'my favorites';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'history') {
    return 'order history';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'wallet') {
    return 'wallet';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'addCard') {
    return 'add card';
  } else if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'chat') {
    return 'support';
  }

  return ''

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/f7250af34986f527f2965d6369c5d90f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/faf4097ffd479f845a2fdff86a630cb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categoryItem, currentItem, error, item, modifier, object, squareObjects, variation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function listenItems() {
  getRTSubscriptions().remove('listenItemChange');

  ;(function(whereClause) {
    const callback = async object => {
        Object.assign((await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(___arguments.context.dataModel, 'item')), (getObjectProperty(object, 'objectId')))), object);
    currentItem = (await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(___arguments.context.pageData, 'catalogList')), (getObjectProperty(object, 'objectId'))));
    if (getObjectProperty(object, 'modifier')) {
      currentItem['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


       return ((Object.keys((getObjectProperty(object, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
      })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


         return ((getObjectProperty(object, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
        }));
      ; return item;})));
    }
    currentItem['variations'] = (await asyncListFilter((getObjectProperty(currentItem, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(object, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));

    };

    const rtHandlers = Backendless.Data.of('Item').rt();

    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addUpdateListener.apply(rtHandlers, options);

    getRTSubscriptions().add('listenItemChange', () => rtHandlers.removeUpdateListener(callback));

  })((['objectId IN (\'',((getObjectProperty(___arguments.context.dataModel, 'catalog')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));
    }


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    try {
      ___arguments.context.dataModel['item'] = (await Backendless.Data.of('Category').loadRelations((getObjectProperty(___arguments.context.dataModel, 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('item').setPageSize(100).setOffset(0)));
      squareObjects = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-items`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((getObjectProperty(___arguments.context.dataModel, 'item')).map(item => item['squareId']))))), 'objects'));
      ___arguments.context.dataModel['catalog'] = (await asyncListSort((await Promise.all((getObjectProperty(___arguments.context.dataModel, 'item')).map(async item => {  categoryItem = (await (async function(list, id) {
        	return list.find(item => item.id === id)
        })(squareObjects, (getObjectProperty(item, 'squareId'))));
        if (categoryItem) {
          item['id'] = (getObjectProperty(categoryItem, 'id'));
          item['item_data'] = (getObjectProperty(categoryItem, 'item_data'));
        } else {
          console.log(item);
        }
      ; return item;}))), 1, async (item) => {


       return (getObjectProperty(item, 'order'));
      }));
      ___arguments.context.pageData['catalogList'] = (getObjectProperty(___arguments.context.dataModel, 'catalog'));
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'showCategory': false,'showCatalog': true,'showItem': false,'todaysOffer': false,'lastOrder': false,'favorite': false }));
      Object.assign(___arguments.context.pageData, ({ 'chosenCategory': ___arguments.context.dataModel,'showCategory': false,'showCatalog': true,'showItem': false,'todaysOffer': false,'lastOrder': false,'favorite': false }));
      ;(async () => {
          await listenItems();

      })();

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/74e24a658f1109d2dc283ff6cb4b262e/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeItem')) && (getObjectProperty(___arguments.context.pageData, 'user')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')).map(item => item['favId'])).includes(((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'favId')) ? (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'favId')) : (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'objectId')))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, error, newFav, userFavorite, favoriteList, actId, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  actId = (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId'));
  if (!(getObjectProperty(item, 'savingFav'))) {
    item['savingFav'] = true;
    favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes(actId)) {
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, actId));
      ;(async () => {
          try {
          await Backendless.Data.of('UserFavorite').remove( favItemId );

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        newFav = (await Backendless.Data.of('UserFavorite').deepSave( ({ 'item': ({ 'objectId': actId }),'variation': (getObjectProperty(item, 'selectedVariationId')),'modifier': ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9')) }) ));
        newFav['favId'] = (getObjectProperty(newFav, 'item.objectId'));
        newFav['item_data'] = (getObjectProperty(item, 'item_data'));
        newFav['id'] = (getObjectProperty(item, 'id'));
        newFav['favVariation'] = (getObjectProperty(newFav, 'variation'));
        newFav['favModifier'] = (getObjectProperty(newFav, 'modifier'));
        newFav['variation'] = (getObjectProperty(newFav, 'item.variation'));
        newFav['modifier'] = (getObjectProperty(newFav, 'item.modifier'));
        newFav['name'] = (getObjectProperty(item, 'name'));
        newFav['image'] = (getObjectProperty(item, 'image'));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);
        await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'userFavorite', [newFav]);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          pageData['networkError'] = true;
        }

      }
    }
    await new Promise(r => setTimeout(r, 300 || 0));
    item['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8001b20e2ab3b6465d84de629bd3baa6/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'user')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId'))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newFav, userFavorite, favoriteList, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'savingFav'))) {
    ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['savingFav'] = true;
    favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId')))) {
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId'))));
      ;(async () => {
          try {
          await Backendless.Data.of('UserFavorite').remove( favItemId );

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        newFav = (await Backendless.Data.of('UserFavorite').deepSave( ({ 'item': ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId')) }) }) ));
        newFav['favId'] = (getObjectProperty(newFav, 'item.objectId'));
        newFav['item_data'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'item_data'));
        newFav['id'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'id'));
        newFav['variation'] = (getObjectProperty(newFav, 'item.variation'));
        newFav['modifier'] = (getObjectProperty(newFav, 'item.modifier'));
        newFav['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'name'));
        newFav['image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'image'));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);
        await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'userFavorite', [newFav]);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      }
    }
    await new Promise(r => setTimeout(r, 300 || 0));
    ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/ef0a8fcc64c7fc2fa05691271c3b714f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, currentItem, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    currentItem = (await (async function(list, condition) {
    	return list.find(item => item.objectId === condition)
    })((getObjectProperty(___arguments.context.pageData, 'chosenCategory.item')), (getObjectProperty(___arguments.context.dataModel, 'objectId'))));
    if (getObjectProperty(currentItem, 'modifier')) {
      ___arguments.context.dataModel['modifier'] = (getObjectProperty(currentItem, 'modifier'));
      ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


       return ((Object.keys((getObjectProperty(currentItem, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
      })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


         return ((getObjectProperty(currentItem, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
        }));
      ; return item;})));
    }
    ___arguments.context.dataModel['variation'] = (getObjectProperty(currentItem, 'variation'));
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(currentItem, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
    if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
      ___arguments.context.dataModel['showVariation'] = true;
    }
    if (getObjectProperty(___arguments.context.dataModel, 'favVariation')) {
      ___arguments.context.dataModel['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'favVariation'));
    }
    if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
      ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
    }
    if (getObjectProperty(___arguments.context.pageData, 'activeLocation')) {
      ___arguments.context.pageData['loading'] = true;
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'showCategory': false,'showCatalog': false,'showItem': true }));
      ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
      console.log(JSON.parse((JSON.stringify(___arguments.context.dataModel))));
      Object.assign(___arguments.context.pageData, ({ 'showCategory': false,'showCatalog': false,'showItem': true }));
      ___arguments.context.pageData['loading'] = false;
      (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('533634d37002c885ec1213b16dad63a9')), 'el'))['scrollTop'] = 0;
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', true);
    }
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'imageList')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/5cdd130697dbcd027b621c699aa3fe0d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCategory')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('533634d37002c885ec1213b16dad63a9')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/67cd2ed6a5cdd8fb24f896c6f7e64742/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'showCategory')) {
    return 'order';
  } else {
    if (getObjectProperty(___arguments.context.pageData, 'todaysOffer')) {
      return 'today\'s offers';
    } else if (getObjectProperty(___arguments.context.pageData, 'lastOrder')) {
      return 'previous';
    } else if (getObjectProperty(___arguments.context.pageData, 'favorite')) {
      return 'favorites';
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'chosenCategory.name'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/17b42e8753514bf1b64d29db869723fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var favoriteList, favItemId, userFavorite, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
  userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
  favItemId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), 'objectId'));
  ;(async () => {
      try {
      await Backendless.Data.of('UserFavorite').remove( favItemId );

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }

  })();
  removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
  removeItemInList(favoriteList, ___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), 'objectId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/88551079be2c4d1d6b3314375795389c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var favoriteList, favItemId, userFavorite, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
  userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
  favItemId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'), 'objectId'));
  ;(async () => {
      try {
      await Backendless.Data.of('UserFavorite').remove( favItemId );

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }

  })();
  removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
  removeItemInList(favoriteList, ___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'), 'objectId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/ead4338b07f96f7bd9aa8ea508213107/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
    ___arguments.context.dataModel['showVariation'] = true;
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favVariation')) {
    ___arguments.context.dataModel['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'favVariation'));
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
    ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
  }
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
    ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
  }
  while (!(getObjectProperty(___arguments.context.pageData, 'imageList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'imageList')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/8a44b738e72ce3215405234d34f42031/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentCategory, categoryId, categoryList, variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4f6874b8ccb5adf1e6a2a463b65600d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'activeItem'))['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'selectedVariationId')) == (getObjectProperty(___arguments.context.dataModel, 'id')) ? ['modifier__option', 'active'] : ['modifier__option'])

  },
  /* handler:onClassListAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'selectedVariationId')) && (getObjectProperty(___arguments.context.dataModel, 'id')) == (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'activeItem.variations'))[0]), 'id'))) {
    (getObjectProperty(___arguments.context.pageData, 'activeItem'))['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/979debb96d4db7b9e15f19acb18f23b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pointCoordinates;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation')) && (getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map') {
    pointCoordinates = (getObjectProperty(___arguments.context.dataModel, 'coordinates'));
    await (async function(userPoint, destinationPoint, map, pageData, points) {
    	const img = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon.png',
    	      imgActive = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-active.png',
    	      destinationLat = destinationPoint.lat,
    	      destinationLng = destinationPoint.lng,
    	      inactiveMarkers = points.filter(point => point.lat !== destinationLat && point.lng !== destinationLng);
    	
    	scrollToLocation(destinationLat, destinationLng);
    	
    	map.setCenter(new google.maps.LatLng(destinationLat, destinationLng));
    	
    	if(userPoint) renderDirection(destinationPoint);
    	
    	addMarkersToMap(pageData.markers, null);
    	
    	pageData.markers = [];
    	
    	addMarkers(img, ...inactiveMarkers);
    	addMarkers(imgActive, destinationPoint);
    	
    	addMarkersToMap(pageData.markers, map);
    	
    	function addMarkers (img, ...latlng) {
    	  for (let i = 0; i < latlng.length; i++) {
    	    const position = new google.maps.LatLng(latlng[i].lat, latlng[i].lng);
    	    
    	    const marker = new google.maps.Marker({
    	      position: position,
    	      icon: img,
    	    });
    	    
    	    pageData.markers.push(marker);
    	    
    	    marker.addListener('click', setMarkers);
    	  }
    	}
    	
    	function renderDirection (position) {
    	  const directionsService = new google.maps.DirectionsService(),
    	        start = new google.maps.LatLng(userPoint.lat, userPoint.lng),
    	        end = new google.maps.LatLng(position.lat, position.lng);
    	  let directionsRenderer;
    	  
    	  if (pageData.directionsRenderer) {
    	    directionsRenderer = pageData.directionsRenderer;
    	  } else {
    	    directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});
    	    pageData.directionsRenderer = directionsRenderer;
    	  }
    	  
    	  directionsRenderer.setMap(map);
    	  
    	  const request = {
    	    origin: start,
    	    destination: end,
    	    travelMode: 'DRIVING'
    	  };
    	    
    	  directionsService.route(request, function(result, status) {
    	    if (status == 'OK') {
    	      directionsRenderer.setDirections(result);
    	    }
    	  })
    	}
    	
    	function setMarkers () {
    	  const markerPosition = this.getPosition(),
    	        currentMarkerLat = markerPosition.lat(),
    	        currentMarkerLng = markerPosition.lng();
    	        
    	  renderDirection({
    	    lat: currentMarkerLat, 
    	    lng: currentMarkerLng
    	  });
    	
    	  addMarkersToMap(pageData.markers, null);
    	
    	  pageData.markers = [];
    	
    	  const inactiveMarkers = points.filter(point => point.lat !== currentMarkerLat && point.lng !== currentMarkerLng)
    	
    	  addMarkers(img, ...inactiveMarkers);
    	  addMarkers(imgActive, {
    	    lat: currentMarkerLat,
    	    lng: currentMarkerLng
    	  });
    	
    	  addMarkersToMap(pageData.markers, map);
    	  
    	  scrollToLocation(currentMarkerLat, currentMarkerLng);
    	}
    	
    	function addMarkersToMap (markersList, map) {
    	  for (i = 0; i < markersList.length; i++) {
    	    markersList[i].setMap(map);
    	  }
    	}
    	
    	function scrollToLocation (lat, lng) {
    	  const locationIndex = pageData.locationsList.findIndex(location => (
    	    location.coordinates.latitude === lat && location.coordinates.longitude === lng
    	  ));
    	  
    	  const locationListComponent = document.querySelector('.location__list')
    	  locationListComponent.scrollLeft = 336 * locationIndex;
    	}
    })((getObjectProperty(___arguments.context.pageData, 'mapCenter')), ({ 'lat': (getObjectProperty(pointCoordinates, 'latitude')),'lng': (getObjectProperty(pointCoordinates, 'longitude')) }), (getObjectProperty(___arguments.context.pageData, 'googleMap')), ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'coordinates')));
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var address, closeTime, dayNum, dayPeriod, item, openTime, periods, schedule, today, workingHours;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createWorkingTimeString(workingHours) {
  openTime = (Number(((getObjectProperty(workingHours, 'start_local_time')).slice(0, 2))));
  closeTime = (Number(((getObjectProperty(workingHours, 'end_local_time')).slice(0, 2))));
  return [await timeToAMPM(openTime),' - ',await timeToAMPM(closeTime)].join('')
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function timeToAMPM(dayPeriod) {
  return dayPeriod >= 12 ? String(dayPeriod == 12 ? dayPeriod : dayPeriod - 12) + String(' PM') : (dayPeriod == 0 ? '12 AM' : String(dayPeriod) + String(' AM'))
}


  periods = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'business_hours')), 'periods'));
  if (periods) {
    dayNum = (new Date((new Date())).getDay());
    today = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][((dayNum + 1) - 1)];
    schedule = (await asyncListFilter(periods, async (item) => {


     return ((getObjectProperty(item, 'day_of_week')) == today);
    }));
    ___arguments.context.dataModel['workingTime'] = (!schedule.length ? 'Closed Today' : await createWorkingTimeString(schedule[0]));
  } else {
    ___arguments.context.dataModel['workingTime'] = 'Closed today';
  }
  address = (getObjectProperty(___arguments.context.dataModel, 'address'));
  ___arguments.context.dataModel['addressString'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/eca3761dc9da373430d2e5537a9f32bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var user, locationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  ___arguments.context.pageData['loading'] = true;
  user = (getObjectProperty(___arguments.context.pageData, 'user'));
  locationId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5'), 'id'));
  if ((getObjectProperty(___arguments.context.pageData, 'activeLocation.id')) != locationId) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': true,'showCatalog': false,'showItem': false }));
    ___arguments.context.pageData['activeLocation'] = ___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5');
    await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, locationId);
    if (user) {
      user['location'] = locationId;
      await Backendless.UserService.update( new Backendless.User(user) );
      ___arguments.context.pageData['cart'] = [];
      localStorage.removeItem('cart');
    }
    Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': true,'showCatalog': false,'showItem': false }));
  }
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/79aea4468572e58e0de7e127579547f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.pageData);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3209bfe6adb55ba82bdf0787070a0ab9')), 'display')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3209bfe6adb55ba82bdf0787070a0ab9/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var closeTime, workingHours, address, activeLocation, schedule, today, item, periods, dayNum, dayPeriod;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createWorkingTimeString(workingHours) {
  closeTime = (Number(((getObjectProperty(workingHours, 'end_local_time')).slice(0, 2))));
  return String('Open until ') + String(await timeToAMPM(closeTime))
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function timeToAMPM(dayPeriod) {
  return dayPeriod >= 12 ? String(dayPeriod == 12 ? dayPeriod : dayPeriod - 12) + String(' PM') : (dayPeriod == 0 ? '12 AM' : String(dayPeriod) + String(' AM'))
}


  activeLocation = (getObjectProperty(___arguments.context.pageData, 'activeLocation'));
  if (activeLocation) {
    periods = (getObjectProperty((getObjectProperty(activeLocation, 'business_hours')), 'periods'));
    if (periods) {
      dayNum = (new Date((new Date())).getDay()) + 1;
      today = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][(dayNum - 1)];
      schedule = (await asyncListFilter(periods, async (item) => {


       return ((getObjectProperty(item, 'day_of_week')) == today);
      }));
      if (!schedule.length) {
        activeLocation['workingTimeStr'] = 'Closed';
      } else {
        activeLocation['workingTimeStr'] = (await createWorkingTimeString(schedule[0]));
      }
    } else {
      activeLocation['workingTimeStr'] = 'Closed';
    }
    address = (getObjectProperty(activeLocation, 'address'));
    activeLocation['addressString'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.pageData);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5fdd5d5a9e6a333f2f9ca4683e0b6c68/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierListId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(___arguments.context.getComponentDataStoreByUid('2df9fe6dec33d3242edb2947f61c011f'));
  console.log(___arguments.context.dataModel);
  modifierListId = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'modifier_data')), 'modifier_list_id'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2df9fe6dec33d3242edb2947f61c011f'), 'modifier_list_data')), 'selection_type')) == 'MULTIPLE' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId))) {
    if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).map(item => item['catalog_object_id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) {
      removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)), ({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) }), 'catalog_object_id');
      if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).length) {
        delete ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId];
      }
    } else {
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2df9fe6dec33d3242edb2947f61c011f'), 'modifier_list_data.selection_type')) == 'MULTIPLE') {
        addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)), ({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) }));
      } else {
        ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId] = [({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) })];
      }
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId] = [({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) })];
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var modifierListId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  modifierListId = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'modifier_data')), 'modifier_list_id'));
  if ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)))) && (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).map(item => item['catalog_object_id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))) {
    return ['modifier__option', 'active'];
  } else {
    return ['modifier__option'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/ef436ef6e4c744ca0cca465a5d8eaacb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, cart, updatedOrder, rewardItem, k, item, j, rewardName, currentOrder, user, activeItem, orderItem, locationId, cartItem, modifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    if (getObjectProperty(___arguments.context.pageData, 'user')) {
      if (getObjectProperty(___arguments.context.pageData, 'activeLocation')) {
        ___arguments.context.pageData['loading'] = true;
        locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
        user = (getObjectProperty(___arguments.context.pageData, 'user'));
        cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
        activeItem = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
        if (!(getObjectProperty(activeItem, 'showVariation'))) {
          activeItem['selectedVariationId'] = ((getObjectProperty(___arguments.context.pageData, 'activeItem.variation'))[0]);
        }
        if (getObjectProperty(activeItem, 'favId')) {
          activeItem['objectId'] = (getObjectProperty(activeItem, 'favId'));
        }
        if (getObjectProperty(activeItem, 'selectedVariationId')) {
          modifiers = [];
          var j_list = ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9'));
          for (var j_index in j_list) {
            j = j_list[j_index];
            modifiers = (modifiers.concat(j));
          }
          activeItem['orderData'] = ({ 'quantity': (getObjectProperty(activeItem, 'quantity')),'catalog_object_id': (getObjectProperty(activeItem, 'selectedVariationId')),'modifiers': modifiers,'note': (getObjectProperty(___arguments.context.pageData, 'note')) });
          ___arguments.context.pageData['note'] = '';
          activeItem['selectedModifiers'] = ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9'));
          if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'cart')))) {
            cartItem = (await BackendlessUI.Functions.Custom['fn_8e28277e1bdfb38d4d1f2b8a6b6ad168'](cart, activeItem));
            if (cartItem) {
              cartItem['quantity'] = (String((Number((getObjectProperty(cartItem, 'quantity')))) + (Number((getObjectProperty(activeItem, 'quantity'))))));
              (getObjectProperty(cartItem, 'orderData'))['quantity'] = (String((Number((getObjectProperty(cartItem, 'orderData.quantity')))) + (Number((getObjectProperty(activeItem, 'orderData.quantity'))))));
            } else {
              addItemToList(cart, activeItem);
            }
          } else {
            ___arguments.context.pageData['cart'] = [activeItem];
          }
          localStorage.setItem('cart', JSON.stringify(cart));
          currentOrder = (getObjectProperty(___arguments.context.pageData, 'currentOrder'));
          if (currentOrder) {
            var j_list2 = (getObjectProperty(currentOrder, 'line_items'));
            for (var j_index2 in j_list2) {
              j = j_list2[j_index2];
              if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(activeItem, 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(activeItem, 'orderData.modifiers')) || !(getObjectProperty(activeItem, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(activeItem, 'orderData.modifiers')) && !!(getObjectProperty(activeItem, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(activeItem, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


               return (!(((getObjectProperty(activeItem, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
              })).length)) {
                orderItem = j;
                break;
              }
            }
            if (orderItem) {
              orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) + (Number((getObjectProperty(activeItem, 'quantity'))))));
            }
            try {
              updatedOrder = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': locationId,'line_items': orderItem ? (getObjectProperty(currentOrder, 'line_items')) : (addItemToList((getObjectProperty(currentOrder, 'line_items')), (getObjectProperty(activeItem, 'orderData')))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'id': (getObjectProperty(currentOrder, 'id')),'version': (getObjectProperty(currentOrder, 'version')) }) })), 'order'));
              ___arguments.context.pageData['currentOrder'] = updatedOrder;
              for (var j_index3 in cart) {
                j = cart[j_index3];
                delete j['reward'];
              }
              var j_list3 = (getObjectProperty(updatedOrder, 'line_items'));
              for (var j_index4 in j_list3) {
                j = j_list3[j_index4];
                if (getObjectProperty(j, 'applied_discounts')) {
                  rewardName = (getObjectProperty(j, 'name'));
                  for (var k_index in cart) {
                    k = cart[k_index];
                    if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


                     return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
                    })).length)) {
                      rewardItem = k;
                      break;
                    }
                  }
                  rewardItem['reward'] = (getObjectProperty(___arguments.context.pageData, 'reward.id'));
                  break;
                }
              }
              localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
              localStorage.setItem('cart', JSON.stringify(cart));

            } catch (error) {
              console.log(error);
              if ((getObjectProperty(error, 'message')) == 'Network Error') {
                ___arguments.context.pageData['loading'] = false;
                ___arguments.context.pageData['networkError'] = true;
              }

            }
          }
          await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'checkout','checkoutTab': 'cart' }));
          ___arguments.context.pageData['screen'] = 'checkout';
          ___arguments.context.pageData['checkoutTab'] = 'cart';
        }
        ___arguments.context.pageData['loading'] = false;
      } else {
        if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
          await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.pageData);
        }
      }
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? ((getObjectProperty(___arguments.context.pageData, 'activeLocation')) ? 'Add to Pickup Order' : 'Choose pickup location') : 'Sign In to Order')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing_backup/components/39d843e6509e0d55aefb312a686b7b6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, locationId, cartItem, k, cart, savedOrder, payment, item, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.pageData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))))) {
      locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
      try {
        if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
          (getObjectProperty(___arguments.context.pageData, 'currentOrder'))['fulfillments'] = [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })];
          order = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': (getObjectProperty(___arguments.context.pageData, 'currentOrder')) })), 'order'));
        } else {
          order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'fulfillments': [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })] })))), 'order'));
        }
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': (getObjectProperty(order, 'total_money.amount')),'source_id': ((getObjectProperty(order, 'total_money.amount')) ? (getObjectProperty(___arguments.context.dataModel, 'id')) : 'CASH'),'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'tip': ((getObjectProperty(___arguments.context.pageData, 'tips')) * 100),'location_id': locationId })), 'payment'));
        (getObjectProperty(order, 'total_tip_money'))['amount'] = ((getObjectProperty(___arguments.context.pageData, 'tips')) ? (getObjectProperty(___arguments.context.pageData, 'tips')) * 100 : 0);
        await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.pageData, 'imageList')));
        order['totalMoneyStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((((getObjectProperty(order, 'total_money.amount')) + (getObjectProperty(order, 'total_tip_money.amount'))) / 100)))));
        savedOrder = (await Backendless.Data.of('Order').deepSave( ({ 'squareId': (getObjectProperty(order, 'id')),'item': (await Promise.all(cart.map(async item => {; return ({ 'item': ({ 'objectId': (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')) }),'selectedModifier': (getObjectProperty(item, 'selectedModifiers')),'selectedVariation': (getObjectProperty(item, 'selectedVariationId')) });}))),'usedBonus': 0,'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'method': 'Card','squareId': (getObjectProperty(payment, 'id')),'type': 'Order' }),'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'activeLocation.objectId')) }),'title': (getObjectProperty(order, 'ticket_name')) }) ));
        ;(async () => {
            await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'order', [savedOrder]);

        })();
        order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
        order['cart'] = cart;
        var k_list = (getObjectProperty(order, 'line_items'));
        for (var k_index in k_list) {
          k = k_list[k_index];
          if (getObjectProperty(k, 'modifiers')) {
            cartItem = (await (async function(list, item) {
            	return list.find(cartItem => {
            	  const cartModifiers = Object.values(cartItem.selectedModifiers).map(item => item[0].catalog_object_id)
            	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

            	  if (cartModifiers.length === modifiers.length) {
            	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
            	    return cartItem.selectedVariationId === item.catalog_object_id && matches.length === 0
            	  }

            	})
            })((getObjectProperty(order, 'cart')), k));
          } else {
            cartItem = (await (async function(list, id) {
            	return list.find(item => {
            	  console.log(`item id: ${item.selectedVariationId}`)
            	  console.log(`compare id: ${id}`)
            	  if (item.selectedModifiers) {
            	    return item.selectedVariationId === id && Object.keys(item.selectedModifiers).length === 0
            	  } else {
            	    return item.selectedVariationId === id
            	  }

            	})
            })((getObjectProperty(order, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
          }
          k['name'] = (getObjectProperty(cartItem, 'name'));
          k['id'] = (getObjectProperty(cartItem, 'Id'));
          k['image'] = (getObjectProperty(cartItem, 'image'));
        }
        ___arguments.context.pageData['previousOrder'] = order;
        (getObjectProperty(___arguments.context.pageData, 'ordersList')).unshift(order);
        if ((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')) && ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.pageData, order);
        }
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        ___arguments.context.pageData['cart'] = [];
        delete ___arguments.context.pageData['reward'];
        delete ___arguments.context.pageData['currentOrder'];
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('reward');
        localStorage.removeItem('cart');

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
          await (async function() {
          	alert('Card declined')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
          await (async function() {
          	alert('Card declined due to insufficient funds')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
          await (async function() {
          	alert('Card declined due to transaction limit')
          })();
          await saveOrder();
        } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
          await (async function() {
          	alert('Card expired')
          })();
          await saveOrder();
        }
        if (((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) || ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) || (getObjectProperty(error, 'errors')) && ((getObjectProperty(((getObjectProperty(error, 'errors'))[0]), 'code')) == 'GENERIC_DECLINE' || (getObjectProperty(((getObjectProperty(error, 'errors'))[0]), 'code')) == 'TRANSACTION_LIMIT')) {
          await (async function() {
          	alert('Card declined')
          })();
        }
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/4e0550055f664ebeb1d5cf6e90bc8f91/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var taxAmount, tax, sum, j, item, discount, quantity, price, selectedVariation, modifiersSum, modifierPrices, selectedModifiers, modifiersNamesStr, modifiersNames, modifier, modifierList, variations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  modifierList = (getObjectProperty(___arguments.context.dataModel, 'modifiers'));
  variations = (getObjectProperty(___arguments.context.dataModel, 'variations'));
  selectedVariation = variations[(((variations.map(item => item['id'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'selectedVariationId')) + 1) - 1)];
  if (modifierList) {
    modifier = [];
    var j_list = (Object.keys((getObjectProperty(___arguments.context.dataModel, 'selectedModifiers'))));
    for (var j_index in j_list) {
      j = j_list[j_index];
      modifier = (modifier.concat((getObjectProperty((getObjectProperty((modifierList[(((modifierList.map(item => item['id'])).indexOf(j) + 1) - 1)]), 'modifier_list_data')), 'modifiers'))));
    }
    selectedModifiers = (await asyncListFilter(modifier, async (item) => {


     return (((getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'orderData')), 'modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    modifiersNames = ((selectedModifiers.map(item => item['modifier_data'])).map(item => item['name']));
  } else {
    modifiersNames = [];
  }
  modifiersNamesStr = !modifiersNames.length ? (getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'name')) : [(getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'name')),', ',modifiersNames.join(', ')].join('');
  ___arguments.context.dataModel['modifiersStr'] = modifiersNamesStr;
  ___arguments.context.dataModel['itemPrice'] = 0;
  if (selectedModifiers) {
    modifierPrices = (await asyncListFilter(((selectedModifiers.map(item => item['modifier_data'])).map(item => item['price_money'])), async (item) => {


     return item;
    }));
  } else {
    modifierPrices = [];
  }
  modifiersSum = !modifierPrices.length ? 0 : (modifierPrices.map(item => item['amount'])).reduce(function(x, y) {return x + y;});
  price = modifiersSum + (getObjectProperty((getObjectProperty((getObjectProperty(selectedVariation, 'item_variation_data')), 'price_money')), 'amount'));
  ___arguments.context.dataModel['itemPrice'] = price;
  quantity = (Number((getObjectProperty(___arguments.context.dataModel, 'quantity'))));
  price = (getObjectProperty(___arguments.context.dataModel, 'itemPrice'));
  discount = (getObjectProperty(___arguments.context.dataModel, 'discount'));
  sum = price * quantity - discount;
  ___arguments.context.dataModel['sum'] = sum;
  ___arguments.context.dataModel['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
  taxAmount = 0;
  var j_list2 = (getObjectProperty(___arguments.context.dataModel, 'item_data.tax_ids'));
  for (var j_index2 in j_list2) {
    j = j_list2[j_index2];
    if (((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


     return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
    })).map(item => item['id'])).includes(j)) {
      tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


       return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
      })), j, 'id'));
      taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
    }
  }
  ___arguments.context.dataModel['taxAmount'] = taxAmount;

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/e5330f6b175ab5996fb2d31cc07b83ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, cart, newCart, modifier, item, updatedOrder, fieldsToClear, j, order, rewardsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['loading'] = true;
  cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
  order = (getObjectProperty(___arguments.context.pageData, 'currentOrder'));
  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) {
      var j_list = subsequenceFromEndLast((getObjectProperty(order, 'rewards')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice')) - 1));
      for (var j_index in j_list) {
        j = j_list[j_index];
        await Backendless.Request.delete(`${Backendless.appPath}/services/Square/delete-royalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(j, 'id'))));
        rewardsList = (getObjectProperty(___arguments.context.pageData, 'rewardsList'));
        addItemToList(rewardsList, '');
        ___arguments.context.pageData['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
      }
      order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
      ___arguments.context.pageData['currentOrder'] = order;
    }
    if (order) {
      if (cart.length == 1) {
        ___arguments.context.pageData['screen'] = 'menu';
        ___arguments.context.pageData['showCategory'] = true;
        ___arguments.context.pageData['showCatalog'] = false;
        ___arguments.context.pageData['showItem'] = false;
        if (!updatedOrder) {
          updatedOrder = order;
        }
        ;(async () => {
            if (updatedOrder) {
            updatedOrder['state'] = 'CANCELED';
            await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(updatedOrder, 'location_id')),'state': (getObjectProperty(updatedOrder, 'state')),'version': (getObjectProperty(updatedOrder, 'version')),'id': (getObjectProperty(updatedOrder, 'id')) }) });
          }

        })();
        delete ___arguments.context.pageData['currentOrder'];
        delete ___arguments.context.pageData['tips'];
        localStorage.removeItem('cart');
        localStorage.removeItem('currentOrder');
      } else {
        fieldsToClear = [];
        if (!updatedOrder) {
          updatedOrder = order;
        }
        var j_list2 = (getObjectProperty(updatedOrder, 'line_items'));
        for (var j_index2 in j_list2) {
          j = j_list2[j_index2];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            addItemToList(fieldsToClear, j);
          }
        }
        updatedOrder = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(updatedOrder, 'location_id')),'line_items': (getObjectProperty(updatedOrder, 'line_items')),'version': (getObjectProperty(updatedOrder, 'version')),'id': (getObjectProperty(updatedOrder, 'id')) }),'fields_to_clear': (!fieldsToClear.length ? null : (await Promise.all(fieldsToClear.map(async item => {; return (['line_items[',(getObjectProperty(item, 'uid')),']'].join(''));})))) })), 'order'));
        ___arguments.context.pageData['currentOrder'] = updatedOrder;
        localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
      }
    }
    newCart = (await asyncListFilter(cart, async (item) => {


     return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'id')) == (getObjectProperty(item, 'id')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) == (getObjectProperty(item, 'selectedVariationId')) && (Object.keys((getObjectProperty(item, 'selectedModifiers')))).length == (Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedModifiers')))).length && (!(getObjectProperty(item, 'orderData.modifiers')).length || !(await asyncListFilter((getObjectProperty(item, 'orderData.modifiers')), async (modifier) => {


     return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(modifier, 'catalog_object_id')))));
    })).length)));
    }));
    if (!newCart.length) {
      ___arguments.context.pageData['screen'] = 'menu';
      ___arguments.context.pageData['showCategory'] = true;
      ___arguments.context.pageData['showCatalog'] = false;
      ___arguments.context.pageData['showItem'] = false;
      ___arguments.context.pageData['cart'] = [];
      localStorage.removeItem('cart');
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': true,'showCatalog': false,'showItem': false }));
    } else {
      ___arguments.context.pageData['cart'] = [];
      ___arguments.context.pageData['cart'] = newCart;
      await new Promise(r => setTimeout(r, 1 || 0));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2cf3f17ed1a906c435ce5524de6aad8e', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2cf3f17ed1a906c435ce5524de6aad8e', true);
      localStorage.setItem('cart', JSON.stringify(cart));
    }
    ___arguments.context.pageData['loading'] = false;

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c7854405dad8cca5aa1baea4e2daf151/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tips')) == ___arguments.context.dataModel ? ['checkout__tips_item', 'active'] : ['checkout__tips_item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'tips')) == ___arguments.context.dataModel) {
    delete ___arguments.context.pageData['tips'];
  } else {
    ___arguments.context.pageData['tips'] = ___arguments.context.dataModel;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e93102dfa124a06aedc14d9fa929cdd', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e93102dfa124a06aedc14d9fa929cdd', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/605e7dad916c5d242663f31b703ce48c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tips')) == ___arguments.context.dataModel ? ['checkout__tips_item', 'active'] : ['checkout__tips_item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'tips')) {
    delete ___arguments.context.pageData['tips'];
  } else {
    ___arguments.context.pageData['tips'] = ___arguments.context.dataModel;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9c8a3a89690df3fe56f95415fbddfd4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'location','locationView': 'list' }));
  ___arguments.context.pageData['screen'] = 'location';
  ___arguments.context.pageData['locationView'] = 'list';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/212588fb0d08a761fd63fcf58808eec2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return (String('$') + String(___arguments.context.getComponentDataStoreByUid('605e7dad916c5d242663f31b703ce48c')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b6a6fc81879fc432cc3eb184a9feaa15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var price;



  return (String('$') + String(___arguments.context.getComponentDataStoreByUid('c7854405dad8cca5aa1baea4e2daf151')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b30888b1e6d7a6abb5ca076fb57f20ce/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'home' ? '/img/icons/home_active.svg' : '/img/icons/home.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/6433375980bbcd83241a310e5345472e/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'menu' ? '/img/icons/menu_active.svg' : '/img/icons/menu.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/54feba53dae81742ff71b3538628464c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'location' ? '/img/icons/location_active.svg' : '/img/icons/location.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b5fd53bb7142a8b4c283186e11245c31/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'settings' ? '/img/icons/profile_active.svg' : '/img/icons/profile.svg') : '/img/icons/login.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/6593c4fa1d764bf2c1b4e27277b6e144/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList, nonce;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    try {
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('REQUEST_SQUARE', ({ 'locationId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'location')) ? (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'location')) : (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) }))), 'nonce'));
      if (nonce) {
        card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'customerId')),'source_id': nonce })), 'card'));
        cardsList = (getObjectProperty(___arguments.context.pageData, 'cardsList'));
        if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
          await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        } else {
          addItemToList((getObjectProperty(___arguments.context.pageData, 'cardsList')), card);
        }
        delete ___arguments.context.pageData['cardToken'];
      }

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')).indexOf('INVALID_CARD_DATA') !== -1) {
        ___arguments.context.pageData['loading'] = false;
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f3b18ec7cedffa75c729dbb86ab41e', true);
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'addCard' }));
    ___arguments.context.pageData['screen'] = 'settings';
    ___arguments.context.pageData['tab'] = 'addCard';
    ___arguments.context.pageData['loading'] = true;
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2d2fdbf56685771bcc07fb7556e92f06', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d3c25a345abfcb2c0869af6bb40a6358/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList, nonce;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    try {
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('REQUEST_SQUARE', ({ 'locationId': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) }))), 'nonce'));
      if (nonce) {
        ___arguments.context.pageData['loading'] = true;
        card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'customerId')),'source_id': nonce })), 'card'));
        cardsList = (getObjectProperty(___arguments.context.pageData, 'cardsList'));
        if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
          await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        } else {
          addItemToList((getObjectProperty(___arguments.context.pageData, 'cardsList')), card);
        }
        delete ___arguments.context.pageData['cardToken'];
      }

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')).indexOf('INVALID_CARD_DATA') !== -1) {
        ___arguments.context.pageData['loading'] = false;
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f3b18ec7cedffa75c729dbb86ab41e', true);
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'addCard' }));
    ___arguments.context.pageData['tab'] = 'addCard';
    ___arguments.context.pageData['loading'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2d2fdbf56685771bcc07fb7556e92f06', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9d6e216bb0ebe6e1ead3569a66970b56/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('24695d18e2b3c574dbb5282d380ef102', false);
    while (!(getObjectProperty(___arguments.context.pageData, 'cardToken'))) {
      await new Promise(r => setTimeout(r, 1000 || 0));
    }
    try {
      card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'customerId')),'source_id': (getObjectProperty(___arguments.context.pageData, 'cardToken')) })), 'card'));
      cardsList = (getObjectProperty(___arguments.context.pageData, 'cardsList'));
      if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('24695d18e2b3c574dbb5282d380ef102', true);
      } else {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'cardsList')), card);
        await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'cards' }));
        ___arguments.context.pageData['tab'] = 'cards';
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2d2fdbf56685771bcc07fb7556e92f06', false);
      }
      delete ___arguments.context.pageData['cardToken'];

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/05eb4a31e45cd44543e8a4e3252ba967/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, modifierNames, modifiers, image, itemImages;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    ___arguments.context.pageData['historyObjectId'] = '';
  } else {
    ___arguments.context.pageData['historyObjectId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
    itemImages = (getObjectProperty(___arguments.context.pageData, 'imageList'));
    var j_list = (getObjectProperty(___arguments.context.dataModel, 'line_items'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (!(getObjectProperty(j, 'image'))) {
        if (!!itemImages.length) {
          image = itemImages[(((itemImages.map(item => item['name'])).indexOf(getObjectProperty(j, 'name')) + 1) - 1)];
        }
        j['image'] = (image ? (getObjectProperty(image, 'image')) : '/img/no-img.png');
      }
      modifiers = (getObjectProperty(j, 'modifiers'));
      if (modifiers) {
        modifierNames = (modifiers.map(item => item['name'])).join(', ');
      }
      j['modifiersString'] = (modifierNames ? [(getObjectProperty(j, 'variation_name')),', ',modifierNames].join('') : (getObjectProperty(j, 'variation_name')));
      j['priceString'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty((getObjectProperty(j, 'total_money')), 'amount')) / 100)))));
      await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](___arguments.context.dataModel, null);
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/286168a664d5ab5dae2fc56d9e8ec9a2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('05eb4a31e45cd44543e8a4e3252ba967'), 'id')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/5c99d95fa08d7c7ce8753ee6de853b1f/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  onIconAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('05eb4a31e45cd44543e8a4e3252ba967'), 'id')) ? 'keyboard arrow down' : 'keyboard arrow up')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing_backup/components/9c639efe72c6e3d7d351212318de1eaa/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/c4bfd5817d64fb1bb003c332177aa957/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, payment, user, wallet, locationId, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': (getObjectProperty(___arguments.context.dataModel, 'id')),'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      wallet = (getObjectProperty(___arguments.context.pageData, 'wallet'));
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      ;(async () => {
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
        ___arguments.context.pageData['walletInput'] = '';
        await new Promise(r => setTimeout(r, 2000 || 0));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

      })();
      ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      await Backendless.UserService.update( new Backendless.User(user) );
      ;(async () => {
          await Backendless.Data.of('Order').deepSave( ({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Card','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' }) );

      })();

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c93d938a14d4d63c474d2f51631cba06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['screen'] = 'settings';
  ___arguments.context.pageData['tab'] = 'history';
  ___arguments.context.pageData['historyObjectId'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/e74af199605f6226c171893e55f14e77/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fillOptions'] = [10, 20, 30];
  ___arguments.context.pageData['walletAmount'] = ((getObjectProperty(___arguments.context.pageData, 'fillOptions'))[0]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/1cf68d89931a01833ed8411bf29e86d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'settings') {
    if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'main') {
      return true;
    }
  } else {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/c37704cb7fbff82fba7236b175823ff4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/aeeeea1ff2c6c13294be45e58d7eec1b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('c64fbb8fa8266712cedd89f6270f346d')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/c64fbb8fa8266712cedd89f6270f346d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'walletAmount')) == ___arguments.context.dataModel ? ['fill__item', 'active'] : ['fill__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['walletAmount'] = ___arguments.context.dataModel;
  await (async function(val) {
  	const el = document.querySelector(`.wheel-${val}`)
  	el.scrollIntoView({block: "center", behavior: "smooth"})
  })(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b96d66299d4ae04f1f544286649c88b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var tax, tips, cart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
  tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
  tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
  if ((Array.isArray(cart)) && !!cart.length && (getObjectProperty(___arguments.context.pageData, 'wallet')) && (getObjectProperty(___arguments.context.pageData, 'wallet.balance_money.amount')) >= (await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d'](cart)) + (tips ? tips * 100 : 0) + (tax ? tax * 100 : 0)) {
    return ['checkout__payment_button', 'button'];
  } else {
    return ['checkout__payment_button', 'button', 'disabled'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, locationId, cartItem, k, cart, savedOrder, user, usedBalance, bonusBalance, payment, orderCart, totalAmount, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && !(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b96d66299d4ae04f1f544286649c88b9')).includes('disabled'))) {
    ___arguments.context.pageData['loading'] = true;
    if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))))) {
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
      locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
      try {
        if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
          (getObjectProperty(___arguments.context.pageData, 'currentOrder'))['fulfillments'] = [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })];
          order = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': (getObjectProperty(___arguments.context.pageData, 'currentOrder')) })), 'order'));
          ___arguments.context.pageData['currentOrder'] = order;
          localStorage.setItem('currentOrder', JSON.stringify(order));
        } else {
          order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'fulfillments': [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })] })))), 'order'));
        }
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': (getObjectProperty(order, 'total_money.amount')),'source_id': ((getObjectProperty(order, 'total_money.amount')) ? (getObjectProperty(user, 'walletId')) : 'CASH'),'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'tip': ((getObjectProperty(___arguments.context.pageData, 'tips')) * 100),'location_id': locationId })), 'payment'));
        (getObjectProperty(order, 'total_tip_money'))['amount'] = ((getObjectProperty(___arguments.context.pageData, 'tips')) ? (getObjectProperty(___arguments.context.pageData, 'tips')) * 100 : 0);
        await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.pageData, 'imageList')));
        totalAmount = (getObjectProperty(order, 'total_money.amount')) + (getObjectProperty(order, 'total_tip_money.amount'));
        order['totalMoneyStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((totalAmount / 100)))));
        orderCart = (await Promise.all(cart.map(async item => {; return ({ 'item': ({ 'objectId': (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')) }),'selectedModifier': (getObjectProperty(item, 'selectedModifiers')),'selectedVariation': (getObjectProperty(item, 'selectedVariationId')) });})));
        bonusBalance = (getObjectProperty(user, 'bonusBalance'));
        usedBalance = bonusBalance > totalAmount ? totalAmount : bonusBalance;
        savedOrder = (await Backendless.Data.of('Order').deepSave( ({ 'squareId': (getObjectProperty(order, 'id')),'item': orderCart,'usedBonus': usedBalance,'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'method': 'Gift Card','squareId': (getObjectProperty(payment, 'id')),'type': 'Order' }),'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'activeLocation.objectId')) }),'title': (getObjectProperty(order, 'ticket_name')) }) ));
        user['bonusBalance'] = (bonusBalance - usedBalance);
        ;(async () => {
            await Backendless.UserService.update( new Backendless.User(user) );
          await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'order', [savedOrder]);

        })();
        order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
        order['cart'] = cart;
        var k_list = (getObjectProperty(order, 'line_items'));
        for (var k_index in k_list) {
          k = k_list[k_index];
          if (getObjectProperty(k, 'modifiers')) {
            cartItem = (await (async function(list, item) {
            	return list.find(cartItem => {
            	  const cartModifiers = Object.values(cartItem.selectedModifiers).map(item => item[0].catalog_object_id)
            	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

            	  if (cartModifiers.length === modifiers.length) {
            	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
            	    return cartItem.selectedVariationId === item.catalog_object_id && matches.length === 0
            	  }

            	})
            })((getObjectProperty(order, 'cart')), k));
          } else {
            cartItem = (await (async function(list, id) {
            	return list.find(item => {
            	  if (item.selectedModifiers) {
            	    return item.selectedVariationId === id && Object.keys(item.selectedModifiers).length === 0
            	  } else {
            	    return item.selectedVariationId === id
            	  }

            	})
            })((getObjectProperty(order, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
          }
          k['name'] = (getObjectProperty(cartItem, 'name'));
          k['id'] = (getObjectProperty(cartItem, 'Id'));
          k['image'] = (getObjectProperty(cartItem, 'image'));
        }
        ___arguments.context.pageData['previousOrder'] = order;
        (getObjectProperty(___arguments.context.pageData, 'ordersList')).unshift(order);
        if ((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')) && ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.pageData, order);
        }
        ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((((getObjectProperty(___arguments.context.pageData, 'wallet.balance_money.amount')) - (getObjectProperty(order, 'total_money.amount'))) / 100)));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        ___arguments.context.pageData['cart'] = [];
        delete ___arguments.context.pageData['reward'];
        delete ___arguments.context.pageData['currentOrder'];
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('reward');
        localStorage.removeItem('cart');
        ___arguments.context.pageData['loading'] = false;

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      } finally {
        ___arguments.context.pageData['loading'] = false;

      }
    } else {
      ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c779f1820bc7edb9afe712575d9b791f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newFav, userFavorite, favoriteList, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'savingFav'))) {
    ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['savingFav'] = true;
    favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId')))) {
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId'))));
      ;(async () => {
          try {
          await Backendless.Data.of('UserFavorite').remove( favItemId );

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        newFav = (await Backendless.Data.of('UserFavorite').deepSave( ({ 'item': ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId')) }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'selectedVariationId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'selectedModifiers')) }) ));
        newFav['favId'] = (getObjectProperty(newFav, 'item.objectId'));
        newFav['item_data'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'item_data'));
        newFav['id'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'id'));
        newFav['favVariation'] = (getObjectProperty(newFav, 'variation'));
        newFav['favModifier'] = (getObjectProperty(newFav, 'modifier'));
        newFav['variation'] = (getObjectProperty(newFav, 'item.variation'));
        newFav['modifier'] = (getObjectProperty(newFav, 'item.modifier'));
        newFav['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'name'));
        newFav['image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'image'));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);
        await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'userFavorite', [newFav]);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      }
    }
    await new Promise(r => setTimeout(r, 300 || 0));
    ___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645')['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), 'objectId'))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing_backup/components/505df5fafbaff2d24456d8c146b5c645/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  console.log(___arguments.context.dataModel);
  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
    ___arguments.context.dataModel['showVariation'] = true;
  }
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'lastOrder': true }));
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'lastOrder': true }));

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var j, order;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  while (!(getObjectProperty(___arguments.context.pageData, 'imageList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'imageList')));
  }
  delete ___arguments.context.dataModel['discount'];
  delete ___arguments.context.dataModel['itemPrice'];
  delete ___arguments.context.dataModel['sum'];
  delete ___arguments.context.dataModel['taxAmount'];
  delete ___arguments.context.dataModel['totalPrice'];
  delete ___arguments.context.dataModel['quantity'];

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/dc77a39e03e152e54fb6b3acc9878074/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'checkoutTab')) == 'cart')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/0df76855ac4adeb84048044ade3ea39a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'checkoutTab')) == 'qr')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/c13605b3de5da77275d391f6fddecbbd/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var error, rewardsList, loyaltyProgram, rewardPointsNeeded, balance, remainder, loyaltyAcc, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await Backendless.UserService.isValidLogin()) {
    try {
      if (!(getObjectProperty(___arguments.context.pageData, 'user'))) {
        while (!(getObjectProperty(___arguments.context.pageData, 'user'))) {
          await new Promise(r => setTimeout(r, 100 || 0));
        }
      }
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      if ((getObjectProperty(user, 'email')) && (getObjectProperty(user, 'name'))) {
        if (!(getObjectProperty(___arguments.context.pageData, 'loyaltyAcc'))) {
          loyaltyAcc = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/getLoyaltyAccount`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'account_id': JSON.stringify((getObjectProperty(user, 'loyaltyId'))) }).send()), 'loyalty_account'));
          ___arguments.context.pageData['loyaltyAcc'] = loyaltyAcc;
        } else {
          loyaltyAcc = (getObjectProperty(___arguments.context.pageData, 'loyaltyAcc'));
        }
        if (!(getObjectProperty(___arguments.context.pageData, 'loyaltyProgram'))) {
          loyaltyProgram = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-loyalty-program`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'program'));
          ___arguments.context.pageData['loyaltyProgram'] = loyaltyProgram;
        } else {
          loyaltyProgram = (getObjectProperty(___arguments.context.pageData, 'loyaltyProgram'));
        }
        rewardPointsNeeded = (getObjectProperty(((getObjectProperty(loyaltyProgram, 'reward_tiers'))[0]), 'points'));
        ___arguments.context.pageData['rewardPointsNeeded'] = rewardPointsNeeded;
        ___arguments.context.pageData['rewardCategories'] = (getObjectProperty(((getObjectProperty(loyaltyProgram, 'reward_tiers'))[0]), 'definition.catalog_object_ids'));
        balance = (getObjectProperty(loyaltyAcc, 'balance'));
        remainder = balance % rewardPointsNeeded;
        rewardsList = [];
        ___arguments.context.pageData['loyaltyStr'] = (String(rewardPointsNeeded - remainder) + String(' drinks'));
        ___arguments.context.pageData['loyaltyImgUrl'] = (['/img/reward/gem',remainder,'.svg'].join(''));
        var repeat_end = Math.floor(balance / rewardPointsNeeded);
        for (var count = 0; count < repeat_end; count++) {
          addItemToList(rewardsList, '');
        }
        ___arguments.context.pageData['rewardsList'] = rewardsList;
        ___arguments.context.pageData['loyaltyProgram'] = loyaltyProgram;
        ___arguments.context.pageData['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
  } else {
    ___arguments.context.pageData['loyaltyImgUrl'] = (['/img/reward/gem',0,'.svg'].join(''));
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'rewards' }));
    ___arguments.context.pageData['screen'] = 'settings';
    ___arguments.context.pageData['tab'] = 'rewards';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b6030c3d84dcf24a11c3ddf1e8df902c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, cart, updatedOrder, taxAmount, rewardItem, tax, sum, j, taxes, discount, quantity, price, k, item, rewardsList, order, user, reward, locationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && !(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b6030c3d84dcf24a11c3ddf1e8df902c')).includes('disabled'))) {
    ___arguments.context.pageData['loading'] = true;
    locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
    user = (getObjectProperty(___arguments.context.pageData, 'user'));
    cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
    try {
      if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
        order = (getObjectProperty(___arguments.context.pageData, 'currentOrder'));
        if ((getObjectProperty(order, 'rewards')) && (await asyncListFilter((getObjectProperty(order, 'line_items')), async (item) => {


         return ((getObjectProperty(item, 'quantity')) != '0');
        })).length == (getObjectProperty(order, 'rewards')).length) {
          ___arguments.context.pageData['loading'] = false;
          return ;
        }
      } else {
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }) })))), 'order'));
      }
      reward = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-loyalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'loyalty_account_id': (getObjectProperty(user, 'loyaltyId')),'reward_tier_id': (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram.reward_tiers'))[0]), 'id')),'order_id': (getObjectProperty(order, 'id')) })), 'reward'));
      updatedOrder = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
      rewardsList = (getObjectProperty(___arguments.context.pageData, 'rewardsList'));
      rewardsList.pop();
      ___arguments.context.pageData['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
      ___arguments.context.pageData['currentOrder'] = updatedOrder;
      taxes = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


       return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
      }));
      for (var j_index in cart) {
        j = cart[j_index];
        j['discount'] = 0;
      }
      var j_list2 = (getObjectProperty(updatedOrder, 'line_items'));
      for (var j_index3 in j_list2) {
        j = j_list2[j_index3];
        if (getObjectProperty(j, 'applied_discounts')) {
          for (var k_index in cart) {
            k = cart[k_index];
            if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


             return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
            })).length)) {
              rewardItem = k;
              break;
            }
          }
          rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
          quantity = (Number((getObjectProperty(rewardItem, 'quantity'))));
          discount = (getObjectProperty(rewardItem, 'discount'));
          price = (getObjectProperty(rewardItem, 'itemPrice'));
          sum = price * quantity - discount;
          rewardItem['sum'] = sum;
          rewardItem['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
          taxAmount = 0;
          var j_list = (getObjectProperty(rewardItem, 'item_data.tax_ids'));
          for (var j_index2 in j_list) {
            j = j_list[j_index2];
            if ((taxes.map(item => item['id'])).includes(j)) {
              tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe'](taxes, j, 'id'));
              taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
            }
          }
          rewardItem['taxAmount'] = taxAmount;
        }
      }
      localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
      localStorage.setItem('cart', JSON.stringify(cart));

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var item, loyaltyItems, cart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'rewardsList')).length ? ['checkout__payment_button', 'button', 'disabled'] : ['checkout__payment_button', 'button'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3c54c641505a7946686cae31e7de9528/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'checkout','checkoutTab': 'cart' }));
  ___arguments.context.pageData['screen'] = 'checkout';
  ___arguments.context.pageData['checkoutTab'] = 'cart';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cart')) {
    if (!!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/4d65e03a58363eb8a6ca9a48f4369fb8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, favoriteList, userFavorite;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  favoriteList = (getObjectProperty(___arguments.context.pageData, 'favoriteList'));
  userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite'));
  if (userFavorite.includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'id')))) {
    removeItemInList(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'id')), '');
    removeItemInList(favoriteList, ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'id');
  } else {
    addItemToList(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'id')));
    addItemToList(favoriteList, ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'));
  }
  try {
    await Backendless.UserService.update( new Backendless.User((getObjectProperty(___arguments.context.pageData, 'user'))) );

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'user')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')) && ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'favorite')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'id'))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    
  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8fd95508501ca7f7e396a8fc90191a76/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.name'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')).length > 1) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['showVariation'] = true;
  }
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'todaysOffer': true }));
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')))));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'todaysOffer': true }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'useImage')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/821a937b393a2bcb49c526c759446c83/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var coords, item, location2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.newValue) {
    if (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      ___arguments.context.pageData['coordinates'] = (await Promise.all(((getObjectProperty(___arguments.context.pageData, 'locationsList')).map(item => item['coordinates'])).map(async item => {; return ({ 'lat': (getObjectProperty(item, 'latitude')),'lng': (getObjectProperty(item, 'longitude')) });})));
    }
    if (getObjectProperty(___arguments.context.pageData, 'user.location')) {
      location2 = (await (async function(list, id) {
      	return list.find(item => item.id === id)
      })((getObjectProperty(___arguments.context.pageData, 'locationsList')), (getObjectProperty(___arguments.context.pageData, 'user.location'))));
      if (location2) {
        coords = (getObjectProperty(location2, 'coordinates'));
      }
    }
    ___arguments.context.pageData['googleMap'] = (await (async function(userPoint, placeholder) {
    	let map

    	function initMap() {
    	  map = new google.maps.Map(document.getElementById("map"), {
    	    zoom: 16,
    	    center: userPoint || placeholder,
    	    disableDefaultUI: true,
    	  });

    	  if(userPoint) {
    	    const userMarker = new google.maps.Marker({
    	      position: userPoint,
    	      icon: 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-user.png',
    	      map: map
    	    });
    	  }
    	}

    	initMap();

    	return map
    })((getObjectProperty(___arguments.context.pageData, 'mapCenter')), (coords ? ({ 'lat': (getObjectProperty(coords, 'latitude')),'lng': (getObjectProperty(coords, 'longitude')) }) : (getObjectProperty(___arguments.context.pageData, 'coordinates'))[0])));
    await (async function(pageData, map, userPoint, points) {
    	const img = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon.png';
    	const imgActive = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-active.png';

    	pageData.markers = [];

    	for (let i = 0; i < points.length; i++) {
    	  const position = new google.maps.LatLng(points[i].lat, points[i].lng)

    	  const marker = new google.maps.Marker({
    	    position: position,
    	    icon: img,
    	  });
    	  pageData.markers.push(marker)

    	  marker.addListener('click', setMarkers);
    	}

    	addMarkersToMap(pageData.markers, map);

    	function addMarkersToMap (markersList, map) {
    	  for (i = 0; i < markersList.length; i++) {
    	    markersList[i].setMap(map);
    	  }
    	}

    	function setMarkers () {
    	  const markerPosition = this.getPosition()
    	  const directionsService = new google.maps.DirectionsService();
    	  let directionsRenderer;

    	  if (pageData.directionsRenderer) {
    	    directionsRenderer = pageData.directionsRenderer;
    	  } else {
    	    directionsRenderer = new google.maps.DirectionsRenderer({
    	      suppressMarkers: true
    	    });
    	    pageData.directionsRenderer = directionsRenderer;
    	  }

    	  directionsRenderer.setMap(map);

    	  calcRoute();

    	  addMarkersToMap(pageData.markers, null);

    	  pageData.markers = [];
    	  const currentMarkerLat = markerPosition.lat()
    	  const currentMarkerLng = markerPosition.lng()

    	  const inactiveMarkers = points.filter(point => point.lat !== currentMarkerLat && point.lng !== currentMarkerLng)

    	  addMarkers(img, ...inactiveMarkers);
    	  addMarkers(imgActive, {
    	    lat: currentMarkerLat,
    	    lng: currentMarkerLng
    	  });

    	  addMarkersToMap(pageData.markers, map);

    	  const locationIndex = pageData.locationsList.findIndex(location => (
    	    location.coordinates.latitude === currentMarkerLat && location.coordinates.longitude === currentMarkerLng
    	  ));

    	  const locationListComponent = document.querySelector('.location__list')
    	  locationListComponent.scrollLeft = 336 * locationIndex;

    	  function calcRoute() {
    	    const start = new google.maps.LatLng(userPoint.lat, userPoint.lng);
    	    const end = markerPosition;
    	    const request = {
    	      origin: start,
    	      destination: end,
    	      travelMode: 'DRIVING'
    	    };

    	    directionsService.route(request, function(result, status) {
    	      if (status == 'OK') {
    	        directionsRenderer.setDirections(result);
    	      }
    	    });
    	  }

    	  function addMarkers (img, ...latlng) {
    	    for (let i = 0; i < latlng.length; i++) {
    	      const position = new google.maps.LatLng(latlng[i].lat, latlng[i].lng);

    	      const marker = new google.maps.Marker({
    	        position: position,
    	        icon: img,
    	      });

    	      pageData.markers.push(marker);

    	      marker.addListener('click', setMarkers);
    	    }
    	  }
    	}
    })(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'googleMap')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), (getObjectProperty(___arguments.context.pageData, 'coordinates')));
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/landing_backup/components/a9d8ddb1a21b097a742e7c756794c665/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('search');

  ;(function() {
    const callback = async () => {
        ___arguments.context.pageData['filteredLocationList'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'locationsList')), async (item) => {


     return ((((getObjectProperty(item, 'addressString')).toLowerCase()).indexOf((___arguments.value.toLowerCase())) !== -1) || (((getObjectProperty(item, 'name')).toLowerCase()).indexOf((___arguments.value.toLowerCase())) !== -1));
    }));

    };

    const timerId = 'search';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/f11891690ff873b51b65ccee75f3cdca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList, nonce;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    try {
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('REQUEST_SQUARE', ({ 'locationId': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) }))), 'nonce'));
      if (nonce) {
        ___arguments.context.pageData['loading'] = true;
        card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'user')), 'customerId')),'source_id': nonce })), 'card'));
        cardsList = (getObjectProperty(___arguments.context.pageData, 'cardsList'));
        if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
          await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        } else {
          addItemToList((getObjectProperty(___arguments.context.pageData, 'cardsList')), card);
        }
        delete ___arguments.context.pageData['cardToken'];
      }

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')).indexOf('INVALID_CARD_DATA') !== -1) {
        ___arguments.context.pageData['loading'] = false;
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f3b18ec7cedffa75c729dbb86ab41e', true);
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'addCard' }));
    ___arguments.context.pageData['tab'] = 'addCard';
    ___arguments.context.pageData['loading'] = true;
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2d2fdbf56685771bcc07fb7556e92f06', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6ffa30fe1e68894d1fa13fd74891a978/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/590d10da4903722d396964f77fc92dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'wallet' }));
  ___arguments.context.pageData['screen'] = 'settings';
  ___arguments.context.pageData['tab'] = 'wallet';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/201cc83dfd3e13e21e4d18c4b06edca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['stopPropagation'] = true;
    ___arguments.context.pageData['screen'] = 'location';
    ___arguments.context.pageData['locationView'] = 'list';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
    ___arguments.context.pageData['userPoint'] = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    while (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(___arguments.context.pageData, 'coordinates')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), ___arguments.context.pageData);
    while (!(getObjectProperty(___arguments.context.pageData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(___arguments.context.pageData, 'distanceList'));
    locationsList = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
    }
    ___arguments.context.pageData['stopPropagation'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/cbaf450f12f802318f7ae9da250df17b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/cd634cadd83c05335f86050c74be1fc9/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var url;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'checkout' && (getObjectProperty(___arguments.context.pageData, 'checkoutTab')) == 'cart') {
    url = '/img/icons/basket.svg';
  } else {
    url = '/img/icons/scan_icon.svg';
  }

  return url

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/1ba8104bda095a632ba93aade8c60f7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['filteredLocationList'] = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a9d8ddb1a21b097a742e7c756794c665'))['value'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/2cf3f17ed1a906c435ce5524de6aad8e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var item, j, tax, taxes, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((getObjectProperty(___arguments.context.pageData, 'cart')) && !!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
    taxes = [];
    var j_list = (getObjectProperty(___arguments.context.pageData, 'cart'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'taxAmount')) {
        addItemToList(taxes, (Number((getObjectProperty(j, 'taxAmount')))));
      }
    }
    if (!!taxes.length) {
      tax = taxes.reduce(function(x, y) {return x + y;}) / 100;
      ___arguments.context.pageData['tax'] = tax;
      return (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](tax))));
    } else {
      return (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
    }
  } else {
    return (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
  }

  return '$0.00'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/73967aa8a551b3ac9dc229990de9562e/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5'), 'id')) == (getObjectProperty(___arguments.context.pageData, 'activeLocation.id')) ? 'check' : 'keyboard arrow right')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing_backup/components/ce1545383274a3419f692ca59f3a5de5/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var address;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'activeLocation')) {
    address = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'address'));
    ___arguments.context.pageData['addressStr'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),' ',(getObjectProperty(address, 'administrative_district_level_1')),' ',(getObjectProperty(address, 'postal_code'))].join(''));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/523bd059dd5fd855f05abdc29bf3d227/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'previousOrder'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/c4919b8f07bbed643529e5120da7f8ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, quantity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  quantity = (Number((getObjectProperty(activeItem, 'quantity'))));
  if (quantity < 99) {
    activeItem['quantity'] = (String(quantity + 1));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9ddc1c5a420ac148e9f39d2930bd3d57/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, quantity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  quantity = (Number((getObjectProperty(activeItem, 'quantity'))));
  if (quantity > 1) {
    activeItem['quantity'] = (String(quantity - 1));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4c019201b3584aff819c4ac855c8ff3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, taxAmount, tax, sum, j, item, discount, quantity, price, rewardItem, k, cart, orderData, items, orderItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
  if (quantity < 99) {
    cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
    ___arguments.context.pageData['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['quantity'] = (String(quantity + 1));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))['quantity'] = (String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.quantity')))) + 1));
    try {
      if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
        items = (getObjectProperty(___arguments.context.pageData, 'currentOrder.line_items'));
        for (var j_index in items) {
          j = items[j_index];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            orderItem = j;
            break;
          }
        }
        if (orderItem) {
          orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) + 1));
        } else {
          orderData = (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))))));
          orderData['quantity'] = '1';
          addItemToList(items, orderData);
        }
        ___arguments.context.pageData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.location_id')),'line_items': (getObjectProperty(___arguments.context.pageData, 'currentOrder.line_items')),'version': (getObjectProperty(___arguments.context.pageData, 'currentOrder.version')),'id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.id')) }) })), 'order'));
        localStorage.setItem('order', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentOrder'))));
        for (var j_index2 in cart) {
          j = cart[j_index2];
          j['discount'] = 0;
        }
        var j_list2 = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentOrder')), 'line_items'));
        for (var j_index4 in j_list2) {
          j = j_list2[j_index4];
          for (var k_index in cart) {
            k = cart[k_index];
            if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


             return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
            })).length)) {
              rewardItem = k;
              break;
            }
          }
          if (getObjectProperty(j, 'applied_discounts')) {
            rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
          }
          quantity = (Number((getObjectProperty(rewardItem, 'quantity'))));
          discount = (getObjectProperty(rewardItem, 'discount'));
          price = (getObjectProperty(rewardItem, 'itemPrice'));
          sum = price * quantity - discount;
          rewardItem['sum'] = sum;
          rewardItem['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
          taxAmount = 0;
          var j_list = (getObjectProperty(rewardItem, 'item_data.tax_ids'));
          for (var j_index3 in j_list) {
            j = j_list[j_index3];
            if (((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


             return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
            })).map(item => item['id'])).includes(j)) {
              tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


               return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
              })), j, 'id'));
              taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
            }
          }
          rewardItem['taxAmount'] = taxAmount;
        }
      } else {
        quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
        discount = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount'));
        price = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'));
        sum = price * quantity - discount;
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['sum'] = sum;
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
        taxAmount = 0;
        var j_list3 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'item_data.tax_ids'));
        for (var j_index5 in j_list3) {
          j = j_list3[j_index5];
          if (((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


           return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
          })).map(item => item['id'])).includes(j)) {
            tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


             return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
            })), j, 'id'));
            taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
          }
        }
        ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['taxAmount'] = taxAmount;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    localStorage.setItem('cart', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'cart'))));
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/53fbcf73ab97355cd227388dcebce7aa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, taxAmount, tax, sum, j, item, discount, quantity, price, orderItem, items, rewardsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
  if (quantity > 1) {
    try {
      (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData'))['quantity'] = (String(quantity - 1));
      if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
        ___arguments.context.pageData['loading'] = true;
        if (quantity == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'))) {
          await Backendless.Request.delete(`${Backendless.appPath}/services/Square/delete-royalty-reward`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentOrder')), 'rewards')).slice(-1)[0]), 'id'))));
          rewardsList = (getObjectProperty(___arguments.context.pageData, 'rewardsList'));
          addItemToList(rewardsList, '');
          ___arguments.context.pageData['rewardsAmountStr'] = (String(rewardsList.length) + String(' free drinks'));
          ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['discount'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice')));
          ___arguments.context.pageData['currentOrder'] = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentOrder.id'))) }).send()), 'order'));
        }
        items = (getObjectProperty(___arguments.context.pageData, 'currentOrder.line_items'));
        for (var j_index in items) {
          j = items[j_index];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')) && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            orderItem = j;
            break;
          }
        }
        if (orderItem) {
          if ((getObjectProperty(orderItem, 'quantity')) == '1') {
            ;(async () => {
                ___arguments.context.pageData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.location_id')),'version': (getObjectProperty(___arguments.context.pageData, 'currentOrder.version')),'id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.id')) }),'fields_to_clear': [['line_items[',(getObjectProperty(orderItem, 'uid')),']'].join('')] })), 'order'));
              localStorage.setItem('order', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentOrder'))));

            })();
          } else {
            orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) - 1));
            ;(async () => {
                ___arguments.context.pageData['currentOrder'] = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.location_id')),'line_items': (getObjectProperty(___arguments.context.pageData, 'currentOrder.line_items')),'version': (getObjectProperty(___arguments.context.pageData, 'currentOrder.version')),'id': (getObjectProperty(___arguments.context.pageData, 'currentOrder.id')) }) })), 'order'));
              localStorage.setItem('order', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentOrder'))));

            })();
          }
        }
      }
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['quantity'] = (String(quantity - 1));
      localStorage.setItem('cart', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'cart'))));
      quantity = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'quantity'))));
      discount = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'discount'));
      price = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'itemPrice'));
      sum = price * quantity - discount;
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['sum'] = sum;
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['totalPrice'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((sum / 100)))));
      taxAmount = 0;
      var j_list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91'), 'item_data.tax_ids'));
      for (var j_index2 in j_list) {
        j = j_list[j_index2];
        if (((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


         return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
        })).map(item => item['id'])).includes(j)) {
          tax = (await BackendlessUI.Functions.Custom['fn_4b2f6a4582f49b7b93bc12c5ee18b8fe']((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'taxList')), async (item) => {


           return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(___arguments.context.pageData, 'activeLocation.id'))));
          })), j, 'id'));
          taxAmount = taxAmount + Math.round(sum * ((Number((getObjectProperty(tax, 'tax_data.percentage')))) / 100));
        }
      }
      ___arguments.context.getComponentDataStoreByUid('4e0550055f664ebeb1d5cf6e90bc8f91')['taxAmount'] = taxAmount;
      ___arguments.context.pageData['loading'] = false;

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/82a80af8c13c4bf8a4b20435829af919/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var updatedOrder, currentOrder, user, locationId, cart, item, modifier;


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'))))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeLocation')) && (getObjectProperty(___arguments.context.pageData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/6e26cbf82c87bac88455c939e4c644d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d'](___arguments.context.getComponentDataStoreByUid('ead4338b07f96f7bd9aa8ea508213107'), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'activeLocation'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/670e2565ecdf44a604b71bd6858371f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d'](___arguments.context.getComponentDataStoreByUid('505df5fafbaff2d24456d8c146b5c645'), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/60ca6af780f15cdd5d5441b5fb737c87/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var tips, tax, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'cart')) && !!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
    tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
    tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
    price = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d']((getObjectProperty(___arguments.context.pageData, 'cart')))) / 100)));
    ___arguments.context.pageData['totalSumStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))));
  } else {
    ___arguments.context.pageData['totalSumStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/0e93102dfa124a06aedc14d9fa929cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tips, tax, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'cart')) && !!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
    tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
    tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
    price = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d']((getObjectProperty(___arguments.context.pageData, 'cart')))) / 100)));
    return (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))));
  } else {
    return (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
  }

  return '$0.00'

  },
  /* handler:onContentAssignment */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var tips, tax, price;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.newValue) {
    if ((getObjectProperty(___arguments.context.pageData, 'cart')) && !!(getObjectProperty(___arguments.context.pageData, 'cart')).length) {
      tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
      tips = (getObjectProperty(___arguments.context.pageData, 'tips'));
      price = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d']((getObjectProperty(___arguments.context.pageData, 'cart')))) / 100)));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((Number(price)) + (tax ? tax : 0) + (tips ? tips : 0))))));
      return ;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](0))));
      return ;
    }
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0e93102dfa124a06aedc14d9fa929cdd'))['content'] = '$0.00';
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/landing_backup/components/a27c6542c4bdfc82c824ad62bf19c1a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var updatedOrder, currentOrder, user, locationId, cart, item, modifier, variation, currentItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  currentItem = (await (async function(list, condition) {
  	return list.find(item => item.squareId === condition)
  })((getObjectProperty(___arguments.context.pageData, 'chosenCategory.item')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'id'))));
  if (getObjectProperty(currentItem, 'modifier')) {
    ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(currentItem, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(currentItem, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'item_data.variations')), async (variation) => {


   return ((getObjectProperty(currentItem, 'variation')).includes((getObjectProperty(variation, 'id'))));
  }));
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'))))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/0af68e451c13fe3450285bb9fd0a7281/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var j, rewardItem, reward, rewardName, cart, k, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
  for (var k_index in cart) {
    k = cart[k_index];
    k['discount'] = 0;
  }
  if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
    var j_list = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentOrder')), 'line_items'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'applied_discounts')) {
        for (var k_index2 in cart) {
          k = cart[k_index2];
          if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


           return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
          })).length)) {
            rewardItem = k;
            break;
          }
        }
        rewardItem['discount'] = ((getObjectProperty(rewardItem, 'discount')) + (getObjectProperty(((getObjectProperty(j, 'applied_discounts'))[0]), 'applied_money.amount')));
      }
    }
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/landing_backup/components/e2b6380114ae5a4d3263f136eaa6f116/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'favoriteList')) && !!(getObjectProperty(___arguments.context.pageData, 'favoriteList')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d85bd7861d61fda3ad1a0d938d61f864/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'favoriteList')) && !!(getObjectProperty(___arguments.context.pageData, 'favoriteList')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d75b8edd820966e4803678fd352c9723/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'rewards' }));
    ___arguments.context.pageData['screen'] = 'settings';
    ___arguments.context.pageData['tab'] = 'rewards';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/2f8c054766b86519c17320f8ff7843d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'rewards' }));
    ___arguments.context.pageData['screen'] = 'settings';
    ___arguments.context.pageData['tab'] = 'rewards';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/338920334785619199555f3eec0a13c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['cardToDelete'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9c639efe72c6e3d7d351212318de1eaa'), 'id'));
  ___arguments.context.pageData['cardToDeleteStr'] = (['Remove card ...',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('9c639efe72c6e3d7d351212318de1eaa'), 'last_4')),' from the app?'].join(''));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/795eb3d1a3bc3cd831db83dd5fb37e2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/e57138310865bb9ddd64ffc1a34fb12f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['loading'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', false);
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'cardToDelete'))));
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'cardsList')), ({ 'id': (getObjectProperty(___arguments.context.pageData, 'cardToDelete')) }), 'id');

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/220a9b5c910bc52bfe5b3187a051ac9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['name'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.name'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifiers')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation'))) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851'), 'variations')).length > 1) {
    ___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')['showVariation'] = true;
  }
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'todaysOffer': true }));
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('4416128cf3cb1e114fe7637fb5f75851')))));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'todaysOffer': true }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9e07541842d81ed3c9491be2284d3a63/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    if ((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('CAN_USE_APPLE_PAY', undefined))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('57f6e3377f6421e1900d4a85050e9f3e', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/57f6e3377f6421e1900d4a85050e9f3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, locationId, cartItem, k, cart, savedOrder, payment, item, user, nonce, tips;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.pageData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))))) {
    try {
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
      locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
      ___arguments.context.pageData['loading'] = true;
      if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
        (getObjectProperty(___arguments.context.pageData, 'currentOrder'))['fulfillments'] = [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })];
        order = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': (getObjectProperty(___arguments.context.pageData, 'currentOrder')) })), 'order'));
      } else {
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'fulfillments': [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })] })))), 'order'));
      }
      tips = (getObjectProperty(___arguments.context.pageData, 'tips')) * 100;
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_APPLE_PAY', ({ 'totalAmount': tips ? (getObjectProperty(order, 'total_money.amount')) + tips : (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': (getObjectProperty(order, 'total_money.amount')),'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'tip': ((getObjectProperty(___arguments.context.pageData, 'tips')) * 100),'location_id': locationId })), 'payment'));
        delete ___arguments.context.pageData['currentOrder'];
        localStorage.removeItem('currentOrder');
        (getObjectProperty(order, 'total_tip_money'))['amount'] = ((getObjectProperty(___arguments.context.pageData, 'tips')) ? (getObjectProperty(___arguments.context.pageData, 'tips')) * 100 : 0);
        await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.pageData, 'imageList')));
        order['totalMoneyStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((((getObjectProperty(order, 'total_money.amount')) + (getObjectProperty(order, 'total_tip_money.amount'))) / 100)))));
        savedOrder = (await Backendless.Data.of('Order').deepSave( ({ 'squareId': (getObjectProperty(order, 'id')),'item': (await Promise.all(cart.map(async item => {; return ({ 'item': ({ 'objectId': (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')) }),'selectedModifier': (getObjectProperty(item, 'selectedModifiers')),'selectedVariation': (getObjectProperty(item, 'selectedVariationId')) });}))),'usedBonus': 0,'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'method': 'Apple Pay','squareId': (getObjectProperty(payment, 'id')),'type': 'Order' }),'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'activeLocation.objectId')) }),'title': (getObjectProperty(order, 'ticket_name')) }) ));
        ;(async () => {
            await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'order', [savedOrder]);

        })();
        order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
        order['cart'] = cart;
        var k_list = (getObjectProperty(order, 'line_items'));
        for (var k_index in k_list) {
          k = k_list[k_index];
          if (getObjectProperty(k, 'modifiers')) {
            cartItem = (await (async function(list, item) {
            	return list.find(cartItem => {
            	  const cartModifiers = Object.values(cartItem.selectedModifiers).map(item => item[0].catalog_object_id)
            	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

            	  if (cartModifiers.length === modifiers.length) {
            	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
            	    return cartItem.selectedVariationId === item.catalog_object_id && matches.length === 0
            	  }

            	})
            })((getObjectProperty(order, 'cart')), k));
          } else {
            cartItem = (await (async function(list, id) {
            	return list.find(item => {
            	  console.log(`item id: ${item.selectedVariationId}`)
            	  console.log(`compare id: ${id}`)
            	  if (item.selectedModifiers) {
            	    return item.selectedVariationId === id && Object.keys(item.selectedModifiers).length === 0
            	  } else {
            	    return item.selectedVariationId === id
            	  }

            	})
            })((getObjectProperty(order, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
          }
          k['name'] = (getObjectProperty(cartItem, 'name'));
          k['id'] = (getObjectProperty(cartItem, 'Id'));
          k['image'] = (getObjectProperty(cartItem, 'image'));
        }
        ___arguments.context.pageData['previousOrder'] = order;
        (getObjectProperty(___arguments.context.pageData, 'ordersList')).unshift(order);
        if ((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')) && ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.pageData, order);
        }
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        ___arguments.context.pageData['cart'] = [];
        localStorage.removeItem('cart');
      } else {
        ___arguments.context.pageData['loading'] = false;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
        await saveOrder();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1c1b0d160fc163e2e004562d4d64d999/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, locationId, cartItem, k, cart, savedOrder, payment, item, user, nonce, tips;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveOrder() {
  ___arguments.context.pageData['currentOrder'] = order;
  localStorage.setItem('currentOrder', JSON.stringify(order));
}


  if ((await BackendlessUI.Functions.Custom['fn_44380bd19b232d1ebc86185656af919a']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))))) {
    try {
      user = (getObjectProperty(___arguments.context.pageData, 'user'));
      cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
      locationId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeLocation')), 'id'));
      ___arguments.context.pageData['loading'] = true;
      if (getObjectProperty(___arguments.context.pageData, 'currentOrder')) {
        (getObjectProperty(___arguments.context.pageData, 'currentOrder'))['fulfillments'] = [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })];
        order = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': (getObjectProperty(___arguments.context.pageData, 'currentOrder')) })), 'order'));
      } else {
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': (cart.map(item => item['orderData'])),'ticket_name': String('Order ') + String((await Backendless.Counters.addAndGet('orderNumber', 1))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'fulfillments': [({ 'pickup_details': ({ 'pickup_at': (await BackendlessUI.Functions.Custom['fn_ae27dc58f88a9714e85d5f17d4b83f38']()),'recipient': ({ 'display_name': (getObjectProperty(user, 'name')),'phone_number': (getObjectProperty(user, 'phoneNumber')) }) }),'type': 'PICKUP' })] })))), 'order'));
      }
      tips = (getObjectProperty(___arguments.context.pageData, 'tips')) * 100;
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_GOOGLE_PAY', ({ 'totalAmount': tips ? (getObjectProperty(order, 'total_money.amount')) + tips : (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': (getObjectProperty(order, 'total_money.amount')),'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'tip': ((getObjectProperty(___arguments.context.pageData, 'tips')) * 100),'location_id': locationId })), 'payment'));
        delete ___arguments.context.pageData['currentOrder'];
        localStorage.removeItem('currentOrder');
        (getObjectProperty(order, 'total_tip_money'))['amount'] = ((getObjectProperty(___arguments.context.pageData, 'tips')) ? (getObjectProperty(___arguments.context.pageData, 'tips')) * 100 : 0);
        await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](order, (getObjectProperty(___arguments.context.pageData, 'imageList')));
        order['totalMoneyStr'] = (String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3']((((getObjectProperty(order, 'total_money.amount')) + (getObjectProperty(order, 'total_tip_money.amount'))) / 100)))));
        savedOrder = (await Backendless.Data.of('Order').deepSave( ({ 'squareId': (getObjectProperty(order, 'id')),'item': (await Promise.all(cart.map(async item => {; return ({ 'item': ({ 'objectId': (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')) }),'selectedModifier': (getObjectProperty(item, 'selectedModifiers')),'selectedVariation': (getObjectProperty(item, 'selectedVariationId')) });}))),'usedBonus': 0,'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'method': 'Google Pay','squareId': (getObjectProperty(payment, 'id')),'type': 'Order' }),'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'activeLocation.objectId')) }),'title': (getObjectProperty(order, 'ticket_name')) }) ));
        ;(async () => {
            await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'user')), 'order', [savedOrder]);

        })();
        order = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(order, 'id'))) }).send()), 'order'));
        order['cart'] = cart;
        var k_list = (getObjectProperty(order, 'line_items'));
        for (var k_index in k_list) {
          k = k_list[k_index];
          if (getObjectProperty(k, 'modifiers')) {
            cartItem = (await (async function(list, item) {
            	return list.find(cartItem => {
            	  const cartModifiers = Object.values(cartItem.selectedModifiers).map(item => item[0].catalog_object_id)
            	  const modifiers = item.modifiers.map(item => item.catalog_object_id)

            	  if (cartModifiers.length === modifiers.length) {
            	    const matches = cartModifiers.filter(modifier => !modifiers.includes(modifier))
            	    return cartItem.selectedVariationId === item.catalog_object_id && matches.length === 0
            	  }

            	})
            })((getObjectProperty(order, 'cart')), k));
          } else {
            cartItem = (await (async function(list, id) {
            	return list.find(item => {
            	  console.log(`item id: ${item.selectedVariationId}`)
            	  console.log(`compare id: ${id}`)
            	  if (item.selectedModifiers) {
            	    return item.selectedVariationId === id && Object.keys(item.selectedModifiers).length === 0
            	  } else {
            	    return item.selectedVariationId === id
            	  }

            	})
            })((getObjectProperty(order, 'cart')), (getObjectProperty(k, 'catalog_object_id'))));
          }
          k['name'] = (getObjectProperty(cartItem, 'name'));
          k['id'] = (getObjectProperty(cartItem, 'Id'));
          k['image'] = (getObjectProperty(cartItem, 'image'));
        }
        ___arguments.context.pageData['previousOrder'] = order;
        (getObjectProperty(___arguments.context.pageData, 'ordersList')).unshift(order);
        if ((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')) && ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'loyaltyProgram')), 'location_ids')).includes(locationId))) {
          await BackendlessUI.Functions.Custom['fn_e6a123cfb14311c56e1eaf98c8a8fb01'](___arguments.context.pageData, order);
        }
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);
        ___arguments.context.pageData['cart'] = [];
        localStorage.removeItem('cart');
      } else {
        ___arguments.context.pageData['loading'] = false;
      }
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1fd7f75a9acccc587a7e83469b4f0b0d', true);

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
        await saveOrder();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
        await saveOrder();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    ___arguments.context.pageData['closedMessage'] = (await BackendlessUI.Functions.Custom['fn_91bb94042c7f02453a544691e012b78f']((getObjectProperty(___arguments.context.pageData, 'activeLocation'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6d38a6784537ef075d9b5aedbc1b56b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'))))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4fb7b4a4536fb28c92a6b55ef925a1f7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value, cents;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['walletAmount'] = '';
  value = (getObjectProperty((___arguments.value.match((new RegExp('\\d*\\.?\\d{0,2}', '')))), '0'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4fb7b4a4536fb28c92a6b55ef925a1f7'))['value'] = value;
  if ((value.search((new RegExp('\\.', '')))) == -1) {
    cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 100;
  } else {
    if (value.length - (value.indexOf('.') + 1) == 1) {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 10;
    } else if (value.length - (value.indexOf('.') + 1) == 2) {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), ''))));
    } else {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 100;
    }
  }
  ___arguments.context.pageData['walletInput'] = cents;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/9bccd962379287374294f829f9581521/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, payment, user, wallet, locationId, nonce, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_APPLE_PAY', ({ 'totalAmount': (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
        wallet = (getObjectProperty(___arguments.context.pageData, 'wallet'));
        if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
        } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
          wallet['state'] = 'ACTIVE';
        }
        ;(async () => {
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
          ___arguments.context.pageData['walletInput'] = '';
          await new Promise(r => setTimeout(r, 2000 || 0));
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

        })();
        ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
        user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        await Backendless.UserService.update( new Backendless.User(user) );
        ;(async () => {
            await Backendless.Data.of('Order').deepSave( ({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Apple Pay','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' }) );

        })();
      } else {
        ___arguments.context.pageData['loading'] = false;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5c9bb3c3a75ea8717643dcd211e23e9c/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    if ((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('CAN_USE_APPLE_PAY', undefined))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bccd962379287374294f829f9581521', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/a9cc11560cc75d9be66410877ce8807e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, payment, user, wallet, locationId, nonce, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_GOOGLE_PAY', ({ 'totalAmount': (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
        wallet = (getObjectProperty(___arguments.context.pageData, 'wallet'));
        if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
        } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
          wallet['state'] = 'ACTIVE';
        }
        ;(async () => {
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
          ___arguments.context.pageData['walletInput'] = '';
          await new Promise(r => setTimeout(r, 2000 || 0));
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

        })();
        ___arguments.context.pageData['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
        user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        await Backendless.UserService.update( new Backendless.User(user) );
        ;(async () => {
            await Backendless.Data.of('Order').deepSave( ({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Google Pay','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' }) );

        })();
      } else {
        ___arguments.context.pageData['loading'] = false;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c551e9bed869c55bdb070ea51f21d969/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9807faa636cfa5f3d49f1b7b4e62ca33/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'user')) {
    ___arguments.context.pageData['tab'] = 'wallet';
    ___arguments.context.pageData['screen'] = 'settings';
    await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'settings','tab': 'wallet' }));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/18a5e8182e1978fba2b72daacbefdbb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': (getObjectProperty(___arguments.context.pageData, 'screen')),'tab': 'wallet' }));
  ___arguments.context.pageData['tab'] = 'wallet';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d6f5763c21c2f8fabddcb54bb43f65c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6bde8b64c9ce77f61d7d2d9f28b2cdd', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/10810b5dde48f0220e75ef85fe72e8eb/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var amountList, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  amountList = [];
  for (i = 10; i <= 500; i += 5) {
    addItemToList(amountList, ({ 'amount': i }));
  }
  ___arguments.context.pageData['wheelValue'] = 10;
  ___arguments.context.pageData['wheelList'] = amountList;
  await (async function(pageData) {
  	const wheel = document.querySelector('.wheel')
  	const minVal = 10
  	const maxVal = 500

  	let scrollStart,
  	    scrollEnd,
  	    scrollHeight

  	wheel.addEventListener('touchstart', touchStart)
  	wheel.addEventListener('touchend', touchEnd)
  	wheel.addEventListener('touchmove', touchMove)

  	function touchStart(event) {
  	  event.preventDefault()

  	  scrollStart = event.changedTouches[0].clientY + wheel.scrollTop
  	  console.log(`start: ${scrollStart}`)
  	}

  	function touchEnd(event) {
  	  event.preventDefault()
  	  console.log(`end: ${scrollEnd}`)

  	  const elHeight = document.querySelector('.wheel__item').clientHeight
  	  const wheelItem = scrollHeight % elHeight > elHeight / 2 ? Math.floor(scrollHeight / elHeight) + 1 : Math.floor(scrollHeight / elHeight)
  	  const wheelValue = (wheelItem + 2) * 5

  	  console.log(wheelValue)

  	  let el

  	  if(wheelValue < minVal) {
  	    el = document.querySelector(`.wheel-${minVal}`)
  	    setPageData(minVal)
  	  } else if (wheelValue > maxVal) {
  	    el = document.querySelector(`.wheel-${maxVal}`)
  	    setPageData(maxVal)
  	  } else {
  	    el = document.querySelector(`.wheel-${wheelValue}`)
  	    setPageData(wheelValue)
  	  }

  	  el.scrollIntoView({block: "center", behavior: "smooth"})

  	}

  	function touchMove(event) {
  	  event.preventDefault()

  	  scrollEnd = event.changedTouches[0].clientY
  	  scrollHeight = scrollStart - scrollEnd
  	  wheel.scrollTop = scrollHeight
  	}

  	function setPageData (val) {
  	  // pageData.walletInput = val * 100
  	  pageData.walletAmount = val
  	  pageData.wheelValue = val
  	  // pageData.walletAmount = ''
  	}
  })(___arguments.context.pageData);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/188b1c8e6e749e7daa15a2e90c3c327b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('188b1c8e6e749e7daa15a2e90c3c327b')), (String('wheel-') + String(getObjectProperty(___arguments.context.dataModel, 'amount'))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/ab2b61f5948fecee6d1b28ecb8a323f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'walletInput')) ? ['fill__sum', 'fill__sum--active'] : ['fill__sum'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/529f15caae4ddae7b88fb86854a351b9/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'activeItem.modifiers'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3c0642efcde4780ae662e5e596d6bf0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/731dc6fd7985926731beb47a132e8189/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4483bfbcc07aa28feb9c10498cbdc274/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? 'Profile' : 'Sign In')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/79ba2e9bdfdc811baaae5c66b8a4f017/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'user')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8edf0106b148e87444fa6e1eebe9d27c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? 'Choose pickup location' : 'Choose location')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/9826e6c796322e51824ba12504084bb8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f3b18ec7cedffa75c729dbb86ab41e', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6516b3a80a8be43568fd1dabd054b24f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tax, tips, cart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cart = (getObjectProperty(___arguments.context.pageData, 'cart'));
  tax = (getObjectProperty(___arguments.context.pageData, 'tax'));
  tips = (getObjectProperty(___arguments.context.pageData, 'tips'));

  return (!(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b96d66299d4ae04f1f544286649c88b9')).includes('disabled')) && (await BackendlessUI.Functions.Custom['fn_d82335856f765b64e571be37c56d0e1d'](cart)) + (tips ? tips * 100 : 0) + (tax ? tax * 100 : 0) == 0 ? 'checkout' : 'pay with balance')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/location/components/009ccd4ecccef8d817e6ac32c7b97795/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['locationView'] = 'list';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/location/components/e6d5d2484e4b75a94638dbc49c73938d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['locationView'] = 'list';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('821a937b393a2bcb49c526c759446c83', false);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'list' ? ['location__menu_item', 'active'] : ['location__menu_item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/d3e1cf038a9bef9d529531bc09f6dfee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['locationView'] = 'map';
  await new Promise(r => setTimeout(r, 1 || 0));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('821a937b393a2bcb49c526c759446c83', true);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? ['location__menu_item', 'active'] : ['location__menu_item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/2bf89bb1a4f9f09e3025ef54ae66cbd4/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'list' ? '/img/icons/list_view_active.svg' : '/img/icons/list_view.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/location/components/0f8de7e359ac5435dfe7f573c98eac44/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? '/img/icons/map_view_active.svg' : '/img/icons/map_view.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/location/components/0d7517c489d90c2f5c180363671bb2ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map' ? ['location__list', 'location__list--row', 'hide-scroll'] : ['location__list', 'location__list--column', 'hide-scroll'])

  },
  /* handler:onClassListAssignment */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'filteredLocationList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/location/components/979debb96d4db7b9e15f19acb18f23b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pointCoordinates;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'locationView')) == 'map') {
    pointCoordinates = (getObjectProperty(___arguments.context.dataModel, 'coordinates'));
    await (async function(userPoint, destinationPoint, map, pageData, points, appData) {
    	const img = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon.png',
    	      imgActive = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-active.png',
    	      destinationLat = destinationPoint.lat,
    	      destinationLng = destinationPoint.lng,
    	      inactiveMarkers = points.filter(point => point.lat !== destinationLat && point.lng !== destinationLng);
    	
    	scrollToLocation(destinationLat, destinationLng);
    	
    	map.setCenter(new google.maps.LatLng(destinationLat, destinationLng));
    	
    	if(userPoint) renderDirection(destinationPoint);
    	
    	addMarkersToMap(pageData.markers, null);
    	
    	pageData.markers = [];
    	
    	addMarkers(img, ...inactiveMarkers);
    	addMarkers(imgActive, destinationPoint);
    	
    	addMarkersToMap(pageData.markers, map);
    	
    	function addMarkers (img, ...latlng) {
    	  for (let i = 0; i < latlng.length; i++) {
    	    const position = new google.maps.LatLng(latlng[i].lat, latlng[i].lng);
    	    
    	    const marker = new google.maps.Marker({
    	      position: position,
    	      icon: img,
    	    });
    	    
    	    pageData.markers.push(marker);
    	    
    	    marker.addListener('click', setMarkers);
    	  }
    	}
    	
    	function renderDirection (position) {
    	  const directionsService = new google.maps.DirectionsService(),
    	        start = new google.maps.LatLng(userPoint.lat, userPoint.lng),
    	        end = new google.maps.LatLng(position.lat, position.lng);
    	  let directionsRenderer;
    	  
    	  if (pageData.directionsRenderer) {
    	    directionsRenderer = pageData.directionsRenderer;
    	  } else {
    	    directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});
    	    pageData.directionsRenderer = directionsRenderer;
    	  }
    	  
    	  directionsRenderer.setMap(map);
    	  
    	  const request = {
    	    origin: start,
    	    destination: end,
    	    travelMode: 'DRIVING'
    	  };
    	    
    	  directionsService.route(request, function(result, status) {
    	    if (status == 'OK') {
    	      directionsRenderer.setDirections(result);
    	    }
    	  })
    	}
    	
    	function setMarkers () {
    	  const markerPosition = this.getPosition(),
    	        currentMarkerLat = markerPosition.lat(),
    	        currentMarkerLng = markerPosition.lng();
    	        
    	  renderDirection({
    	    lat: currentMarkerLat, 
    	    lng: currentMarkerLng
    	  });
    	
    	  addMarkersToMap(pageData.markers, null);
    	
    	  pageData.markers = [];
    	
    	  const inactiveMarkers = points.filter(point => point.lat !== currentMarkerLat && point.lng !== currentMarkerLng)
    	
    	  addMarkers(img, ...inactiveMarkers);
    	  addMarkers(imgActive, {
    	    lat: currentMarkerLat,
    	    lng: currentMarkerLng
    	  });
    	
    	  addMarkersToMap(pageData.markers, map);
    	  
    	  scrollToLocation(currentMarkerLat, currentMarkerLng);
    	}
    	
    	function addMarkersToMap (markersList, map) {
    	  for (i = 0; i < markersList.length; i++) {
    	    markersList[i].setMap(map);
    	  }
    	}
    	
    	function scrollToLocation (lat, lng) {
    	  const locationIndex = appData.filteredLocationList.findIndex(location => (
    	    location.coordinates.latitude === lat && location.coordinates.longitude === lng
    	  ));
    	  
    	  const locationListComponent = document.querySelector('.location__list')
    	  locationListComponent.scrollLeft = 336 * locationIndex;
    	}
    })((getObjectProperty(___arguments.context.appData, 'mapCenter')), ({ 'lat': (getObjectProperty(pointCoordinates, 'latitude')),'lng': (getObjectProperty(pointCoordinates, 'longitude')) }), (getObjectProperty(___arguments.context.pageData, 'googleMap')), ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'coordinates')), ___arguments.context.appData);
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var address, closeTime, dayNum, dayPeriod, item, openTime, periods, schedule, today, workingHours;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createWorkingTimeString(workingHours) {
  openTime = (Number(((getObjectProperty(workingHours, 'start_local_time')).slice(0, 2))));
  closeTime = (Number(((getObjectProperty(workingHours, 'end_local_time')).slice(0, 2))));
  return [await timeToAMPM(openTime),' - ',await timeToAMPM(closeTime)].join('')
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function timeToAMPM(dayPeriod) {
  return dayPeriod >= 12 ? String(dayPeriod == 12 ? dayPeriod : dayPeriod - 12) + String(' PM') : (dayPeriod == 0 ? '12 AM' : String(dayPeriod) + String(' AM'))
}


  periods = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'business_hours')), 'periods'));
  if (periods) {
    dayNum = (new Date((new Date())).getDay());
    today = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][((dayNum + 1) - 1)];
    schedule = (await asyncListFilter(periods, async (item) => {


     return ((getObjectProperty(item, 'day_of_week')) == today);
    }));
    ___arguments.context.dataModel['workingTime'] = (!schedule.length ? 'Closed Today' : await createWorkingTimeString(schedule[0]));
  } else {
    ___arguments.context.dataModel['workingTime'] = 'Closed today';
  }
  address = (getObjectProperty(___arguments.context.dataModel, 'address'));
  ___arguments.context.dataModel['addressString'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/location/components/eca3761dc9da373430d2e5537a9f32bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var locationId, user, userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  ___arguments.context.pageData['loading'] = true;
  user = (getObjectProperty(___arguments.context.appData, 'user'));
  locationId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5'), 'id'));
  userData = (getObjectProperty(___arguments.context.appData, 'userData'));
  if ((getObjectProperty(userData, 'activeLocation.id')) != locationId) {
    userData['activeLocation'] = ___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5');
    await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, ___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5'), ___arguments.context.appData);
    if (user) {
      user['location'] = locationId;
      await Backendless.UserService.update( new Backendless.User(user) );
      userData['cart'] = [];
      localStorage.removeItem('cart');
      localStorage.removeItem('currentOrder');
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu', undefined);
  }
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/821a937b393a2bcb49c526c759446c83/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var coords, location2, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.newValue) {
    if (!(getObjectProperty(___arguments.context.appData, 'coordinates'))) {
      ___arguments.context.appData['coordinates'] = (await Promise.all(((getObjectProperty(___arguments.context.appData, 'locationsList')).map(item => item['coordinates'])).map(async item => {; return ({ 'lat': (getObjectProperty(item, 'latitude')),'lng': (getObjectProperty(item, 'longitude')) });})));
    }
    if (getObjectProperty(___arguments.context.appData, 'user.location')) {
      location2 = (await (async function(list, id) {
      	return list.find(item => item.id === id)
      })((getObjectProperty(___arguments.context.appData, 'locationsList')), (getObjectProperty(___arguments.context.appData, 'user.location'))));
      if (location2) {
        coords = (getObjectProperty(location2, 'coordinates'));
      }
    }
    ___arguments.context.pageData['googleMap'] = (await (async function(userPoint, placeholder) {
    	let map

    	function initMap() {
    	  map = new google.maps.Map(document.getElementById("map"), {
    	    zoom: 16,
    	    center: userPoint || placeholder,
    	    disableDefaultUI: true,
    	  });

    	  if(userPoint) {
    	    const userMarker = new google.maps.Marker({
    	      position: userPoint,
    	      icon: 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-user.png',
    	      map: map
    	    });
    	  }
    	}

    	initMap();

    	return map
    })((getObjectProperty(___arguments.context.appData, 'mapCenter')), (coords ? ({ 'lat': (getObjectProperty(coords, 'latitude')),'lng': (getObjectProperty(coords, 'longitude')) }) : (getObjectProperty(___arguments.context.appData, 'coordinates'))[0])));
    await (async function(pageData, map, userPoint, points) {
    	const img = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon.png';
    	const imgActive = 'https://backendlessappcontent.com/B90240B6-34BF-59D1-FF98-83FC732C7500/CB510787-0DC8-49BE-9E8B-9177692A5E20/files/img/icons/map-icon-active.png';

    	pageData.markers = [];

    	for (let i = 0; i < points.length; i++) {
    	  const position = new google.maps.LatLng(points[i].lat, points[i].lng)

    	  const marker = new google.maps.Marker({
    	    position: position,
    	    icon: img,
    	  });
    	  pageData.markers.push(marker)

    	  marker.addListener('click', setMarkers);
    	}

    	addMarkersToMap(pageData.markers, map);

    	function addMarkersToMap (markersList, map) {
    	  for (i = 0; i < markersList.length; i++) {
    	    markersList[i].setMap(map);
    	  }
    	}

    	function setMarkers () {
    	  const markerPosition = this.getPosition()
    	  const directionsService = new google.maps.DirectionsService();
    	  let directionsRenderer;

    	  if (pageData.directionsRenderer) {
    	    directionsRenderer = pageData.directionsRenderer;
    	  } else {
    	    directionsRenderer = new google.maps.DirectionsRenderer({
    	      suppressMarkers: true
    	    });
    	    pageData.directionsRenderer = directionsRenderer;
    	  }

    	  directionsRenderer.setMap(map);

    	  calcRoute();

    	  addMarkersToMap(pageData.markers, null);

    	  pageData.markers = [];
    	  const currentMarkerLat = markerPosition.lat()
    	  const currentMarkerLng = markerPosition.lng()

    	  const inactiveMarkers = points.filter(point => point.lat !== currentMarkerLat && point.lng !== currentMarkerLng)

    	  addMarkers(img, ...inactiveMarkers);
    	  addMarkers(imgActive, {
    	    lat: currentMarkerLat,
    	    lng: currentMarkerLng
    	  });

    	  addMarkersToMap(pageData.markers, map);

    	  const locationIndex = pageData.locationsList.findIndex(location => (
    	    location.coordinates.latitude === currentMarkerLat && location.coordinates.longitude === currentMarkerLng
    	  ));

    	  const locationListComponent = document.querySelector('.location__list')
    	  locationListComponent.scrollLeft = 336 * locationIndex;

    	  function calcRoute() {
    	    const start = new google.maps.LatLng(userPoint.lat, userPoint.lng);
    	    const end = markerPosition;
    	    const request = {
    	      origin: start,
    	      destination: end,
    	      travelMode: 'DRIVING'
    	    };

    	    directionsService.route(request, function(result, status) {
    	      if (status == 'OK') {
    	        directionsRenderer.setDirections(result);
    	      }
    	    });
    	  }

    	  function addMarkers (img, ...latlng) {
    	    for (let i = 0; i < latlng.length; i++) {
    	      const position = new google.maps.LatLng(latlng[i].lat, latlng[i].lng);

    	      const marker = new google.maps.Marker({
    	        position: position,
    	        icon: img,
    	      });

    	      pageData.markers.push(marker);

    	      marker.addListener('click', setMarkers);
    	    }
    	  }
    	}
    })(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'googleMap')), (getObjectProperty(___arguments.context.appData, 'mapCenter')), (getObjectProperty(___arguments.context.appData, 'coordinates')));
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/location/components/a9d8ddb1a21b097a742e7c756794c665/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('search');

  ;(function() {
    const callback = async () => {
        ___arguments.context.appData['filteredLocationList'] = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'locationsList')), async (item) => {


     return ((((getObjectProperty(item, 'addressString')).toLowerCase()).indexOf((___arguments.value.toLowerCase())) !== -1) || (((getObjectProperty(item, 'name')).toLowerCase()).indexOf((___arguments.value.toLowerCase())) !== -1));
    }));

    };

    const timerId = 'search';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/location/components/1ba8104bda095a632ba93aade8c60f7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.appData['filteredLocationList'] = (getObjectProperty(___arguments.context.appData, 'locationsList'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a9d8ddb1a21b097a742e7c756794c665'))['value'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/73967aa8a551b3ac9dc229990de9562e/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('979debb96d4db7b9e15f19acb18f23b5'), 'id')) == (getObjectProperty(___arguments.context.appData, 'userData.activeLocation.id')) ? 'check' : 'keyboard arrow right')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/location/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, locationsList, item, j, location2, locationTypes, address, imagesList, category, categoryItem, squareObjects, data, userLocation, user, order, payment, wallet, deviceId, token, amount, appConfig, newLocations, list, locationChangeTimestamp, locationChangeCache, timestamps, cardsList, favItem, squareFavs, favorite, lastOrder, cartItem, squareItemData, lastOrderItems, modifierNames, orders, squareOrders, k, curOrder, birthday, monthList, menus, menuChangeTimestamp, menuChangeCache, offer, todaysOffer, dateEnd, date, offerCache, dateStart, offset, res, newOffersList, newOffers, newIds, object, hookData, command;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.appData, 'appLoaded'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  } else {
    await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.appData, ___arguments.context.pageData);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/menu/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var location2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['menuTimestamps'] = (getObjectProperty(___arguments.context.appData, 'menuTimestamps'));
    location2 = (getObjectProperty(___arguments.context.appData, 'userData.activeLocation'));
    if (location2) {
      ___arguments.context.pageData['activeLocation'] = location2;
      if ((getObjectProperty(___arguments.context.appData, 'activeMenuId')) == (getObjectProperty(location2, 'menuId'))) {
        ___arguments.context.pageData['categoryList'] = (getObjectProperty(___arguments.context.appData, 'categoryList'));
      } else {
        await BackendlessUI.Functions.Custom['fn_ad211bac0f872b8ab4fffec74df842d8'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'activeLocation')), ___arguments.context.appData);
      }
    }
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/menu/components/faf4097ffd479f845a2fdff86a630cb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, item, variation, object, currentItem, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function listenItems() {
  getRTSubscriptions().remove('listenItemChange');

  ;(function(whereClause) {
    const callback = async object => {
        Object.assign((await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(___arguments.context.dataModel, 'item')), (getObjectProperty(object, 'objectId')))), object);
    currentItem = (await (async function(list, id) {
    	return list.find(item => item.objectId === id)
    })((getObjectProperty(___arguments.context.appData, 'catalogList')), (getObjectProperty(object, 'objectId'))));
    if (getObjectProperty(object, 'modifier')) {
      currentItem['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


       return ((Object.keys((getObjectProperty(object, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
      })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


         return ((getObjectProperty(object, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
        }));
      ; return item;})));
    }
    currentItem['variations'] = (await asyncListFilter((getObjectProperty(currentItem, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(object, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));

    };

    const rtHandlers = Backendless.Data.of('Item').rt();

    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addUpdateListener.apply(rtHandlers, options);

    getRTSubscriptions().add('listenItemChange', () => rtHandlers.removeUpdateListener(callback));

  })((['objectId IN (\'',((getObjectProperty(___arguments.context.dataModel, 'catalog')).map(item => item['objectId'])).join('\',\''),'\')'].join('')));
    }


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    try {
      ___arguments.context.dataModel['catalog'] = (await asyncListSort((getObjectProperty(___arguments.context.dataModel, 'item')), 1, async (item) => {


       return (getObjectProperty(item, 'order'));
      }));
      ___arguments.context.appData['catalog'] = ({ 'category': (getObjectProperty(___arguments.context.dataModel, 'name')),'catalogList': (getObjectProperty(___arguments.context.dataModel, 'catalog')) });
      ;(async () => {
          await listenItems();

      })();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-category', undefined);

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/79aea4468572e58e0de7e127579547f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.appData, 'userData.activeLocation')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/201cc83dfd3e13e21e4d18c4b06edca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['stopPropagation'] = true;
    ___arguments.context.pageData['screen'] = 'location';
    ___arguments.context.pageData['locationView'] = 'list';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
    ___arguments.context.pageData['userPoint'] = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    while (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(___arguments.context.pageData, 'coordinates')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), ___arguments.context.pageData);
    while (!(getObjectProperty(___arguments.context.pageData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(___arguments.context.pageData, 'distanceList'));
    locationsList = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
    }
    ___arguments.context.pageData['stopPropagation'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/cbaf450f12f802318f7ae9da250df17b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/8edf0106b148e87444fa6e1eebe9d27c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? 'Choose pickup location' : 'Choose location')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/menu-category/components/8001b20e2ab3b6465d84de629bd3baa6/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'user')) && (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite')) && (((getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId'))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newFav, userFavorite, favoriteList, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'savingFav'))) {
    ___arguments.context.pageData['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['savingFav'] = true;
    favoriteList = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId')))) {
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId'))));
      ;(async () => {
          try {
          await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(favItemId));

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        newFav = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/createFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'item': ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'objectId')) }) }))));
        Object.assign(newFav, ({ 'favId': (getObjectProperty(newFav, 'item.objectId')),'item_data': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'item_data')),'id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'id')),'variation': (getObjectProperty(newFav, 'item.variation')),'modifier': (getObjectProperty(newFav, 'item.modifier')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'name')),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'), 'image')) }));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['loading'] = false;
          ___arguments.context.pageData['networkError'] = true;
        }

      }
    }
    ___arguments.context.pageData['loading'] = false;
    await new Promise(r => setTimeout(r, 300 || 0));
    ___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f')['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu-category/components/ef0a8fcc64c7fc2fa05691271c3b714f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifiersList')), async (modifier) => {


   return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
  })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


     return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
    }));
  ; return item;})));
  ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


   return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
  }));
  if ((getObjectProperty(___arguments.context.dataModel, 'variations')).length > 1) {
    ___arguments.context.dataModel['showVariation'] = true;
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favVariation')) {
    ___arguments.context.dataModel['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'favVariation'));
  }
  if (getObjectProperty(___arguments.context.dataModel, 'favModifier')) {
    ___arguments.context.dataModel['selectedModifiers'] = (getObjectProperty(___arguments.context.dataModel, 'favModifier'));
  }
  if (getObjectProperty(___arguments.context.appData, 'userData.activeLocation')) {
    ___arguments.context.appData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu-item', undefined);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', true);
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.dataModel, 'image'))) {
    await BackendlessUI.Functions.Custom['fn_68014e5426756ca6afb9b717b4664334'](___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'imageList')));
  }
  user = (getObjectProperty(___arguments.context.appData, 'user'));
  if ((getObjectProperty(user, 'favorite')) && (((getObjectProperty(user, 'favorite')).map(item => item['favId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))) {
    ___arguments.context.dataModel['userFav'] = true;
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu-category/components/5cdd130697dbcd027b621c699aa3fe0d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCategory')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('533634d37002c885ec1213b16dad63a9')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-category/components/201cc83dfd3e13e21e4d18c4b06edca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['stopPropagation'] = true;
    ___arguments.context.pageData['screen'] = 'location';
    ___arguments.context.pageData['locationView'] = 'list';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
    ___arguments.context.pageData['userPoint'] = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    while (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(___arguments.context.pageData, 'coordinates')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), ___arguments.context.pageData);
    while (!(getObjectProperty(___arguments.context.pageData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(___arguments.context.pageData, 'distanceList'));
    locationsList = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
    }
    ___arguments.context.pageData['stopPropagation'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-category/components/cbaf450f12f802318f7ae9da250df17b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-category/components/a27c6542c4bdfc82c824ad62bf19c1a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var variation, currentItem, item, modifier;


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('ef0a8fcc64c7fc2fa05691271c3b714f'))))), ___arguments.context.pageData, ___arguments.context.appData);
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-order', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu-category/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-category/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['activeLocation'] = (getObjectProperty(___arguments.context.appData, 'userData.activeLocation'));
    Object.assign(___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'catalog')));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/menu-item/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var item, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    Object.assign(___arguments.context.pageData, ({ 'activeItem': (getObjectProperty(___arguments.context.appData, 'activeItem')),'category': (getObjectProperty(___arguments.context.appData, 'catalog.category')) }));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/menu-item/components/74e24a658f1109d2dc283ff6cb4b262e/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var item, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  item = (getObjectProperty(___arguments.context.appData, 'activeItem'));
  user = (getObjectProperty(___arguments.context.appData, 'user'));
  if (item && user && (getObjectProperty(user, 'favorite')) && (((getObjectProperty(user, 'favorite')).map(item => item['favId'])).includes(((getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId')))))) {
    return 'favorite';
  } else {
    return 'favorite border';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, error, newFav, userFavorite, favoriteList, actId, favItemId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(getObjectProperty(___arguments.context.appData, 'catalog'));
  item = (getObjectProperty(___arguments.context.appData, 'activeItem'));
  actId = (getObjectProperty(item, 'favId')) ? (getObjectProperty(item, 'favId')) : (getObjectProperty(item, 'objectId'));
  if (!(getObjectProperty(item, 'savingFav'))) {
    item['savingFav'] = true;
    favoriteList = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'favoriteList'));
    userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite'));
    if ((favoriteList.map(item => item['favId'])).includes(actId)) {
      favItemId = (await (async function(list, id) {
      	return list.find(fav => fav.favId === id).objectId
      })(userFavorite, actId));
      ;(async () => {
          try {
          await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(favItemId));

        } catch (error) {
          console.log(error);
          if ((getObjectProperty(error, 'message')) == 'Network Error') {
            ___arguments.context.pageData['loading'] = false;
            ___arguments.context.pageData['networkError'] = true;
          }

        }

      })();
      removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
      removeItemInList(favoriteList, ({ 'objectId': favItemId }), 'objectId');
    } else {
      try {
        newFav = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/createFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'item': ({ 'objectId': actId }),'variation': (getObjectProperty(item, 'selectedVariationId')),'modifier': ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9')) }))));
        newFav['favId'] = (getObjectProperty(newFav, 'item.objectId'));
        newFav['item_data'] = (getObjectProperty(item, 'item_data'));
        newFav['id'] = (getObjectProperty(item, 'id'));
        newFav['favVariation'] = (getObjectProperty(newFav, 'variation'));
        newFav['favModifier'] = (getObjectProperty(newFav, 'modifier'));
        newFav['variation'] = (getObjectProperty(newFav, 'item.variation'));
        newFav['modifier'] = (getObjectProperty(newFav, 'item.modifier'));
        newFav['name'] = (getObjectProperty(item, 'name'));
        newFav['image'] = (getObjectProperty(item, 'image'));
        addItemToList(favoriteList, newFav);
        addItemToList(userFavorite, newFav);

      } catch (error) {
        console.log(error);
        if ((getObjectProperty(error, 'message')) == 'Network Error') {
          ___arguments.context.pageData['networkError'] = true;
        }

      }
    }
    await new Promise(r => setTimeout(r, 300 || 0));
    item['savingFav'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'user'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu-item/components/5cdd130697dbcd027b621c699aa3fe0d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCategory')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('533634d37002c885ec1213b16dad63a9')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/4f6874b8ccb5adf1e6a2a463b65600d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'activeItem'))['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'selectedVariationId')) == (getObjectProperty(___arguments.context.dataModel, 'id')) ? ['modifier__option', 'active'] : ['modifier__option'])

  },
  /* handler:onClassListAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'activeItem')), 'selectedVariationId')) && (getObjectProperty(___arguments.context.dataModel, 'id')) == (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'activeItem.variations'))[0]), 'id'))) {
    (getObjectProperty(___arguments.context.pageData, 'activeItem'))['selectedVariationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu-item/components/79aea4468572e58e0de7e127579547f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    await BackendlessUI.Functions.Custom['fn_bfc548691c9afea98ab7f3ec02f5415c'](___arguments.context.pageData);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('332dc9db170cf80fd3b52a342d3589eb')), 'display')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu-item/components/5fdd5d5a9e6a333f2f9ca4683e0b6c68/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierListId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  modifierListId = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'modifier_data')), 'modifier_list_id'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2df9fe6dec33d3242edb2947f61c011f'), 'modifier_list_data')), 'selection_type')) == 'MULTIPLE' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId))) {
    if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).map(item => item['catalog_object_id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) {
      removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)), ({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) }), 'catalog_object_id');
      if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).length) {
        delete ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId];
      }
    } else {
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2df9fe6dec33d3242edb2947f61c011f'), 'modifier_list_data.selection_type')) == 'MULTIPLE') {
        addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)), ({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) }));
      } else {
        ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId] = [({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) })];
      }
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9')[modifierListId] = [({ 'catalog_object_id': (getObjectProperty(___arguments.context.dataModel, 'id')) })];
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var modifierListId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  modifierListId = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'modifier_data')), 'modifier_list_id'));
  if ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)))) && (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('529f15caae4ddae7b88fb86854a351b9'), modifierListId)).map(item => item['catalog_object_id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))) {
    return ['modifier__option', 'active'];
  } else {
    return ['modifier__option'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu-item/components/ef436ef6e4c744ca0cca465a5d8eaacb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var activeItem, cart, cartItem, currentOrder, error, item, j, k, location2, locationId, modifiers, orderItem, rewardItem, rewardName, updatedOrder, user, userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    if (getObjectProperty(___arguments.context.appData, 'user')) {
      userData = (getObjectProperty(___arguments.context.appData, 'userData'));
      location2 = (getObjectProperty(userData, 'activeLocation'));
      if (location2) {
        ___arguments.context.pageData['loading'] = true;
        locationId = (getObjectProperty(location2, 'id'));
        user = (getObjectProperty(___arguments.context.appData, 'user'));
        cart = (getObjectProperty(userData, 'cart'));
        activeItem = (getObjectProperty(___arguments.context.appData, 'activeItem'));
        if (!(getObjectProperty(activeItem, 'showVariation'))) {
          activeItem['selectedVariationId'] = (getObjectProperty(((getObjectProperty(activeItem, 'variations'))[0]), 'id'));
        }
        if (getObjectProperty(activeItem, 'favId')) {
          activeItem['objectId'] = (getObjectProperty(activeItem, 'favId'));
        }
        if (getObjectProperty(activeItem, 'selectedVariationId')) {
          modifiers = [];
          var j_list = ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9'));
          for (var j_index in j_list) {
            j = j_list[j_index];
            modifiers = (modifiers.concat(j));
          }
          activeItem['orderData'] = ({ 'quantity': (getObjectProperty(activeItem, 'quantity')),'catalog_object_id': (getObjectProperty(activeItem, 'selectedVariationId')),'modifiers': modifiers,'note': (getObjectProperty(___arguments.context.pageData, 'note')) });
          ___arguments.context.pageData['note'] = '';
          activeItem['selectedModifiers'] = ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('529f15caae4ddae7b88fb86854a351b9'));
          if (Array.isArray((getObjectProperty(userData, 'cart')))) {
            cartItem = (await BackendlessUI.Functions.Custom['fn_8e28277e1bdfb38d4d1f2b8a6b6ad168'](cart, activeItem));
            if (cartItem) {
              cartItem['quantity'] = (String((Number((getObjectProperty(cartItem, 'quantity')))) + (Number((getObjectProperty(activeItem, 'quantity'))))));
              (getObjectProperty(cartItem, 'orderData'))['quantity'] = (String((Number((getObjectProperty(cartItem, 'orderData.quantity')))) + (Number((getObjectProperty(activeItem, 'orderData.quantity'))))));
            } else {
              addItemToList(cart, activeItem);
            }
          } else {
            userData['cart'] = [activeItem];
          }
          localStorage.setItem('cart', JSON.stringify(cart));
          currentOrder = (getObjectProperty(userData, 'currentOrder'));
          if (currentOrder) {
            var j_list2 = (getObjectProperty(currentOrder, 'line_items'));
            for (var j_index2 in j_list2) {
              j = j_list2[j_index2];
              if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(activeItem, 'selectedVariationId')) && !(getObjectProperty(j, 'applied_discounts')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(activeItem, 'orderData.modifiers')) || !(getObjectProperty(activeItem, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(activeItem, 'orderData.modifiers')) && !!(getObjectProperty(activeItem, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(activeItem, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


               return (!(((getObjectProperty(activeItem, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
              })).length)) {
                orderItem = j;
                break;
              }
            }
            if (orderItem) {
              orderItem['quantity'] = (String((Number((getObjectProperty(orderItem, 'quantity')))) + (Number((getObjectProperty(activeItem, 'quantity'))))));
            }
            try {
              updatedOrder = (getObjectProperty((await Backendless.Request.put(`${Backendless.appPath}/services/Square/updare-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'order': ({ 'location_id': locationId,'line_items': orderItem ? (getObjectProperty(currentOrder, 'line_items')) : (addItemToList((getObjectProperty(currentOrder, 'line_items')), (getObjectProperty(activeItem, 'orderData')))),'customer_id': (getObjectProperty(user, 'customerId')),'pricing_options': ({ 'auto_apply_taxes': true }),'id': (getObjectProperty(currentOrder, 'id')),'version': (getObjectProperty(currentOrder, 'version')) }) })), 'order'));
              userData['currentOrder'] = updatedOrder;
              for (var j_index3 in cart) {
                j = cart[j_index3];
                delete j['reward'];
              }
              var j_list3 = (getObjectProperty(updatedOrder, 'line_items'));
              for (var j_index4 in j_list3) {
                j = j_list3[j_index4];
                if (getObjectProperty(j, 'applied_discounts')) {
                  rewardName = (getObjectProperty(j, 'name'));
                  for (var k_index in cart) {
                    k = cart[k_index];
                    if ((getObjectProperty(j, 'catalog_object_id')) == (getObjectProperty(k, 'selectedVariationId')) && (!(getObjectProperty(j, 'modifiers')) && (!(getObjectProperty(k, 'orderData.modifiers')) || !(getObjectProperty(k, 'orderData.modifiers')).length) || (getObjectProperty(j, 'modifiers')) && (getObjectProperty(k, 'orderData.modifiers')) && !!(getObjectProperty(k, 'orderData.modifiers')).length && (getObjectProperty(j, 'modifiers')).length == (getObjectProperty(k, 'orderData.modifiers')).length && !(await asyncListFilter((getObjectProperty(j, 'modifiers')), async (item) => {


                     return (!(((getObjectProperty(k, 'orderData.modifiers')).map(item => item['catalog_object_id'])).includes((getObjectProperty(item, 'catalog_object_id')))));
                    })).length)) {
                      rewardItem = k;
                      break;
                    }
                  }
                  break;
                }
              }
              localStorage.setItem('currentOrder', JSON.stringify(updatedOrder));
              localStorage.setItem('cart', JSON.stringify(cart));

            } catch (error) {
              console.log(error);
              if ((getObjectProperty(error, 'message')) == 'Network Error') {
                ___arguments.context.pageData['loading'] = false;
                ___arguments.context.pageData['networkError'] = true;
              }

            }
          }
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-order', undefined);
        }
        ___arguments.context.pageData['loading'] = false;
      } else {
        if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);
        }
      }
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'user')) ? ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'activeLocation')) ? 'Add to Pickup Order' : 'Choose pickup location') : 'Sign In to Order')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/menu-item/components/201cc83dfd3e13e21e4d18c4b06edca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var i, distanceList, locationsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['stopPropagation'] = true;
    ___arguments.context.pageData['screen'] = 'location';
    ___arguments.context.pageData['locationView'] = 'list';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
    ___arguments.context.pageData['userPoint'] = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    while (!(getObjectProperty(___arguments.context.pageData, 'coordinates'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    await (async function(coords, userPoint, pageData) {
    	const formatedCoords = coords.map(el => new google.maps.LatLng(el.lat, el.lng));

    	const service = new google.maps.DistanceMatrixService();

    	service.getDistanceMatrix(
    	  {
    	    origins: [new google.maps.LatLng(userPoint.lat, userPoint.lng)],
    	    destinations: formatedCoords,
    	    travelMode: 'DRIVING',
    	    unitSystem: google.maps.UnitSystem.IMPERIAL,
    	  }, callback);

    	function callback(response, status) {
    	  if(status === 'OK') {
    	    pageData.distanceList = response.rows[0].elements;
    	  }
    	}

    })((getObjectProperty(___arguments.context.pageData, 'coordinates')), (getObjectProperty(___arguments.context.pageData, 'mapCenter')), ___arguments.context.pageData);
    while (!(getObjectProperty(___arguments.context.pageData, 'distanceList'))) {
      await new Promise(r => setTimeout(r, 50 || 0));
    }
    distanceList = (getObjectProperty(___arguments.context.pageData, 'distanceList'));
    locationsList = (getObjectProperty(___arguments.context.pageData, 'locationsList'));
    var i_end = locationsList.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (locationsList[(i - 1)])['distance'] = (getObjectProperty((distanceList[(i - 1)]), 'distance.text'));
    }
    ___arguments.context.pageData['stopPropagation'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/cbaf450f12f802318f7ae9da250df17b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'stopPropagation'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cbaf450f12f802318f7ae9da250df17b', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/c4919b8f07bbed643529e5120da7f8ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, quantity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  quantity = (Number((getObjectProperty(activeItem, 'quantity'))));
  if (quantity < 99) {
    activeItem['quantity'] = (String(quantity + 1));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/9ddc1c5a420ac148e9f39d2930bd3d57/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, quantity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  quantity = (Number((getObjectProperty(activeItem, 'quantity'))));
  if (quantity > 1) {
    activeItem['quantity'] = (String(quantity - 1));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu-item/components/529f15caae4ddae7b88fb86854a351b9/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'activeItem.modifiers'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/menu-item/components/8edf0106b148e87444fa6e1eebe9d27c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) ? 'Choose pickup location' : 'Choose location')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/menu-item/components/13f7271cfd8eafcceaa1f04954f6c3fa/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('935ba5e5894c78467b04798b60e76cee'))['padding-bottom'] = '50%';
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a9ca892750dc01bb1e7b0af55498e3f1')));
  }

  },
  /* handler:onFocus */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    delete ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('935ba5e5894c78467b04798b60e76cee'))['padding-bottom'];
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/settings/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var error, locationsList, item, j, location2, locationTypes, address, imagesList, category, categoryItem, squareObjects, data, userLocation, user, order, payment, wallet, deviceId, token, amount, appConfig, newLocations, list, locationChangeTimestamp, locationChangeCache, timestamps, cardsList, favItem, squareFavs, favorite, lastOrder, cartItem, squareItemData, lastOrderItems, modifierNames, orders, squareOrders, k, curOrder, birthday, monthList, menus, menuChangeTimestamp, menuChangeCache, offer, todaysOffer, dateEnd, date, offerCache, dateStart, offset, res, newOffersList, newOffers, newIds, object, hookData, command;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['user'] = (getObjectProperty(___arguments.context.appData, 'user'));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/settings/components/fbde59d360654c7f336e7609c888a9b4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getDeviceSubscriptions() { return getSubscriptionsManager('deviceSubscriptions');}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.UserService.logout();
    delete ___arguments.context.appData['user'];
    delete ___arguments.context.appData['appLoaded'];
    delete ___arguments.context.appData['userData'];
    getRTSubscriptions().remove('addTodaysOffer');
    getRTSubscriptions().remove('deleteTodaysOffer');
    getRTSubscriptions().remove('AddNewCategory');
    getRTSubscriptions().remove('addPeriodTodaysOffer');
    getRTSubscriptions().remove('listenItemChange');
    getRTSubscriptions().remove('ListenMainLocationChange');
    getRTSubscriptions().remove('Balance Update Hook');
    getDeviceSubscriptions().remove('resumedAppRequest');
    localStorage.removeItem('cart');
    localStorage.removeItem('currentOrder');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/95fd5ae8fd633aefb714d3831e515c7e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-rewards', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/04f56ead3c0bd5fd547c75c1b499331d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-cards', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/1b04b2cd9a01bd09fb87459ecd4f6c27/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-fav', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/298b4f13b0cacd479453ebabae8ad524/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-history', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/c87fef686e49cb41abcebea341274ec3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['tab'] = 'chat';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/f7250af34986f527f2965d6369c5d90f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/18a5e8182e1978fba2b72daacbefdbb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings-wallet', undefined);

  },
  /* handler:onClick */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.balance'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/settings/components/726f6c44348bc807659187e88b0f7351/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c1f3ba5c51bc3d1ecf2df30c945cb739', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/9c639efe72c6e3d7d351212318de1eaa/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings-cards/components/338920334785619199555f3eec0a13c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['cardToDelete'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9c639efe72c6e3d7d351212318de1eaa'), 'id'));
  ___arguments.context.pageData['cardToDeleteStr'] = (['Remove card ...',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('9c639efe72c6e3d7d351212318de1eaa'), 'last_4')),' from the app?'].join(''));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/795eb3d1a3bc3cd831db83dd5fb37e2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/e57138310865bb9ddd64ffc1a34fb12f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['loading'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80d9689e4a7742b922628de5810805eb', false);
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'cardToDelete'))));
    removeItemInList((getObjectProperty(___arguments.context.appData, 'userData.cardsList')), ({ 'id': (getObjectProperty(___arguments.context.pageData, 'cardToDelete')) }), 'id');

  } catch (error) {
    console.log(error);
    if ((getObjectProperty(error, 'message')) == 'Network Error') {
      ___arguments.context.pageData['loading'] = false;
      ___arguments.context.pageData['networkError'] = true;
    }

  }
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-cards/components/89e16e3280d1ba395534758af4afc156/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.cardsList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/settings-cards/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/settings-fav/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['favoriteList'] = (getObjectProperty(___arguments.context.appData, 'userData.favoriteList'));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var reward, order, cart;


  ___arguments.context.pageData['screen'] = 'home';
  ___arguments.context.pageData['menu'] = ({  });
  ___arguments.context.pageData['tipsList'] = [1, 2, 5];
  cart = ((function(){ try { return JSON.parse(localStorage.getItem('cart')) } catch(e){ return null }})());
  ___arguments.context.pageData['cart'] = (cart ? cart : []);
  order = ((function(){ try { return JSON.parse(localStorage.getItem('currentOrder')) } catch(e){ return null }})());
  if (order) {
    ___arguments.context.pageData['currentOrder'] = order;
  }
  reward = ((function(){ try { return JSON.parse(localStorage.getItem('reward')) } catch(e){ return null }})());
  if (reward) {
    ___arguments.context.pageData['reward'] = reward;
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onLeave */
  ['onLeave'](___arguments) {
    function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getDeviceSubscriptions() { return getSubscriptionsManager('deviceSubscriptions');}


  getRTSubscriptions().remove('addTodaysOffer');
  getRTSubscriptions().remove('deleteTodaysOffer');
  getRTSubscriptions().remove('AddNewCategory');
  getRTSubscriptions().remove('addPeriodTodaysOffer');
  getRTSubscriptions().remove('listenItemChange');
  getRTSubscriptions().remove('ListenMainLocationChange');
  getRTSubscriptions().remove('Balance Update Hook');
  getDeviceSubscriptions().remove('resumedAppRequest');

  },
  /* handler:onLeave */
  /* content */
}))

define('./pages/settings-fav/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-fav/components/88551079be2c4d1d6b3314375795389c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var favoriteList, favItemId, userFavorite, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (event) { event.stopPropagation() })(___arguments.event);
  favoriteList = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'favoriteList'));
  userFavorite = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'favorite'));
  favItemId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'), 'objectId'));
  ;(async () => {
      try {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/deleteFav`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(favItemId));

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }

  })();
  removeItemInList(userFavorite, ({ 'objectId': favItemId }), 'objectId');
  removeItemInList(favoriteList, ___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'), 'objectId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-fav/components/8a44b738e72ce3215405234d34f42031/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentCategory, categoryId, categoryList, variation, item, modifier;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info')) && !(getObjectProperty(___arguments.context.dataModel, 'modifiers')) && (getObjectProperty(___arguments.context.dataModel, 'modifier'))) {
    ___arguments.context.dataModel['modifiers'] = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifiersList')), async (modifier) => {


     return ((Object.keys((getObjectProperty(___arguments.context.dataModel, 'modifier')))).includes((getObjectProperty(modifier, 'id'))));
    })).map(async item => {  (getObjectProperty(item, 'modifier_list_data'))['modifiers'] = (await asyncListFilter((getObjectProperty(item, 'modifier_list_data.modifiers')), async (modifier) => {


       return ((getObjectProperty(___arguments.context.dataModel, (String('modifier.') + String(getObjectProperty(item, 'id'))))).includes((getObjectProperty(modifier, 'id'))));
      }));
    ; return item;})));
  }
  if (!(getObjectProperty(___arguments.context.dataModel, 'variations')) && (getObjectProperty(___arguments.context.dataModel, 'variation'))) {
    ___arguments.context.dataModel['variations'] = (await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')), async (variation) => {


     return ((getObjectProperty(___arguments.context.dataModel, 'variation')).includes((getObjectProperty(variation, 'id'))));
    }));
  }
  ___arguments.context.pageData['activeItem'] = (JSON.parse((JSON.stringify(___arguments.context.dataModel))));
  await BackendlessUI.Functions.Custom['fn_deae239fbd5105dc37d8b961045461fd'](({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));
  Object.assign(___arguments.context.pageData, ({ 'screen': 'menu','showCategory': false,'showCatalog': false,'showItem': true,'favorite': true }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-fav/components/6d38a6784537ef075d9b5aedbc1b56b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  await BackendlessUI.Functions.Custom['fn_89844db7d6677ddcee222b521258184d']((JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('8a44b738e72ce3215405234d34f42031'))))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-fav/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-history/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    ___arguments.context.pageData['ordersList'] = (getObjectProperty(___arguments.context.appData, 'userData.ordersList'));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/settings-history/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-history/components/05eb4a31e45cd44543e8a4e3252ba967/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var itemImages, j, modifierNames, modifiers, image;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  if ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    ___arguments.context.pageData['historyObjectId'] = '';
  } else {
    itemImages = (getObjectProperty(___arguments.context.appData, 'imageList'));
    var j_list = (getObjectProperty(___arguments.context.dataModel, 'line_items'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (!(getObjectProperty(j, 'image'))) {
        if (!!itemImages.length) {
          image = itemImages[(((itemImages.map(item => item['name'])).indexOf(getObjectProperty(j, 'name')) + 1) - 1)];
        }
        j['image'] = (image ? (getObjectProperty(image, 'image')) : '/img/no-img.png');
      }
      modifiers = (getObjectProperty(j, 'modifiers'));
      if (modifiers) {
        modifierNames = (modifiers.map(item => item['name'])).join(', ');
      }
      if (!(getObjectProperty(j, 'nameStr')) || !(getObjectProperty(j, 'modifiersString')) || !(getObjectProperty(j, 'priceString'))) {
        Object.assign(j, ({ 'nameStr': [(getObjectProperty(j, 'quantity')),'x ',(getObjectProperty(j, 'name'))].join(''),'modifiersString': modifierNames ? [(getObjectProperty(j, 'variation_name')),', ',modifierNames].join('') : (getObjectProperty(j, 'variation_name')),'priceString': String('$') + String((await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty((getObjectProperty(j, 'total_money')), 'amount')) / 100)))) }));
      }
      Object.assign(___arguments.context.dataModel, (await BackendlessUI.Functions.Custom['fn_d8cc31b5bafa725dab15cd36453e4669'](___arguments.context.dataModel, itemImages)));
    }
    ___arguments.context.pageData['historyObjectId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-history/components/286168a664d5ab5dae2fc56d9e8ec9a2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('05eb4a31e45cd44543e8a4e3252ba967'), 'id')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings-history/components/5c99d95fa08d7c7ce8753ee6de853b1f/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  onIconAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'historyObjectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('05eb4a31e45cd44543e8a4e3252ba967'), 'id')) ? 'keyboard arrow down' : 'keyboard arrow up')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/settings-history/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-history/components/d2918964b5f9145587386a0a0dcf5e8b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var squareOrders, userData, item, orders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    userData = (getObjectProperty(___arguments.context.appData, 'userData'));
    orders = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getOrders`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'offset': ((getObjectProperty(userData, 'ordersList')).length) }).send());
    if (!orders.length) {
      userData['ordersLoaded'] = true;
    } else {
      squareOrders = (await asyncListSort((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders')), -1, async (item) => {


       return (getObjectProperty(item, 'updated_at'));
      }));
      if (squareOrders.length < 20) {
        userData['ordersLoaded'] = true;
      }
      addItemToList((getObjectProperty(userData, 'ordersList')), squareOrders);
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.appData, 'userData.ordersLoaded')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings-rewards/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
    Object.assign(___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'userData.loyalty')));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/settings-rewards/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-rewards/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var error, locationsList, item, j, location2, locationTypes, address, imagesList, category, categoryItem, squareObjects, data, userLocation, user, order, payment, wallet, deviceId, token, amount, appConfig, newLocations, list, locationChangeTimestamp, locationChangeCache, timestamps, cardsList, favItem, squareFavs, favorite, lastOrder, cartItem, squareItemData, lastOrderItems, modifierNames, orders, squareOrders, k, curOrder, birthday, monthList, menus, menuChangeTimestamp, menuChangeCache, offer, todaysOffer, dateEnd, date, offerCache, dateStart, offset, res, newOffersList, newOffers, newIds, object, hookData, command;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'appLoaded')) {
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/settings-wallet/components/f2eafdfa8aef31f6f0db7a9ab9951f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c2bee686ed885b1408b94e5c34acef12']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/c4bfd5817d64fb1bb003c332177aa957/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['card_brand_image'] = (await BackendlessUI.Functions.Custom['fn_bb44ca5c2f64b9195b6f2012de579bc1'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var amount, balanceObj, error, locationId, order, payment, user, wallet, balance, userData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.appData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': (getObjectProperty(___arguments.context.dataModel, 'id')),'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      balanceObj = (getObjectProperty(___arguments.context.appData, 'userData.balance'));
      if (!(getObjectProperty(balanceObj, 'wallet'))) {
        balanceObj['wallet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());
      }
      wallet = (getObjectProperty(balanceObj, 'wallet'));
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      ;(async () => {
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
        ___arguments.context.pageData['walletInput'] = '';
        await new Promise(r => setTimeout(r, 2000 || 0));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

      })();
      balanceObj['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      await Backendless.UserService.update( new Backendless.User(user) );
      ;(async () => {
          await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveOrder`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.appData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Card','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' })));

      })();

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }
      Backendless.Logging.getLogger('SERVER_CODE').error((String('Card fill up: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/e74af199605f6226c171893e55f14e77/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fillOptions'] = [10, 20, 30];
  ___arguments.context.pageData['walletAmount'] = ((getObjectProperty(___arguments.context.pageData, 'fillOptions'))[0]);

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/settings-wallet/components/aeeeea1ff2c6c13294be45e58d7eec1b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('c64fbb8fa8266712cedd89f6270f346d')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/settings-wallet/components/c64fbb8fa8266712cedd89f6270f346d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'walletAmount')) == ___arguments.context.dataModel ? ['fill__item', 'active'] : ['fill__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['walletAmount'] = ___arguments.context.dataModel;
  await (async function(val) {
  	const el = document.querySelector(`.wheel-${val}`)
  	el.scrollIntoView({block: "center", behavior: "smooth"})
  })(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/4fb7b4a4536fb28c92a6b55ef925a1f7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value, cents;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['walletAmount'] = '';
  value = (getObjectProperty((___arguments.value.match((new RegExp('\\d*\\.?\\d{0,2}', '')))), '0'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4fb7b4a4536fb28c92a6b55ef925a1f7'))['value'] = value;
  if ((value.search((new RegExp('\\.', '')))) == -1) {
    cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 100;
  } else {
    if (value.length - (value.indexOf('.') + 1) == 1) {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 10;
    } else if (value.length - (value.indexOf('.') + 1) == 2) {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), ''))));
    } else {
      cents = (Number((value.replace((new RegExp('\\D', 'g')), '')))) * 100;
    }
  }
  ___arguments.context.pageData['walletInput'] = cents;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/settings-wallet/components/9bccd962379287374294f829f9581521/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, payment, user, wallet, balanceObj, locationId, nonce, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.appData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_APPLE_PAY', ({ 'totalAmount': (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
        balanceObj = (getObjectProperty(___arguments.context.appData, 'userData.balance'));
        if (!(getObjectProperty(balanceObj, 'wallet'))) {
          balanceObj['wallet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());
        }
        wallet = (getObjectProperty(balanceObj, 'wallet'));
        if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
        } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
          wallet['state'] = 'ACTIVE';
        }
        ;(async () => {
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
          ___arguments.context.pageData['walletInput'] = '';
          await new Promise(r => setTimeout(r, 2000 || 0));
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

        })();
        balanceObj['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
        user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        await Backendless.UserService.update( new Backendless.User(user) );
        await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveOrder`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.appData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Apple Pay','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' })));
      } else {
        ___arguments.context.pageData['loading'] = false;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }
      Backendless.Logging.getLogger('SERVER_CODE').error((String('Apple pay fill up: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/5c9bb3c3a75ea8717643dcd211e23e9c/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    if ((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('CAN_USE_APPLE_PAY', undefined))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bccd962379287374294f829f9581521', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings-wallet/components/a9cc11560cc75d9be66410877ce8807e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, order, payment, user, wallet, balanceObj, locationId, nonce, amount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && ((getObjectProperty(___arguments.context.pageData, 'walletInput')) || (getObjectProperty(___arguments.context.pageData, 'walletAmount')))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.appData, 'user'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'walletInput')) ? (getObjectProperty(___arguments.context.pageData, 'walletInput')) : (getObjectProperty(___arguments.context.pageData, 'walletAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.appData, 'mainLocationId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Fill up the balance','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('SQUARE_GOOGLE_PAY', ({ 'totalAmount': (getObjectProperty(order, 'total_money.amount')) }))), 'nonce'));
      if (nonce) {
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': nonce,'customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
        balanceObj = (getObjectProperty(___arguments.context.appData, 'userData.balance'));
        if (!(getObjectProperty(balanceObj, 'wallet'))) {
          balanceObj['wallet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty(user, 'walletId'))) }).send());
        }
        wallet = (getObjectProperty(balanceObj, 'wallet'));
        if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
        } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
          wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
          wallet['state'] = 'ACTIVE';
        }
        ;(async () => {
            (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', true);
          ___arguments.context.pageData['walletInput'] = '';
          await new Promise(r => setTimeout(r, 2000 || 0));
          (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db2f7e5d91e96fb29e22efd524eb6b49', false);

        })();
        balanceObj['balanceStr'] = (await BackendlessUI.Functions.Custom['fn_51c9e59364164f22ddfea5944f05aab3'](((getObjectProperty(wallet, 'balance_money.amount')) / 100)));
        user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        await Backendless.UserService.update( new Backendless.User(user) );
        ;(async () => {
            await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveOrder`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location': ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'mainLocation.objectId')) }),'payment': ({ 'amount': (getObjectProperty(payment, 'total_money.amount')),'type': 'Fill up the balance','method': 'Google Pay','squareId': (getObjectProperty(payment, 'id')) }),'squareId': (getObjectProperty(order, 'id')),'title': 'Fill up the balance' })));

        })();
      } else {
        ___arguments.context.pageData['loading'] = false;
      }

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')).indexOf('GENERIC_DECLINE') !== -1) {
        await (async function() {
        	alert('Card declined')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('INSUFFICIENT_FUNDS') !== -1) {
        await (async function() {
        	alert('Card declined due to insufficient funds')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('TRANSACTION_LIMIT') !== -1) {
        await (async function() {
        	alert('Card declined due to transaction limit')
        })();
      } else if ((getObjectProperty(error, 'message')).indexOf('CARD_EXPIRED') !== -1) {
        await (async function() {
        	alert('Card expired')
        })();
      }
      if (error.indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }
      Backendless.Logging.getLogger('SERVER_CODE').error((String('Google Pay fill up: ') + String((getObjectProperty(error, 'message')) ? (getObjectProperty(error, 'message')) : (JSON.stringify(error)))));

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/c551e9bed869c55bdb070ea51f21d969/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings-wallet/components/d7397c07c916576129e79e1100742344/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/10810b5dde48f0220e75ef85fe72e8eb/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var amountList, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  amountList = [];
  for (i = 10; i <= 500; i += 5) {
    addItemToList(amountList, ({ 'amount': i }));
  }
  ___arguments.context.pageData['wheelValue'] = 10;
  ___arguments.context.pageData['wheelList'] = amountList;
  await (async function(pageData) {
  	const wheel = document.querySelector('.wheel')
  	const minVal = 10
  	const maxVal = 500

  	let scrollStart,
  	    scrollEnd,
  	    scrollHeight

  	wheel.addEventListener('touchstart', touchStart)
  	wheel.addEventListener('touchend', touchEnd)
  	wheel.addEventListener('touchmove', touchMove)

  	function touchStart(event) {
  	  event.preventDefault()

  	  scrollStart = event.changedTouches[0].clientY + wheel.scrollTop
  	  console.log(`start: ${scrollStart}`)
  	}

  	function touchEnd(event) {
  	  event.preventDefault()
  	  console.log(`end: ${scrollEnd}`)

  	  const elHeight = document.querySelector('.wheel__item').clientHeight
  	  const wheelItem = scrollHeight % elHeight > elHeight / 2 ? Math.floor(scrollHeight / elHeight) + 1 : Math.floor(scrollHeight / elHeight)
  	  const wheelValue = (wheelItem + 2) * 5

  	  console.log(wheelValue)

  	  let el

  	  if(wheelValue < minVal) {
  	    el = document.querySelector(`.wheel-${minVal}`)
  	    setPageData(minVal)
  	  } else if (wheelValue > maxVal) {
  	    el = document.querySelector(`.wheel-${maxVal}`)
  	    setPageData(maxVal)
  	  } else {
  	    el = document.querySelector(`.wheel-${wheelValue}`)
  	    setPageData(wheelValue)
  	  }

  	  el.scrollIntoView({block: "center", behavior: "smooth"})

  	}

  	function touchMove(event) {
  	  event.preventDefault()

  	  scrollEnd = event.changedTouches[0].clientY
  	  scrollHeight = scrollStart - scrollEnd
  	  wheel.scrollTop = scrollHeight
  	}

  	function setPageData (val) {
  	  // pageData.walletInput = val * 100
  	  pageData.walletAmount = val
  	  pageData.wheelValue = val
  	  // pageData.walletAmount = ''
  	}
  })(___arguments.context.pageData);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings-wallet/components/188b1c8e6e749e7daa15a2e90c3c327b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('188b1c8e6e749e7daa15a2e90c3c327b')), (String('wheel-') + String(getObjectProperty(___arguments.context.dataModel, 'amount'))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings-wallet/components/ab2b61f5948fecee6d1b28ecb8a323f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'walletInput')) ? ['fill__sum', 'fill__sum--active'] : ['fill__sum'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings-wallet/components/d31411d13b6ac2e6bfdfd148c732505e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.balance'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/settings-wallet/components/474e680a5b93f414c8ca1f0bce8fd1dd/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'userData.cardsList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_97823ca8584106da9e1d0d34284ade5b/logic/3209bfe6adb55ba82bdf0787070a0ab9/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var closeTime, workingHours, address, activeLocation, schedule, tomorrowSchedule, tomorrow, item, periods, dayNum, days, today, dayPeriod;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function createClosingTimeString(workingHours) {
  closeTime = (Number(((getObjectProperty(workingHours, 'end_local_time')).slice(0, 2))));
  return String('Open until ') + String(await timeToAMPM(closeTime))
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function createOpenTimeString2(workingHours) {
  closeTime = (Number(((getObjectProperty(workingHours, 'start_local_time')).slice(0, 2))));
  return String('Closed. Opens at ') + String(await timeToAMPM(closeTime))
}

/**
 * Describe this function...
 */
async function timeToAMPM(dayPeriod) {
  return dayPeriod >= 12 ? String(dayPeriod == 12 ? dayPeriod : dayPeriod - 12) + String(' PM') : (dayPeriod == 0 ? '12 AM' : String(dayPeriod) + String(' AM'))
}


  if (getObjectProperty(___arguments.context.appData, 'userData')) {
    activeLocation = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'userData')), 'activeLocation'));
    console.log(activeLocation);
    if (activeLocation) {
      periods = (getObjectProperty((getObjectProperty(activeLocation, 'business_hours')), 'periods'));
      if (periods) {
        days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        dayNum = (new Date((new Date())).getDay()) + 1;
        today = days[(dayNum - 1)];
        schedule = (await asyncListFilter(periods, async (item) => {


         return ((getObjectProperty(item, 'day_of_week')) == today);
        }));
        tomorrow = dayNum == 7 ? days[0] : days[((dayNum + 1) - 1)];
        tomorrowSchedule = (await asyncListFilter(periods, async (item) => {


         return ((getObjectProperty(item, 'day_of_week')) == tomorrow);
        }));
        closeTime = (Number(((getObjectProperty(((await asyncListFilter(periods, async (item) => {


         return ((getObjectProperty(item, 'day_of_week')) == tomorrow);
        }))[0]), 'end_local_time')).slice(0, 2))));
        if (!schedule.length || (new Date((new Date())).getHours()) >= closeTime) {
          if (!tomorrowSchedule.length) {
            activeLocation['workingTimeStr'] = 'Closed';
          } else {
            activeLocation['workingTimeStr'] = (await createOpenTimeString2(tomorrowSchedule[0]));
          }
        } else {
          activeLocation['workingTimeStr'] = (await createClosingTimeString(schedule[0]));
        }
      } else {
        activeLocation['workingTimeStr'] = 'Closed';
      }
      address = (getObjectProperty(activeLocation, 'address'));
      activeLocation['addressString'] = ([(getObjectProperty(address, 'address_line_1')),', ',(getObjectProperty(address, 'locality')),', ',(getObjectProperty(address, 'administrative_district_level_1')),', ',(getObjectProperty(address, 'postal_code'))].join(''));
    }
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_97823ca8584106da9e1d0d34284ade5b/logic/3c54c641505a7946686cae31e7de9528/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (event) { event.stopPropagation() })(___arguments.event);
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-order', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'userData.cart')) && !!(getObjectProperty(___arguments.context.appData, 'userData.cart')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_6751d15bf24500fa314384ed3fc37211/logic/e65b1a1aaa6bd703311e8161953c3fe8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(location, pageData) {
  	const appId = 'sq0idp-F5SAAmEU_KgZHsEeQnUTrQ';
  	const locationId = location;

  	async function main() {
  	  const payments = Square.payments(appId, locationId);
  	  const card = await payments.card();

  	  await card.attach('#card-container');

  	  pageData.loading = false;

  	  async function eventHandler(event) {
  	    event.preventDefault();

  	    try {
  	      const result = await card.tokenize();

  	      if (result.status === 'OK') {
  	        pageData.cardToken = result.token;
  	      }
  	    } catch (e) {
  	      pageData.loading = false;
  	    }
  	  };

  	  const cardButton = document.querySelector('.card__submit');
  	  cardButton.addEventListener('click', eventHandler);
  	}

  	main();
  })((getObjectProperty(___arguments.context.appData, 'user.location')), ___arguments.context.pageData);
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_6751d15bf24500fa314384ed3fc37211/logic/7a5ee976e524226cc61f08d4896f0c44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bef5d68424c25e69fb519be437bfba5', false);
    while (!(getObjectProperty(___arguments.context.pageData, 'cardToken'))) {
      await new Promise(r => setTimeout(r, 1000 || 0));
    }
    try {
      card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'customerId')),'source_id': (getObjectProperty(___arguments.context.pageData, 'cardToken')) })), 'card'));
      cardsList = (getObjectProperty(___arguments.context.appData, 'userData.cardsList'));
      if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bef5d68424c25e69fb519be437bfba5', true);
      } else {
        addItemToList(cardsList, card);
        delete ___arguments.context.pageData['showCardForm'];
      }
      delete ___arguments.context.pageData['cardToken'];

    } catch (error) {
      console.log(error);
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6751d15bf24500fa314384ed3fc37211/logic/8724bdf27d5451edd9accafe54b712d9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['showCardForm'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e2013ab2995dfd35e161ce7ec234e71b/logic/c253c4af40f7ebb63f2c3e51dfcbb223/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['showCardError'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/1cf68d89931a01833ed8411bf29e86d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'screen')) == 'settings') {
    if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'main') {
      return true;
    }
  } else {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/11ab3313596c7e67f8c477aa5438744f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('home') !== -1)) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('page') !== -1) || ((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('home') !== -1) ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/b30888b1e6d7a6abb5ca076fb57f20ce/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('page') !== -1) || ((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('home') !== -1) ? './styles/assets/img/icons/home_active.svg' : './styles/assets/img/icons/home.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/ba4dca12ca12c5ab578277ae41c27301/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var location2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('menu') !== -1)) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('menu') !== -1) ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/6433375980bbcd83241a310e5345472e/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('menu') !== -1) ? './styles/assets/img/icons/menu_active.svg' : './styles/assets/img/icons/menu.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/2487e69734eb1ed933420f2510cd57af/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('checkout') !== -1) ? ['navbar__item', 'navbar__item--w85', 'active'] : ['navbar__item', 'navbar__item--w85'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/b584707c94d14e68ae9a8d5cd1cf058c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('checkout') !== -1) && (getObjectProperty(___arguments.context.appData, 'user'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('checkout-qr', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/cd634cadd83c05335f86050c74be1fc9/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var url;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('checkout') !== -1) && !((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('checkout-qr') !== -1)) {
    url = './styles/assets/img/icons/basket.svg';
  } else {
    url = './styles/assets/img/icons/scan_icon.svg';
  }

  return url

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/7cbc502c817266c8c9377ba76f5e7051/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('location') !== -1)) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('location') !== -1) ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/54feba53dae81742ff71b3538628464c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('location') !== -1) ? './styles/assets/img/icons/location_active.svg' : './styles/assets/img/icons/location.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/bd1b573124e75f1bb4a63faa9815af86/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('settings') !== -1)) {
    if (getObjectProperty(___arguments.context.appData, 'user')) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('authorization', undefined);
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('settings') !== -1) ? ['navbar__item', 'active'] : ['navbar__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/b5fd53bb7142a8b4c283186e11245c31/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'user')) ? (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('settings') !== -1) ? './styles/assets/img/icons/profile_active.svg' : './styles/assets/img/icons/profile.svg') : './styles/assets/img/icons/login.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_57d8a83d442533bbf7c5360bceb5699b/logic/4483bfbcc07aa28feb9c10498cbdc274/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'user')) ? 'Profile' : 'Sign In')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_fbd9e37b932eb9d508e8103e17cdda0e/logic/6593c4fa1d764bf2c1b4e27277b6e144/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, card, cardsList, nonce;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('NATIVE_SHELL')) {
    try {
      nonce = (getObjectProperty((await ( async function (name, data) { return BackendlessUI.DeviceAPI.sendRequest(name, data) })('REQUEST_SQUARE', ({ 'locationId': (getObjectProperty(___arguments.context.appData, 'mainLocationId')) }))), 'nonce'));
      if (nonce) {
        card = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/add-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'customer_id': (getObjectProperty((getObjectProperty(___arguments.context.appData, 'user')), 'customerId')),'source_id': nonce })), 'card'));
        cardsList = (getObjectProperty(___arguments.context.appData, 'userData.cardsList'));
        if (!!cardsList.length && ((cardsList.map(item => item['fingerprint'])).includes((getObjectProperty(card, 'fingerprint'))))) {
          await Backendless.Request.post(`${Backendless.appPath}/services/Square/disable-card`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(card, 'id'))));
        } else {
          addItemToList(cardsList, card);
        }
        delete ___arguments.context.pageData['cardToken'];
      }

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('_CANCELED BY USER') !== -1) {
        ___arguments.context.pageData['loading'] = false;
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')).indexOf('INVALID_CARD_DATA') !== -1) {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['showCardError'] = true;
      } else {
        console.log(error);
      }
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    } finally {
      ___arguments.context.pageData['loading'] = false;

    }
  } else {
    console.log('abc');
    ___arguments.context.pageData['showCardForm'] = true;
    console.log(getObjectProperty(___arguments.context.pageData, 'showCardForm'));
  }

  },
  /* handler:onClick */
  /* content */
}))

